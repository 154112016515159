import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LinkWithPrevState from '../components/LinkWithPrevState';
import { Icon, Card, Image } from '@virtidev/toolbox';
import { getResizeImage } from '../apps/core/src/helpers/imageResizing/getResizeImage';

export const StyledUnitCard = styled(Card).attrs({ noPadding: true })`
  ${({ theme: { spacing }, locked }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 1rem;
    user-select: none;

    .body {
      gap: ${spacing.sm};
    }

    ${locked &&
    css`
      filter: grayscale(1) brightness(0.9);
      * {
        pointer-events: none;
      }
    `}
  `}
`;

export const StyledImageLink = styled(LinkWithPrevState)`
  position: relative;
  display: block;
  width: 100%;

  &:hover {
    opacity: var(--image-link-opacity-hover);
  }
  &:active {
    opacity: var(--image-link-opacity-active);
  }
`;

const StyledUnitStatusContainer = styled.div`
  width: 100%;
  padding-top: 0.5rem;
`;

const StyledUnitStatusLabel = styled.p`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  padding: 2px 5px 2px 0px;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;

  color: ${({ availability }) => {
    switch (availability?.toLowerCase()) {
      case 'completed':
        return 'var(--secondary-color)';
      case 'available':
        return 'var(--primary-color)';
      case 'locked':
        return 'darkgrey';
      default:
        return 'var(--primary-color)';
    }
  }};
`;

export const StyledUnitCardInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 0.5rem 1rem;
`;

export const StyledUnitTitle = styled(LinkWithPrevState)`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: flex-start;

  &:hover {
    text-decoration: underline;
  }
`;

export const TitleBox = styled.div`
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.3;
  word-break: break-word;
  overflow-wrap: anywhere;
`;

export const TitleSpan = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 2.4rem;
  width: 100%;
`;

export const StyledUnitTitleContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 0.5rem;
`;

export const StyledUnitImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const StyledThumbnail = styled(Image)`
  width: 100%;
  display: block;
  height: 108px;
  object-fit: cover;
`;

const StyledStatusDot = styled.div`
  margin-top: 2px;
  width: 10px;
  height: 10px;
  display: block;
  background-color: currentColor;
  border-radius: 0.5rem;
`;

const MyCourseUnit = ({
  availability,
  courseId,
  unitData,
  moduleIndex,
  unitIndex,
}) => {
  const url = useMemo(() => {
    if (unitData.Type === 'VirtualHuman') {
      return {
        pathname: `/my-courses/${courseId}/virtual-humans/${unitData.VirtualHuman.ID}`,
        search: `unitID=${unitData.ID}`,
      };
    }
    return {
      pathname: `/my-courses/${courseId}/simulations/${unitData.Simulation.ID}`,
      search: `unitID=${unitData.ID}`,
    };
  }, [unitData, courseId]);

  const { Title } = useMemo(() => {
    if (unitData.Type === 'VirtualHuman') {
      return unitData.VirtualHuman;
    }
    return unitData.Simulation;
  }, [unitData]);

  const thumbnailUrl = useMemo(() => {
    const model =
      unitData.Type === 'VirtualHuman'
        ? unitData.VirtualHuman
        : unitData.Simulation;

    if (model.ImageMedia?.TusID) {
      return getResizeImage(model.ImageMedia.TusID);
    }
    if (model.Thumbnail?.TusID) {
      return getResizeImage(model.Thumbnail.TusID);
    }
    if (model.Avatar?.ImageMedia?.TusID) {
      return getResizeImage(model.Avatar.ImageMedia.TusID);
    }
    return (
      model.ThumbnailURL ||
      model.ImageMedia?.URL ||
      model.FirstVideo?.nodes?.[0]?.PosterURL
    );
  }, [unitData]);

  const type = useMemo(() => {
    if (unitData.Type === 'VirtualHuman') {
      return 'virtual-human';
    }
    return 'video';
  }, [unitData]);

  return (
    <StyledUnitCard locked={availability === 'Locked'}>
      <StyledImageLink to={url}>
        <StyledUnitImageContainer>
          <StyledThumbnail src={thumbnailUrl} />
        </StyledUnitImageContainer>
      </StyledImageLink>
      <StyledUnitCardInfoContainer>
        <StyledUnitTitleContainer>
          <StyledUnitTitle to={url}>
            <Icon icon={type} color="var(--secondary-color, #46beaf)" />
            <TitleBox>
              <TitleSpan>{Title}</TitleSpan>
            </TitleBox>
          </StyledUnitTitle>
        </StyledUnitTitleContainer>
        <StyledUnitStatusContainer>
          <StyledUnitStatusLabel availability={availability}>
            <StyledStatusDot />
            {availability}
          </StyledUnitStatusLabel>
        </StyledUnitStatusContainer>
      </StyledUnitCardInfoContainer>
    </StyledUnitCard>
  );
};

MyCourseUnit.propTypes = {
  courseId: PropTypes.string.isRequired,
  unitData: PropTypes.object.isRequired,
  availability: PropTypes.string.isRequired,
};

export default MyCourseUnit;
