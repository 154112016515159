import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import withUser from '@base/HOCs/WithUser';
import StyledPage, {
  StyledPageContentInner,
  StyledTopArea,
} from '@base/components/StyledPage';
import { StyledDataBlockHeaderText } from '@base/styled-components/StyledDataBlocks';
import StyledPageHeader from '@base/styled-components/StyledPageHeader';
import RedirectWithPrevState from '@base/components/RedirectWithPrevState';
import { DASHBOARD_STUDENT } from '@base/queries/DashboardQueries';
import LoadingPageIndicator from '@base/components/LoadingPageIndicator';
import PageLoadError from '@base/components/PageLoadError';

import { SimulationList } from '@base/components/SimulationItem';
import MySimulationItem from '@base/components/MySimulationItem';
import MyCourseItem from '@base/components/MyCourseItem';

import MyVirtualHumanItem from '@base/components/MyVirtualHumanItem';
import TrialBanner from '@core/components/Signup/legacy/TrialBanner/TrialBanner';
import { useWelcomeFlow } from '@core/helpers/useWelcomeFlow/useWelcomeFlow';
import useUser from '@core/helpers/useUser';
import { useQuery } from '@apollo/client';
import { setCanViewUserSideVirtualHumans } from '@base/ApolloReactiveVars';
import { usePrevious } from '@base/utility/CustomHooks';
import { useCanViewUserSideVirtualHumans } from '@core/helpers/permissions.hooks';
import PendingContentCopyBanner from '@core/components/Banners/PendingContentCopyBanner/PendingContentCopyBanner';
import BannerContainer from '@core/components/Banners/BannerContainer/BannerContainer';

const MyHomePage = (props) => {
  const { SignupMethod, Organisation, UserType } = useUser();

  const { data, loading, error } = useQuery(DASHBOARD_STUDENT, {
    variables: { OrgID: props.userOrganisationID },
  });

  const canViewUserSideVirtualHumans = useCanViewUserSideVirtualHumans();

  // set whether user virtual humans tab should be visible or not based on whether VHs exist in this org
  const prevLoading = usePrevious(loading);
  useEffect(() => {
    if (!loading && prevLoading) {
      const total =
        data?.readOneOrganisation?.VirtualHumans?.pageInfo.totalCount ?? 0;
      setCanViewUserSideVirtualHumans(total > 0);
    }
  }, [data, loading, prevLoading]);

  const firstCourseComplete = useMemo(() => {
    return (
      data?.readOneOrganisation?.Courses?.edges[0]?.node?.MemberProgress === 100
    );
  }, [data?.readOneOrganisation?.Courses]);

  const trial = useMemo(() => {
    const method = SignupMethod || 'public';

    return Organisation?.Trial && method === 'public';
  }, [Organisation, SignupMethod]);

  useWelcomeFlow(firstCourseComplete);

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }

  return (
    <StyledPage pageKey="dashboard">
      <StyledPageContentInner style={{ gap: 16 }}>
        <StyledTopArea>
          <StyledPageHeader>Home</StyledPageHeader>
        </StyledTopArea>
        {(trial || UserType === 'admin') && (
          <BannerContainer>
            {trial && <TrialBanner />}
            {UserType === 'admin' && <PendingContentCopyBanner />}
          </BannerContainer>
        )}
        {loading && <LoadingPageIndicator />}
        {!loading && error && <PageLoadError graphQLErrorObj={error} />}
        {!!data?.readOneOrganisation?.Courses?.edges?.length && (
          <>
            <StyledDataBlockHeaderText>Courses</StyledDataBlockHeaderText>

            {!loading && (
              <SimulationList>
                {data.readOneOrganisation.Courses.edges.map((edge, index) => (
                  <MyCourseItem
                    data={edge.node}
                    key={edge.node.ID}
                    index={index}
                  />
                ))}
              </SimulationList>
            )}
          </>
        )}
        {!!data?.readOneOrganisation?.FeaturedSimulations?.edges?.length && (
          <>
            <StyledDataBlockHeaderText>
              Featured interactive videos
            </StyledDataBlockHeaderText>

            {!loading && (
              <>
                <SimulationList>
                  {data.readOneOrganisation.FeaturedSimulations.edges.map(
                    (edge) => (
                      <MySimulationItem data={edge.node} key={edge.node.ID} />
                    )
                  )}
                </SimulationList>
              </>
            )}
          </>
        )}
        {!!data?.readOneOrganisation?.Simulations?.edges?.length && (
          <>
            <StyledDataBlockHeaderText>
              Latest interactive videos added
            </StyledDataBlockHeaderText>

            {!loading && (
              <SimulationList>
                {data.readOneOrganisation.Simulations.edges.map((edge) => (
                  <MySimulationItem data={edge.node} key={edge.node.ID} />
                ))}
              </SimulationList>
            )}
          </>
        )}

        {canViewUserSideVirtualHumans &&
          !!data?.readOneOrganisation?.VirtualHumans?.edges?.length && (
            <>
              <StyledDataBlockHeaderText>
                Virtual Humans
              </StyledDataBlockHeaderText>

              {!loading && (
                <SimulationList>
                  {data.readOneOrganisation.VirtualHumans.edges.map((edge) => (
                    <MyVirtualHumanItem data={edge.node} key={edge.node.ID} />
                  ))}
                </SimulationList>
              )}
            </>
          )}
      </StyledPageContentInner>
    </StyledPage>
  );
};

MyHomePage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number,
  userOrganisationName: PropTypes.string,
};

export default withUser(MyHomePage);
