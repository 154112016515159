import { gql } from "@apollo/client";

export const READ_LICENSE_COUNTS = gql`
  query licenseCounts($OrganisationID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $OrganisationID } }) {
      ID
      Name
      VirtualHumanFreeformLimit
      Licenses
      UnusedFreeformLicenses
      TotalAdmins
      TotalUsers
      VirtualHumans {
        pageInfo {
          totalCount
        }
      }
      Simulations {
        pageInfo {
          totalCount
        }
      }
      PlatformLicense {
        ID
        Type
        SessionsPerMonth
        VirtualHumans
        InteractiveVideos
        Editors
        PaidEditorSeats
        Learners
      }
    }
  }
`;