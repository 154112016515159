import * as Sentry from '@sentry/browser';
import getConfig from '@base/lib/config';

const IGNORE_MESSAGES = [
  'memory access out of bounds',
  'index out of bounds',
  'Timed out while trying to start the Service Worker',
  'ResizeObserver loop limit exceeded',
  'Maximum call stack size exceeded',
  'Failed to update a ServiceWorker for scope',
  'ResizeObserver loop completed with undelivered notifications',
  "Failed to execute 'removeChild' on 'Node'",
  '_emscripten_set_canvas_element_size_calling_thread',
  'encountered an error during installation.',
  'Failed to register a ServiceWorker',
  'IndexSizeError: The index is not in the allowed range',
  'AbortError',
  'sw.js load failed',
];
export const setupSentry = () => {
  /**
   * Determine if this is a mobile operating system.
   * Copied from BrowserCheck.js because of react-refresh not working well with service workers
   *
   * @returns {Boolean}
   */
  const IsMobile = () => {
    var userAgent = navigator.userAgent || navigator.vendor || global.opera;
    var document = global.document || {};

    if (!userAgent) {
      return false;
    }

    if (
      // Windows Phone must come first because its UA also contains "Android"
      /windows phone/i.test(userAgent) ||
      // Android detection
      /android/i.test(userAgent) ||
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      (/iPad|iPhone|iPod/.test(userAgent) &&
        !(/** @type {any} */ (global).MSStream)) ||
      (userAgent.includes('Mac') && 'ontouchend' in document)
    ) {
      return true;
    }

    return false;
  };

  const getEnvironment = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      if (
        /deploy-preview-[0-9]+--virti-admin\.netlify\.app/.test(
          global.location.hostname
        )
      ) {
        return 'preview';
      }
      return 'production';
    }
    return process.env.REACT_APP_ENV;
  };

  if (process.env.REACT_APP_ENV === 'production' && !IsMobile()) {
    Sentry.init({
      dsn: getConfig('REACT_APP_SENTRY_DSN'),
      integrations: [
        /** @type {any} */
        (global.document ? Sentry.browserTracingIntegration() : null),
      ].filter(Boolean),
      environment: getEnvironment(),
      // Set tracesSampleRate to 1.0 to capture 100%
      // lower rate for production for performance reasons
      tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.1 : 0.5,
      beforeSend: (event, hint) => {
        const error = hint.originalException;
        const message =
          (typeof error === 'string' ? error : error?.message) ||
          event.message ||
          '';
        const token = global.localStorage?.getItem?.('token');
        if (
          !global.localStorage ||
          !token ||
          (typeof message === 'string' &&
            IGNORE_MESSAGES.find((ignored) => message.includes(ignored)))
        ) {
          return null;
        }
        return event;
      },
      ignoreErrors: [/^(chrome-extension:|blob:https:|wasm:)\/\//],
    });
  }

  const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } =
    performance?.memory || {};

  const deviceMemory = {
    size_limit: jsHeapSizeLimit,
    total_size: totalJSHeapSize,
    used_size: usedJSHeapSize,
    memory: navigator?.deviceMemory,
  };

  Sentry.setContext('device memory', deviceMemory);
};

export default setupSentry;
