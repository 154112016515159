import React, {
  forwardRef,
  useLayoutEffect,
  useState,
  useEffect,
  useRef,
} from 'react';
import { StyledIframe } from './FeedbackFormEmbed.styled';
import { useUser } from '../../../helpers/useUser';
import useRefs from '../../../helpers/mergeRefs';
import getConfig from '../../../../../../lib/config';
import tracker from '../../../helpers/tracker';

const url = getConfig('REACT_APP_FORM_TARGET_URI');
export const FeedbackFormEmbed = forwardRef(
  ({ formID, hash, onComplete, trigger, sourceID, ...props }, ref) => {
    const iframeRef = useRef();
    const [height, setHeight] = useState('0');
    const user = useUser();
    const title = `Feedback Form - ${formID} - ${hash}`;
    const formUrl = `${url}forms/${hash}/${formID}?trigger=${trigger}&sourceId=${sourceID}&userID=${user.ID}`;

    useEffect(() => {
      if (!hash || !formID) {
        onComplete();
      }
    }, [formID, hash, onComplete]);

    useLayoutEffect(() => {
      const handler = async (event) => {
        const { Hash, event: eventName } = event.data;
        if (hash !== Hash) {
          return;
        }
        if (eventName === 'submit') {
          tracker.track('form_submitted', {
            form_id: formID,
          });
        }
        if (eventName === 'submit' || eventName === 'error') {
          await new Promise((r) => setTimeout(r, 5000));
          onComplete();
        }
      };
      window.addEventListener('message', handler, false);

      return () => window.removeEventListener('message', handler, false);
    }, [formID, hash, onComplete]);

    useEffect(() => {
      const resize = () => {
        if (!iframeRef.current) {
          return;
        }
        const width = iframeRef.current.offsetWidth;
        setHeight(`${(width / 16) * 9}px`);
      };
      window.addEventListener('resize', resize, { passive: true });

      setTimeout(resize, 100);

      return () => {
        window.removeEventListener('resize', resize, { passive: true });
      };
    }, [ref, setHeight]);

    return (
      <StyledIframe
        ref={useRefs(iframeRef, ref)}
        {...props}
        title={title}
        src={formUrl}
        allow="fullscreen"
        style={{ minHeight: height }}
      />
    );
  }
);

export default FeedbackFormEmbed;
