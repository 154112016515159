import { useLazyQuery } from '@apollo/client';
import Checkbox from '@core/components/form/Checkbox';
import FieldError from '@core/components/form/FieldError';
import { CHECK_EMAIL } from '@core/components/Signup/RegisterForm/components/EmailForm.query';
import { emailFormSchema } from '@core/components/Signup/RegisterForm/helpers/register-form.schema';
import {
  GoLoginMessage,
  Link,
  PrivacyField,
  RegisterFormInner,
} from '@core/components/Signup/RegisterForm/RegisterForm.styled';
import { Button, Loading, TextInput } from '@virtidev/toolbox';
import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { EmailFormInput } from '@core/components/Signup/RegisterForm/helpers/register-form.types';
import { BlockedRegionMessage } from '@core/components/Signup/RegisterForm/components/EmailForm.styled';
import LoadingPageIndicator from '@base/components/LoadingPageIndicator';

/**
 * @type {FC<{
 *    onSubmit: () => void,
 * }>}
 */
export const EmailForm = ({ children, onSubmit }) => {
  /** @type {UseFormReturn<EmailFormInput>} */
  const { register, getValues, trigger, clearErrors, setError } =
    useFormContext();
  const [checkEmail, { loading }] = useLazyQuery(CHECK_EMAIL, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  // Block APAC regions from signing up for HTC
  const [blockedRegion, setBlockedRegion] = useState(false);

  const [loadingIPInfo, setLoadingIPInfo] = useState(true);

  useEffect(() => {
    fetch('https://ipinfo.io/json?token=a7c8b3859378a8')
      .then((res) => res.json())
      .then((data) => {
        const blockedCountries = [
          'TW',
          'KR',
          'HK',
          'MO',
          'CN',
          'JP',
          'SG',
          'MY',
          'VN',
        ];
        if (blockedCountries.includes(data.country)) {
          setBlockedRegion(true);
        }
      })
      .catch((err) => {
        console.error('Geo API Error:', err);
      })
      .finally(() => {
        setLoadingIPInfo(false);
      });
  }, []);

  const submitForm = useCallback(
    async (e) => {
      if (e) {
        e.preventDefault();
      }
      const values = getValues();
      try {
        emailFormSchema.parse(values);

        const {
          data: { checkEmail: exists },
        } = await checkEmail({ variables: { email: values.Email } });

        if (exists) {
          setError(
            'Email',
            { message: 'Email is already in-use.' },
            { shouldFocus: true }
          );
          return;
        }

        clearErrors();
        onSubmit();
      } catch (e) {
        trigger(undefined, { shouldFocus: true });
      }
    },
    [trigger, clearErrors, getValues, onSubmit, setError, checkEmail]
  );

  if (loadingIPInfo) {
    return <LoadingPageIndicator />;
  }

  // Blocked region message
  if (blockedRegion) {
    return (
      <BlockedRegionMessage>
        <p>
          We're sorry, but free trials are unavailable in your region. To set up
          a trial, please{' '}
          <a
            href="https://virti.com/contact"
            style={{ textDecoration: 'underline' }}
          >
            contact sales
          </a>
          .
        </p>
      </BlockedRegionMessage>
    );
  }

  return (
    <RegisterFormInner onSubmit={submitForm}>
      <div>
        <TextInput
          {...register('Email')}
          placeholder="Work email address"
          suffix={loading && <Loading />}
        />
        <FieldError name="Email" />
      </div>
      <div>
        <TextInput
          type="password"
          {...register('Password')}
          placeholder="Password"
        />
        <FieldError name="Password" />
      </div>
      <PrivacyField>
        <Checkbox {...register('AcceptPrivacy')} id="accept-privacy" />
        <label className="description" htmlFor="accept-privacy">
          I agree to the{' '}
          <Link to="/privacy-policy" target="_blank">
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link to="/terms-conditions" target="_blank">
            Terms of Service.
          </Link>
        </label>
        <FieldError name="AcceptPrivacy" className="error" />
      </PrivacyField>
      {children}
      <Button color="secondary" type="submit" loading={loading}>
        Continue
      </Button>
      <GoLoginMessage>
        Already signed up? <Link to="/login">Sign in.</Link>
      </GoLoginMessage>
    </RegisterFormInner>
  );
};

export default EmailForm;
