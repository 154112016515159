import getConfig from '@base/lib/config';
import { publicRoutes } from '@core/helpers/publicRoutes';
import useUser from '@core/helpers/useUser';
import { parseDatetime } from '@virtidev/toolbox';
import { useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';

if (process.env.REACT_APP_ENV === 'production') {
  Userpilot.initialize('NX-b59dfdf7');
}

export const withUserPilot = (Component) => {
  const UserPilotProvider = ({ ...props }) => {
    const user = useUser();
    const location = useLocation();

    const { pathname } = location;

    const publicPage = useMemo(
      () => publicRoutes.find((route) => matchPath(pathname, route)),
      [pathname]
    );

    /**
     * For capturing the userpilot logs so we can assess the events they've sent and received
     */
    // useEffect(() => {
    //   const intercept = () => {
    //     if (!window?.userpilot?.log) {
    //       setTimeout(intercept, 100);
    //       return;
    //     }

    //     const upLog = window.userpilot.log;

    //     window.userpilot.log = (...args) => {
    //       console.log(...args);
    //       upLog(...args);
    //     };
    //   };
    //   intercept();
    // }, []);

    useEffect(() => {
      if (user.ID && user.ID !== '0') {
        const prefix =
          process.env.REACT_APP_ENV === 'production'
            ? 'prod'
            : process.env.REACT_APP_ENV;
        const { Organisation } = user;

        Userpilot.identify(`${prefix}-${user.ID}`, {
          name: user.Name,
          email: user.Email,
          created_at: user.Created,
          org_trial: Organisation?.Trial,
          org_expiry: Organisation?.Trial
            ? parseDatetime(Organisation?.TrialExpiryDate)?.toISOString?.()
            : null,
          customer_stage: Organisation?.CustomerStage,
          type: user.UserType,
          orgStage: Organisation?.CustomerStage,
        });
        return;
      }
      // if (publicPage) {
      //   Userpilot.anonymous();
      //   return;
      // }
    }, [user, publicPage]);

    useEffect(() => {
      Userpilot.reload();
    }, [location]);

    return <Component {...props} />;
  };

  return UserPilotProvider;
};

export default withUserPilot;
