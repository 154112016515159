import { useLazyQuery } from '@apollo/client';
import WithConfirmationBox from '@base/HOCs/WithConfirmationBox';
import getConfig from '@base/lib/config';
import useFlashMessage from '@core/components/FlashMessage';
import { PlanFeature } from '@core/components/Pricing/components/PlanFeature';
import {
  GENERATE_CHECKOUT,
  UPDATE_ACCOUNT,
  UPGRADE_PREVIEW,
} from '@core/components/Pricing/Pricing.query';
import {
  PlanBigPrice,
  PlanContainer,
  PlanFeatures,
  PlanNote,
  PlanPriceWrapper,
} from '@core/components/Pricing/Pricing.styled';
import { isIframe } from '@core/helpers/useIsIframe';
import useUser from '@core/helpers/useUser';
import { Button, Chip, parseDatetime } from '@virtidev/toolbox';
import { differenceInDays, setHours, setMinutes, setSeconds } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

export const PlanCard = ({ plan, bill, org, setConfirmAsLoading, confirm }) => {
  const { addFlashMessage } = useFlashMessage();
  const history = useHistory();
  const { ID, Email, FirstName, Surname } = useUser();
  const {
    Title,
    Type,
    Editors,
    Learners,
    InteractiveVideos,
    VirtualHumans,
    SessionsPerMonth,
  } = plan;

  const [generateCheckout, { loading: checkoutLoading }] =
    useLazyQuery(GENERATE_CHECKOUT);

  const [getUpgradePreview, { loading: previewLoading }] =
    useLazyQuery(UPGRADE_PREVIEW);
  const [updateAccount, { loading: updateLoading }] =
    useLazyQuery(UPDATE_ACCOUNT);

  const isMonthly = bill === 'monthly';

  const trial = useMemo(() => {
    if (!org?.TrialExpiryDate) {
      return null;
    }
    const expiry = parseDatetime(org.TrialExpiryDate);
    const now = setHours(setMinutes(setSeconds(new Date(), 0), 0), 0);

    return Math.max(differenceInDays(expiry, now), 0);
  }, [org?.TrialExpiryDate]);

  const orgType = useMemo(() => {
    const platform = org?.PlatformLicense?.Type;
    if (platform) {
      return platform;
    }
    return org?.CustomerStage;
  }, [org]);

  const [cost, priceId, extraCost] = useMemo(
    /** @return {[number, string, number]} */
    () => {
      if (Type === 'PRO') {
        if (isMonthly) {
          return [199, getConfig('REACT_APP_PRO_MONTH_PRICEID'), 750];
        }
        return [1999, getConfig('REACT_APP_PRO_YEAR_PRICEID'), 750];
      }
      if (isMonthly) {
        return [99, getConfig('REACT_APP_STARTER_MONTH_PRICEID'), 1000];
      }
      return [949, getConfig('REACT_APP_STARTER_YEAR_PRICEID'), 1000];
    },
    [Type, isMonthly]
  );

  const purchaseLicense = useCallback(async () => {
    if (!ID || !org?.ID) {
      return;
    }
    const results = await generateCheckout({
      variables: {
        orgId: org.ID,
        priceId,
      },
    });
    const url = results?.data?.generateCheckoutSession?.URL;

    if (!url) {
      addFlashMessage('Unable to obtain Stripe URL', 'error');
      return;
    }
    window.location.href = url;
  }, [ID, org?.ID, addFlashMessage, generateCheckout, priceId]);

  const upgradeLicense = useCallback(async () => {
    if (!ID || !org?.ID) {
      return;
    }
    const results = await getUpgradePreview({
      variables: {
        orgId: org.ID,
        priceId,
      },
    });

    if (!results?.data?.getUpgradePreview) {
      addFlashMessage('Unable to obtain update data', 'error');
      return;
    }
    const {
      ProrationAmount,
      Currency,
      Message: upgradeMessage,
    } = results?.data?.getUpgradePreview;

    confirm(
      async () => {
        setConfirmAsLoading(true);
        const results = await updateAccount({
          variables: {
            orgId: org.ID,
            priceId,
          },
        });
        setConfirmAsLoading(false);

        if (!results?.data?.upgradeSubscription) {
          addFlashMessage('Unable to update subscription data', 'error');
          return;
        }
        const {
          Status,
          Message: subscriptionMessage,
          NewPlan,
        } = results.data.upgradeSubscription;

        addFlashMessage(
          subscriptionMessage,
          Status === 'success' ? 'success' : 'info'
        );
        history.push('/billing');
      },
      'Please confirm the upgrade',
      upgradeMessage
    );
  }, [
    ID,
    org?.ID,
    addFlashMessage,
    confirm,
    getUpgradePreview,
    history,
    priceId,
    updateAccount,
    setConfirmAsLoading,
  ]);

  const button = useMemo(() => {
    if (!ID) {
      return (
        <Button
          color="turquoise"
          design="outline"
          type="link"
          as="a"
          href="https://app.virti.com/register"
          target={isIframe() ? '_top' : null}
        >
          Get started
        </Button>
      );
    }

    if (orgType === 'FREE_TRIAL') {
      return (
        <Button
          color="turquoise"
          design="outline"
          onClick={purchaseLicense}
          loading={checkoutLoading}
        >
          Purchase
        </Button>
      );
    }

    if (orgType === 'STARTER' && Type === 'PRO') {
      return (
        <Button
          color="turquoise"
          design="outline"
          onClick={upgradeLicense}
          loading={previewLoading}
        >
          Upgrade
        </Button>
      );
    }

    if ((orgType === 'STARTER' || orgType === 'PRO') && Type === orgType) {
      return (
        <Button color="turquoise" design="outline" disabled>
          Owned
        </Button>
      );
    }

    let subject = '';
    if (Type === 'PRO') {
      subject =
        ID && org
          ? `Request to downgrade plan to Pro plan for ${org.Name} (${org.ID})`
          : 'Request to downgrade plan to Pro plan';
    }

    if (Type === 'STARTER') {
      subject =
        ID && org
          ? `Request to downgrade plan to Starter plan for ${org.Name} (${org.ID})`
          : 'Request to downgrade plan to Starter plan';
    }

    return (
      <Button
        color="turquoise"
        design="outline"
        type="link"
        as="a"
        href={`https://www.virti.com/contact/?message=${subject}&email=${Email}&firstname=${FirstName}&lastname=${Surname}`}
        target={isIframe() ? '_top' : null}
      >
        Contact sales
      </Button>
    );
  }, [
    ID,
    orgType,
    Type,
    Email,
    FirstName,
    Surname,
    purchaseLicense,
    upgradeLicense,
    org,
    previewLoading,
    checkoutLoading,
  ]);

  return (
    <PlanContainer
      title={
        <>
          <div>{Title}</div>
          <div>
            {Type === 'PRO' ? (
              <Chip color="pink">Most popular</Chip>
            ) : (
              !!trial &&
              !!ID && <PlanNote $small>Trial ends in {trial} days</PlanNote>
            )}
          </div>
        </>
      }
      footer={
        extraCost ? (
          <>Extra sessions cost ${extraCost.toLocaleString()} per 500</>
        ) : null
      }
    >
      <PlanPriceWrapper>
        <PlanBigPrice>${cost.toLocaleString()}</PlanBigPrice>
        {isMonthly ? <>per admin/month</> : <>per admin/year</>}
      </PlanPriceWrapper>
      {button}
      <PlanFeatures>
        <PlanFeature>up to {Editors} administrators</PlanFeature>
        <PlanFeature>{Learners} learners</PlanFeature>
        <PlanFeature>{VirtualHumans} Virtual Humans</PlanFeature>
        <PlanFeature>{InteractiveVideos} Interactive Videos</PlanFeature>
        <PlanFeature>{SessionsPerMonth} sessions/month</PlanFeature>
        {Type === 'PRO' && <PlanFeature>SCORM exports</PlanFeature>}
      </PlanFeatures>
    </PlanContainer>
  );
};

export default WithConfirmationBox(PlanCard);
