import styled, { css } from 'styled-components';

export const StyledBannerCTA = styled.div`
  ${({ theme: { spacing, color, borderRadius, font } }) => css`
    padding: ${spacing.sm} ${spacing.lg};
    ${font.label};
    background-color: ${color.turquoise20};
    color: #000;
    border-radius: ${borderRadius.sm};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;
