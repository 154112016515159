import {
  BrandImage,
  BrandImageContainer,
  LogoLink,
  LogoSvg,
} from '@core/components/Sidebar/Sidebar.styled';
import darkLogo from '../../images/virti-dark.svg';
import darkLogoSmall from '../../images/virti-dark-small.svg';
import lightLogo from '../../images/virti-light.svg';
import lightLogoSmall from '../../images/virti-light-small.svg';
import useUser from '@core/helpers/useUser';
import { useMemo } from 'react';
import { getResizeImage } from '@core/helpers/imageResizing/getResizeImage';
import { useTheme } from 'styled-components';
import EasterEgg from '@core/components/EasterEgg';

export const SidebarLogo = ({ onClick }) => {
  const { type } = useTheme();
  const { Organisation } = useUser();

  const brandImage = useMemo(() => {
    if (!Organisation?.BrandImageMedia?.TusID) {
      return null;
    }
    return getResizeImage(Organisation.BrandImageMedia.TusID, {
      resize: {
        width: 180,
      },
    });
  }, [Organisation]);

  return (
    <LogoLink
      aria-label="Virti dashboard"
      to="/"
      onClick={onClick}
      $branded={!!brandImage}
    >
      {brandImage ? (
        <BrandImageContainer>
          <BrandImage src={brandImage} />
        </BrandImageContainer>
      ) : (
        <>
          <EasterEgg />
          <LogoSvg
            src={type === 'light' ? darkLogo : lightLogo}
            className="big-logo"
          />
          <LogoSvg
            src={type === 'light' ? darkLogoSmall : lightLogoSmall}
            className="small-logo"
          />
        </>
      )}
    </LogoLink>
  );
};

export default SidebarLogo;
