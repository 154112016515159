import { useLazyQuery, useMutation } from '@apollo/client';
import NavigationPromptManual from '@base/components/NavigationPromptManual';
import { EDIT_ORGANISATION } from '@base/queries/OrganisationQueries';
import { READ_MEMBER_BASIC } from '@base/queries/UserQueries';
import { StoreLogin } from '@base/utility/LoginUtility';
import { CanSeeCXDashboard } from '@base/utility/Permissions';
import useFlashMessage from '@core/components/FlashMessage';
import { OrganisationImageUpload } from '@core/components/Organisation/EditOrganisationForm/components/OrganisationImageUpload';
import { editOrganisationSchema } from '@core/components/Organisation/EditOrganisationForm/EditOrganisation.schema';
import {
  OrganisationFieldsBlock,
  OrganisationForm,
  OrganisationImagesBlock,
} from '@core/components/Organisation/EditOrganisationForm/EditOrganisationForm.styled';
import { Savebar } from '@core/components/Page';
import useUser from '@core/helpers/useUser';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, TextInput } from '@virtidev/toolbox';
import { pick } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export const EditOrganisationForm = ({ organisation }) => {
  const { addFlashMessage } = useFlashMessage();
  const { ID: userId } = useUser();
  const formMethods = useForm({
    defaultValues: organisation,
    resolver: zodResolver(editOrganisationSchema),
  });

  const {
    register,
    handleSubmit: submit,
    formState: { isDirty, errors },
    reset,
  } = formMethods;

  const [editOrg, { loading: savingOrg }] = useMutation(EDIT_ORGANISATION, {
    notifyOnNetworkStatusChange: true,
  });

  const [reloadUserData, { loading: loadingUser }] = useLazyQuery(
    READ_MEMBER_BASIC,
    { notifyOnNetworkStatusChange: true }
  );

  useEffect(() => {
    reset(organisation, { keepDirtyValues: true, keepErrors: true });
  }, [organisation, reset]);

  const saveOrg = useMemo(
    () =>
      submit(async (input) => {
        const { data } = await editOrg({
          variables: {
            Input: {
              ID: organisation.ID,
              ...pick(input, [
                'Name',
                'Description',
                'LogoMediaID',
                'BrandImageMediaID',
              ]),
            },
          },
        });

        if (!data?.updateOrganisation?.ID) {
          addFlashMessage('Error saving organisation data', 'error');
          return;
        }
        const { data: dataUser } = await reloadUserData({
          variables: { ID: userId },
        });

        if (!dataUser?.readOneMember?.ID) {
          addFlashMessage(
            'Saved organisation data, you may need to refresh your login to see the changes.',
            'warning'
          );
          return;
        }
        StoreLogin(null, null, dataUser.readOneMember, null, organisation.ID);
        addFlashMessage('Saved orgnisation data');
      }),
    [addFlashMessage, editOrg, organisation.ID, reloadUserData, submit, userId]
  );

  return (
    <FormProvider {...formMethods}>
      <NavigationPromptManual
        when={isDirty}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <OrganisationForm onSubmit={saveOrg}>
        <OrganisationImagesBlock>
          <OrganisationImageUpload
            name="LogoMedia"
            round
            label="Organisation logo"
          />
          {CanSeeCXDashboard() && (
            <OrganisationImageUpload
              name="BrandImageMedia"
              label="Organisation brand image"
            />
          )}
        </OrganisationImagesBlock>

        <OrganisationFieldsBlock>
          <TextInput
            {...register('Name')}
            error={errors?.Name}
            label="Organisation name"
            description={errors?.Name?.message}
          />
          <TextInput
            {...register('Description')}
            label="Organisation description"
            multiline
          />
        </OrganisationFieldsBlock>
        <Savebar>
          <Button
            loading={savingOrg || loadingUser}
            disabled={!isDirty}
            onClick={saveOrg}
          >
            Save
          </Button>
        </Savebar>
      </OrganisationForm>
    </FormProvider>
  );
};
