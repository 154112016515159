import { useCallback, useEffect, useRef, useState } from 'react';

// Easing function: Exponential decay
/** @type {(t: number) => number} */
const easeOutExpo = (t) => {
  return t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
};

// generates a slowly incrementing progress value based on the given expected time amount
/** @type {()=> {fakeProgress: number; startProgressIncrementing: (expectedTimeMS: number, forceRestart: boolean) => void; stopProgress: () => void;}} */
export const useFakeProgressValue = () => {
  const [progress, setProgress] = useState(0);

  const progressRef = useRef(progress);
  progressRef.current = progress;

  let timerRef = useRef(/** @type {number | undefined} */ (undefined));

  /** @type {(expectedTimeMS: number, forceRestart: boolean) => void} */
  const startProgressIncrementing = useCallback(
    (expectedTimeMS, forceRestart) => {
      if (timerRef.current && !forceRestart) {
        return;
      }
      const maxProgress = 99; // Stall at 99%
      const startTime = Date.now();
      const updateInterval = 100; // Update every 100ms
      setProgress(0);
      clearTimeout(timerRef.current);
      timerRef.current = undefined;
      const updateProgress = () => {
        const elapsedTime = Date.now() - startTime;
        const timeFraction = Math.min(elapsedTime / expectedTimeMS, 1);

        // Easing function for smooth, decelerating progress
        const easedProgress = maxProgress * easeOutExpo(timeFraction);

        setProgress(easedProgress);

        if (timeFraction < 1) {
          timerRef.current = window.setTimeout(updateProgress, updateInterval);
        }
      };

      // Start the progress updates
      updateProgress();
    },
    []
  );

  // Cleanup effect to clear timer on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const stopProgress = useCallback(() => {
    console.log('stopProgress');
    clearTimeout(timerRef.current);
  }, []);

  return { fakeProgress: progress, startProgressIncrementing, stopProgress };
};
