import {
  ForwardedRef,
  HTMLAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useResize } from '../../../helpers/useResize';
import { Iframe, FrameContainer } from '../Unity.styled';
import { isPublicSimPlayerPage } from '@core/components/Simulation/helpers/isPublicSimPlayerPage';
import { useLocation } from 'react-router-dom';

const viewCap = 0.7;

const minWidth = 300;

export const UnityIframe = forwardRef(
  /**
   * @param {{
   *    src: string,
   *    onResize: (dimensions: { width: number | string; height: number | string }) => void,
   *    show?: boolean,
   * } & HTMLAttributes<HTMLDivElement>} props
   * @param {ForwardedRef<HTMLIFrameElement>} ref
   * @returns
   */
  ({ src, onResize, show, ...props }, ref) => {
    const location = useLocation();
    const [containerRef, setContainerRef] = useState(
      /** @type {HTMLDivElement | null} */ (null)
    );
    const [dimensions, setDimensions] = useState(
      /** @type {{ width: number | string; height: number | string }} */
      ({
        width: '100%',
        height: '100%',
      })
    );

    // only set the url once, other attempts to set it will refresh the iframe
    const [url] = useState(src);

    const resize = useCallback(() => {
      if (
        !containerRef ||
        !containerRef.offsetWidth ||
        !containerRef.offsetHeight
      ) {
        return;
      }
      const oldHeight = containerRef.style.height;
      containerRef.style.height = null;

      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      // we want to cap at 70% view height
      const vh =
        Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        ) * (isPublicSimPlayerPage(location) ? 1 : viewCap);

      const width = Math.min(vw, Math.max(minWidth, containerRef.offsetWidth));
      const height = Math.min(
        vh,
        Math.max((minWidth / 16) * 9, (width / 16) * 9)
      );

      containerRef.style.height = oldHeight;
      setDimensions({ width: (height / 9) * 16, height });
    }, [containerRef, location]);

    useEffect(() => {
      if (dimensions.width && dimensions.height) {
        onResize(dimensions);
      }
    }, [dimensions, onResize]);

    useResize(containerRef, resize);

    useEffect(() => {
      try {
        // try to replace the url by going inside the iframe state
        containerRef
          ?.querySelector('iframe')
          ?.contentWindow?.history?.replaceState(null, '', src);
      } catch (e) {
        // doesn't matter, it tried
      }
    }, [src, containerRef]);

    return (
      <FrameContainer
        ref={setContainerRef}
        {...props}
        $show={show}
        style={{ height: dimensions.height }}
      >
        <Iframe ref={ref} src={url} style={{ maxWidth: dimensions.width }} />
      </FrameContainer>
    );
  }
);

UnityIframe.defaultProps = {
  onResize: () => null,
};
