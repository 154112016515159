import { addHours, addMinutes } from 'date-fns';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import { parseDatetime } from '@virtidev/toolbox';
import { isIframe } from '@core/helpers/useIsIframe';

let start = new Date();

export const setupOfflinePlugin = () => {
  OfflinePluginRuntime.install({
    onInstalled: () => null,
    onUpdating: () => null,
    onUpdateReady: () => {
      const iframe = isIframe();
      const now = new Date();
      const threshold = addMinutes(start, 2);

      if (
        iframe ||
        window.location.hostname === 'localhost' ||
        now < threshold
      ) {
        console.log('Updating assets...');
        OfflinePluginRuntime.applyUpdate();
        return;
      }

      const prevFailed = localStorage.getItem('offline-plugin-failed');
      const failed = parseDatetime(prevFailed);
      const retryThreshold = addHours(start, 6);

      if (iframe || (failed && failed < retryThreshold)) {
        return;
      }

      document.body.classList.add('virti-offline-update');
    },
    onUpdated: () => {
      localStorage.removeItem('offline-plugin-failed');
      document.body.classList.remove('virti-offline-update');
      start = new Date();
    },
    onUpdateFailed: () => {
      console.error('Updating assets failed');
      const iframe = isIframe();
      const retryThreshold = addHours(start, 6);
      const prevFailed = localStorage.getItem('offline-plugin-failed');
      const failed = parseDatetime(prevFailed);

      if (
        iframe ||
        window.location.hostname === 'localhost' ||
        !failed ||
        failed >= retryThreshold
      ) {
        localStorage.setItem('offline-plugin-failed', new Date().toISOString());
        window.location.reload();
      }
    },
  });
};

export default setupOfflinePlugin;
