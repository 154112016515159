import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LoadingIndicator from './LoadingIndicator';
import { HoveringChildTooltip } from './HelpTooltip';
import { LimitChars } from '../utility/StringUtility';
import LinkWithPrevState from './LinkWithPrevState';
import WithConfirmationBox from '../HOCs/WithConfirmationBox';
import Button from './buttons/Button';
import { useMutation } from '@apollo/client';
import {
  REMOVE_VIDEO_FROM_SIMULATION,
  SET_START_VIDEO,
} from '../queries/SimulationQueries';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledThumbnailWrapper = styled.div`
  margin: 0.25rem;
  width: 173px;
  height: 108px;
  position: relative;
  &:hover div {
    display: block;
  }
  &:hover > div {
    display: flex;
  }
  &:hover button {
    display: initial;
  }
`;
const StyledTranscodingIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--form-section-bg-color);
  border-radius: 0 0 0 11px;
  border: 1px solid #000;
  padding: 0.4rem;
`;

const StyledThumbnailLink = styled(LinkWithPrevState)`
  position: relative;
  background: none;
  height: 100%;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  max-width: 40rem;
  border: 0.2rem solid rgba(0, 0, 0, 0);
  ${(props) =>
    props.mutating &&
    css`
      pointer-events: none;
    `}
  ${(props) =>
    props.selected &&
    css`
      border-color: var(--selected-color);
    `}
  img {
    width: 100%;
    height: 100%;
  }
`;

const StyledSetStartVideoButton = styled(Button)`
  position: absolute;
  padding: 0.3rem 0.5rem;
  display: none;
  bottom: 0.2rem;
  left: 0.2rem;
  z-index: 1;
  svg {
    position: relative;
    top: -1px;
  }
`;
const StyledStartVideoIndicator = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 0.5rem;
  display: block;
  left: 0.4rem;
  color: #ffe000;
`;
const StyledTranscodeError = styled.div`
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0;
  display: block;
  color: var(--warning-color-2);
  text-align: center;
`;

const VideoBarThumbnail = (props) => {
  const [mutating, setMutating] = useState(false);
  const [removeVideoFromSim] = useMutation(REMOVE_VIDEO_FROM_SIMULATION, {
    onCompleted: () => {
      props.addFlashMessage('Removed video from interactive video');
      setMutating(false);
    },
    onError: () => {
      props.addFlashMessage('Failed to remove video from interactive video', 'error');
      setMutating(false);
    },
    variables: { SimulationID: props.simulationID, VideoID: props.video.ID },
  });
  const [setStartVideo] = useMutation(SET_START_VIDEO, {
    onCompleted: () => {
      props.addFlashMessage('Updated start video');
      setMutating(false);
    },
    onError: () => {
      props.addFlashMessage('Failed to updated start video', 'error');
      setMutating(false);
    },
    variables: { SimulationID: props.simulationID, VideoID: props.video.ID },
  });

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (props.onClick) {
        props.onClick(props.video.ID);
      }
    },
    [props]
  );

  return (
    <StyledThumbnailWrapper key={props.video.ID}>
      {props.video.Title && (
        <HoveringChildTooltip>
          {LimitChars(props.video.Title, 35)}
        </HoveringChildTooltip>
      )}
      <StyledThumbnailLink
        mutating={mutating}
        to={`/simulations/${props.simulationID}/videos/${props.video.ID}${
          props.pageType !== 'details' ? '/' + props.pageType : ''
        }`}
        onClick={handleClick}
        selected={props.video.ID === props.selectedVideoID}
      >
        {(props.video.TranscodingStatus === 'Transcoding' || mutating) && (
          <StyledTranscodingIndicator>
            <LoadingIndicator size="small" />
          </StyledTranscodingIndicator>
        )}
        {props.isStartVideo && (
          <StyledStartVideoIndicator
            icon="star"
            title="This is the start video"
          />
        )}
        {props.video.TranscodingStatus === 'Error' && (
          <StyledTranscodeError title="There was an error uploading this video">
            <FontAwesomeIcon icon="exclamation-triangle" />
          </StyledTranscodeError>
        )}
        <img src={props.video.PosterURL} alt={props.video.PosterURL} />
      </StyledThumbnailLink>
    </StyledThumbnailWrapper>
  );
};

VideoBarThumbnail.propTypes = {
  simulationID: PropTypes.string.isRequired,
  video: PropTypes.object.isRequired,
  selectedVideoID: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  videoCount: PropTypes.number.isRequired,
  isStartVideo: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default withFlashMessaging(WithConfirmationBox(VideoBarThumbnail));
