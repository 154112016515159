import getConfig from '@base/lib/config';
import * as Sentry from '@sentry/browser';

/**
 *
 * @param {{
 *    email: string,
 *    firstname: string,
 *    lastname: string,
 *    company: string,
 *    dataConsent: boolean,
 *    pageName: string,
 *    marketingConsent?: boolean,
 * }} params
 */
export const submitHubspotData = async ({
  email,
  firstname,
  lastname,
  company,
  // companySize,
  // interests,
  // usage,
  // vertical,
  // jobtitle,
  // country,
  pageName,
  dataConsent,
  // marketingConsent,
}) => {
  const submission = {
    fields: [
      {
        objectTypeId: '0-1',
        name: 'email',
        value: email,
      },
      {
        objectTypeId: '0-1',
        name: 'firstname',
        value: firstname,
      },
      {
        objectTypeId: '0-1',
        name: 'lastname',
        value: lastname,
      },
      {
        objectTypeId: '0-1',
        name: 'company',
        value: company,
      },
      // {
      //   objectTypeId: '0-1',
      //   name: 'signup_type',
      //   value: 'web', // hardcoded here, is only different on the mobile app
      // },
      // {
      //   objectTypeId: '0-1',
      //   name: 'country_form_only',
      //   value: '',
      // },
      // vertical && {
      //   objectTypeId: '0-2',
      //   name: 'sales_vertical',
      //   value: vertical,
      // },
      // companySize && {
      //   objectTypeId: '0-1',
      //   name: 'company_size',
      //   value: companySize,
      // },
      // {
      //   objectTypeId: '0-1',
      //   name: 'interest_in_using_virti',
      //   value: interests,
      // },
      // usage && {
      //   objectTypeId: '0-1',
      //   name: 'how_often_do_you_plan_to_use_virti_',
      //   value: usage,
      // },
      // jobtitle && {
      //   objectTypeId: '0-1',
      //   name: 'jobtitle',
      //   value: jobtitle,
      // },
    ].filter(Boolean),

    context: {
      hutk: document.cookie
        .split('; ')
        .find((row) => row.startsWith('hubspotutk='))
        ?.split('=')[1],
      pageUri: window.location.host + window.location.pathname,
      pageName,
    },

    legalConsentOptions: {
      consent: {
        consentToProcess: dataConsent,
        text: 'I agree to allow Example Company to store and process my personal data.',
        // communications: [
        //   {
        //     value: marketingConsent,
        //     subscriptionTypeId: 999,
        //     text: 'I agree to receive marketing communications from Example Company.',
        //   },
        // ],
      },
    },
  };

  if (process.env.REACT_APP_ENV !== 'production') {
    console.log(
      'Skipping hubspot submission for non-production environment',
      submission
    );
    return Promise.resolve();
  }

  return fetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/7277851/7f5e3901-5bd6-441f-9e15-abf16c6b451d',
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(submission),
    }
  ).catch((error) => {
    console.error(error);
    Sentry.captureException(error);
  });
};

export default submitHubspotData;
