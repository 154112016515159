import { useQuery } from '@apollo/client';
import { Button } from '@virtidev/toolbox';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setCanViewUserSideVirtualHumans } from '../../../../../ApolloReactiveVars';
import LoadingPageIndicator from '../../../../../components/LoadingPageIndicator';
import PageLoadError from '../../../../../components/PageLoadError';
import { IsMobile, WeSupportUnity } from '../../../../../utility/BrowserCheck';
import { useCurrentOrganisation } from '../../helpers/useCurrentOrganisation';
import useStateUrl from '../../helpers/useStateUrl';
import useUser from '../../helpers/useUser';
import { deepLinkValueContext } from '../DeepLink/DeepLinkProvider';
import { generateDeepLink } from '../DeepLink/helpers/generateDeepLink';
import FeedbackFormEmbed from '@core/components/FeedbackForms/FeedbackFormEmbed'; 
import { READ_VIRTUAL_HUMAN } from './VirtualHumanManager.query';
import { StyledMessage, VirtualHuman } from './VirtualHumanManager.styled';

export const VirtualHumanManager = ({
  virtualHumanId,
  onComplete,
  isPreviewPlayer,
  unitID,
  ...props
}) => {
  const deepLink = useContext(deepLinkValueContext);
  const [key, setKey] = useState(2);
  const { pathname } = useLocation();
  const { OrganisationID } = useUser();

  const [completeRedirectPath, setCompleteRedirectPath] = useState(null);

  // save which stage we're on 'before', 'vh', 'after' or 'complete'
  const { value: stage, updateValue: setStage } = useStateUrl({
    key: 'stage',
    replace: true,
  });

  const { loading, error, data } = useQuery(READ_VIRTUAL_HUMAN, {
    variables: { ID: virtualHumanId },
  });

  useCurrentOrganisation(data?.readOneVirtualHuman?.OrganisationID);

  const goToLink = useCallback(() => {
    window.onbeforeunload = null;
    if (deepLink) {
      window.location = deepLink;
    } else {
      window.location = generateDeepLink(pathname, OrganisationID);
    }
  }, [deepLink, pathname, OrganisationID]);

  useEffect(() => {
    if (Number(data?.readOneVirtualHuman?.ID) > 0) {
      setCanViewUserSideVirtualHumans(true);
    }
  }, [data]);

  // find the before and after form data
  const [beforeVH, afterVH] = useMemo(() => {
    const beforeForm = _.get(data, 'readOneVirtualHuman.FeedbackFormBefore');
    const afterForm = _.get(data, 'readOneVirtualHuman.FeedbackFormAfter');
    const shouldShowBefore =
      !isPreviewPlayer &&
      (beforeForm?.AllowMultipleSubmissions ||
        !data?.readOneVirtualHuman.FeedbackFormBeforeCompleted);

    const shouldShowAfter =
      !isPreviewPlayer &&
      (afterForm?.AllowMultipleSubmissions ||
        !data?.readOneVirtualHuman.FeedbackFormAfterCompleted);

    return [shouldShowBefore && beforeForm, shouldShowAfter && afterForm];
  }, [data, isPreviewPlayer]);

  // initial stage value after data is loaded
  useEffect(() => {
    if (!data || ['before', 'vh', 'after', 'complete'].includes(stage)) {
      return;
    }

    setStage('before');
  }, [stage, data, setStage]);

  // ensure when vh changes the redirect path is reset
  // this prevents self-redirects for final VHs in a course
  useEffect(() => {
    setCompleteRedirectPath(null);
  }, [virtualHumanId]);

  const gotoNextStage = useCallback(
    (nextUnitURL) => {
      if (nextUnitURL) {
        setCompleteRedirectPath(nextUnitURL);
      }
      switch (stage) {
        case 'before':
          setStage('vh');
          return;
        case 'vh':
          setStage('after');
          return;
        case 'after':
          setStage('complete');
          // remove this call if using a complete screen render (below)
          onComplete(nextUnitURL ?? completeRedirectPath);
          return;
        default:
          return;
      }
    },
    [stage, setStage, completeRedirectPath, onComplete]
  );

  const restartSim = useCallback(() => {
    setStage('before');
    setKey(key + 1);
  }, [key, setStage]);

  if (error) {
    return <PageLoadError graphQLErrorObj={error} />;
  }

  if (loading) {
    return <LoadingPageIndicator />;
  }

  if (
    !WeSupportUnity() ||
    (IsMobile() && data?.readOneVirtualHuman?.PlayerType !== 'heygen')
  ) {
    return (
      <StyledMessage>
        <div>
          Unfortunately, web preview is currently only available in latest
          version of Google Chrome, Mozilla Firefox, and Microsoft Edge on a
          computer.
        </div>
        <Button color="primary" onClick={goToLink}>
          Use the Virti App
        </Button>
      </StyledMessage>
    );
  }

  let content = null;

  if (stage === 'before') {
    content = (
      <FeedbackFormEmbed
        hash={beforeVH?.Hash}
        formID={beforeVH?.ID}
        onComplete={gotoNextStage}
        trigger="BEGIN_VH"
        sourceID={virtualHumanId}
      />
    );
  }

  if (stage === 'after') {
    content = (
      <FeedbackFormEmbed
        hash={afterVH?.Hash}
        formID={afterVH?.ID}
        onComplete={gotoNextStage}
        trigger="END_VH"
        sourceID={virtualHumanId}
      />
    );
  }

  // // calling onComplete in gotoNextStage() when complete
  // if (stage === 'complete') {
  //   return <VirtualHumanCompleteContent onComplete={onComplete} />;
  // }

  return (
    <>
      {content}
      <VirtualHuman
        key={key}
        {...props}
        show={stage === 'vh'}
        virtualHumanId={virtualHumanId}
        onComplete={gotoNextStage}
        isPreviewPlayer={isPreviewPlayer}
        unitID={unitID}
      />
    </>
  );
};

export default VirtualHumanManager;
