import { PlanFeature } from '@core/components/Pricing/components/PlanFeature';
import {
  PlanBigPrice,
  PlanContainer,
  PlanFeatures,
  PlanPriceWrapper,
} from '@core/components/Pricing/Pricing.styled';
import { isIframe } from '@core/helpers/useIsIframe';
import useUser from '@core/helpers/useUser';
import { Button } from '@virtidev/toolbox';
import { useMemo } from 'react';

export const EnterprisePlanCard = ({ org }) => {
  const { ID, Email, FirstName, Surname } = useUser();

  const orgType = useMemo(() => {
    const platform = org?.PlatformLicense?.Type;
    if (platform) {
      return platform;
    }
    return org?.CustomerStage;
  }, [org]);

  const subject = useMemo(
    () =>
      ID && org
        ? `Request to upgrade to Enterprise plan for ${org.Name} (${org.ID})`
        : 'Request to upgrade to Enterprise plan',
    [ID, org]
  );
  return (
    <PlanContainer title="Enterprise">
      <PlanPriceWrapper>
        <PlanBigPrice>Let&apos;s talk</PlanBigPrice>
      </PlanPriceWrapper>
      <Button
        color="turquoise"
        design="fill"
        type="link"
        as="a"
        disabled={orgType === 'FULL_CONTRACT'}
        href={
          orgType === 'FULL_CONTRACT'
            ? null
            : `https://www.virti.com/contact/?message=${subject}&email=${Email}&firstname=${FirstName}&lastname=${Surname}`
        }
        target={isIframe() ? '_top' : null}
      >
        {orgType === 'FULL_CONTRACT' ? 'Owned' : 'Contact sales'}
      </Button>
      <PlanFeatures>
        <PlanFeature>Unlimited administrators</PlanFeature>
        <PlanFeature>Unlimited learners</PlanFeature>
        <PlanFeature>Content Creator role</PlanFeature>
        <PlanFeature>Unlimited Virtual Humans</PlanFeature>
        <PlanFeature>Unlimited Interactive Videos</PlanFeature>
        <PlanFeature>Unlimited sessions</PlanFeature>
        <PlanFeature>SCORM exports</PlanFeature>
        <PlanFeature>Custom pricing & support</PlanFeature>
      </PlanFeatures>
    </PlanContainer>
  );
};

export default EnterprisePlanCard;
