import { LDProvider } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo, useRef } from 'react';
import useUser from '../../helpers/useUser';
import getConfig from '../../../../../lib/config';

const REACT_APP_LAUNCH_DARKLY_CLIENT_ID = getConfig(
  'REACT_APP_LAUNCH_DARKLY_CLIENT_ID'
);
const REACT_APP_REMOTE_URI = getConfig('REACT_APP_REMOTE_URI');

const PREVIEW_REGEX =
  /deploy-preview-([0-9]+)--virti-([0-9a-zA-Z]+).netlify.app/;

export const LaunchDarklyProvider = ({ children, ...props }) => {
  const userData = useUser();
  const ldRef = useRef(/** @type {LDProvider | null} */ (null));

  let prefix =
    REACT_APP_REMOTE_URI === 'https://pikachu.virti.com/' ||
    REACT_APP_REMOTE_URI === 'https://raichu.virti.com/'
      ? 'prod'
      : 'dev';

  const user = useMemo(() => {
    const [, previewKey] = window.location.hostname.match(PREVIEW_REGEX) || [];

    if (!userData?.ID) {
      return {
        key: `${prefix}-0`,
        custom: {
          domain: window.location.hostname,
          previewKey,
        },
      };
    }
    const { ID, OrganisationID, Name, Email, UserType, Organisation } =
      userData;

    return {
      key: `${prefix}-${ID}`,
      name: Name,
      email: Email,
      privateAttributeNames: ['email'],
      custom: {
        domain: window.location.hostname,
        previewKey,
        type: UserType,
        orgKey: `${prefix}-${OrganisationID}`,
        orgPartner: Organisation?.Partner || '',
        license:
          Organisation?.PlatformLicense?.Type || Organisation?.CustomerStage,
      },
    };
  }, [userData, prefix]);

  useEffect(() => {
    if (!ldRef.current || !user) {
      return;
    }
    const { ldClient } = ldRef.current.state;

    if (!ldClient) {
      console.error('Cannot find LDClient needed to update user data');
      return;
    }
    ldClient.identify(user);
  }, [user]);

  return (
    <LDProvider
      {...props}
      ref={ldRef}
      clientSideID={REACT_APP_LAUNCH_DARKLY_CLIENT_ID}
      deferInitialization
      user={user}
    >
      {children}
    </LDProvider>
  );
};

export default LaunchDarklyProvider;
