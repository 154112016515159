import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { usePageScroll } from '../../../Page';
import PaginationControl from '../../../PaginationControl';
import { usePaginationUrl } from '../../../PaginationControl/helpers/usePaginationUrl';
import { Block, Card, Content } from '../../../Analytics/Analytics.styled';
import { MY_SIMULATION_SESSIONS } from './MySimulationSessions.query';
import { useUser } from '../../../../helpers/useUser';
import MySessionsTable from '../MySessionsTable';
import useStateUrl from '../../../../helpers/useStateUrl';
import { useDebouncedSave } from '../../../form/hooks/useDebouncedSave';
import {
  FilterContainer,
  SearchInput,
  SearchSwitch,
} from './MySimulationSessions.styled';
import useSortUrl from '../../../../helpers/useSortUrl';
import { created as sortOptions } from '../../../../helpers/sortCollections';

export const MySimulationSessions = () => {
  const {
    value: incomplete,
    updateValue: setIncomplete,
    reset: resetIncomplete,
  } = useStateUrl({
    key: 'incomplete',
    replace: true,
  });

  const { scrollTop } = usePageScroll();
  const { ID, OrganisationID } = useUser();

  const { page, pageSize, resetPage, controlProps } = usePaginationUrl({
    pageSize: 25,
    onchange: scrollTop,
  });

  const { value: search, updateValue: updateSearch } = useStateUrl({
    key: 'search',
  });

  const { sortField, sortDirection, updateSort } = useSortUrl(sortOptions);

  const filter = {
    Simulation: {
      ID: { ne: 0 },
      OrganisationID: { eq: OrganisationID },
      ...(search && { Title: { contains: search } }),
    },
    ...(incomplete === '1' ? {} : { Finished: { eq: true } }),
  };

  const { data, loading } = useQuery(MY_SIMULATION_SESSIONS, {
    variables: {
      ID,
      filter,
      limit: pageSize,
      offset: (page - 1) * pageSize,
      sort: {
        [sortField]: sortDirection,
      },
    },
  });

  const handleChange = useMemo(
    () =>
      _.debounce(({ target }) => {
        resetPage();
        if (!target.checked) {
          setIncomplete('1');
          return;
        }
        resetIncomplete();
      }, 10),
    [setIncomplete, resetPage, resetIncomplete]
  );

  const onUpdateUserSearch = useCallback(
    (value) => {
      updateSearch(value || '');
      resetPage();
    },
    [updateSearch, resetPage]
  );

  const debouncedSearchProps = useDebouncedSave(search, {
    onUpdate: onUpdateUserSearch,
    enter: true,
  });

  const sessions = [
    ...(data?.readOneMember?.AnalyticsSubmissions?.nodes || []),
  ].map((node) => {
    return {
      key: node.ID,
      title: node.Simulation.Title,
      startDateTime: node.Created,
      endDateTime: node.LastEdited === node.Created ? '' : node.LastEdited,
      score:
        node.Simulation.Quiz && !node.Simulation.HideScoresFromUsers
          ? node.Score
          : null,
      logUrl: `/my-profile/simulations/${node.ID}`,
    };
  });

  return (
    <Content>
      <Block>
        <Card>
          <FilterContainer>
            <SearchInput
              placeholder="Search by interactive video name..."
              {...debouncedSearchProps}
            />
            <SearchSwitch
              flipped
              checked={incomplete === '1'}
              onClick={handleChange}
            >
              Show Incomplete Sessions
            </SearchSwitch>
          </FilterContainer>
          <MySessionsTable
            loading={loading}
            sessions={sessions}
            title="Interactive Video"
            handleSort={updateSort}
            currentSort={{ sortField, sortDirection }}
          />
          <PaginationControl
            {...controlProps}
            total={
              data?.readOneMember?.AnalyticsSubmissions?.pageInfo?.totalCount
            }
          />
        </Card>
      </Block>
    </Content>
  );
};

export default MySimulationSessions;
