import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import LoadingPageIndicator from '../components/LoadingPageIndicator';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import withUser from '../HOCs/WithUser';
import { useQuery } from '@apollo/client';
import PageLoadError from '../components/PageLoadError';
import HeaderArea from '../components/HeaderArea';
import { READ_BADGES } from '../queries/BadgeQueries';
import Badge from '@core/components/Badge/Badge';
import WebPlayerWrapper from '../components/WebPlayerWrapper';
import SimulationOverview from '../apps/core/src/components/Simulation/SimulationOverview';
import {
  Card,
  CardTitle,
} from '../apps/core/src/components/Simulation/SimulationOverview/SimulationOverview.styled';
import { useLocation, useParams } from 'react-router-dom';
import { useDeepLink } from '../apps/core/src/components/DeepLink/hooks/useDeepLink';
import { READ_STUDENT_SIMULATION } from '../queries/LearnerQueries';
import { FALLBACK } from '@virtidev/toolbox';
import { useCurrentOrganisation } from '../apps/core/src/helpers/useCurrentOrganisation';
import { withPageTemplate } from '../apps/core/src/templates';

const StyledPageContentInner = styled.div`
  display: flex;
  flex-direction: ${({ showOverview }) => (showOverview ? 'row' : 'column')};
  flex: 1;
  align-items: stretch;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ showOverview }) =>
    showOverview &&
    css`
      flex: 1;
      margin-left: var(--content-side-padding);
    `}
`;

const OverviewContainer = styled.div`
  flex: ${({ showOverview }) => (showOverview ? 2 : 1)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

const StyledHeaderContent = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, minmax(70px, 1fr));

  > div {
    display: flex;
    flex-direction: column;
  }
`;

const StyledDataHeader = styled.div`
  color: #c3c3c3;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
`;

const StyledDataItem = styled.div`
  font-weight: 500;
`;

const BadgesCard = styled(Card)`
  flex: 1;

  > .body {
    display: grid;
    gap: var(--content-padding);
    ${({ showOverview }) =>
      showOverview
        ? css`
            grid-template-rows: repeat(auto-fill, 80px);
          `
        : css`
            grid-template-columns: repeat(3, minmax(250px, 1fr));
          `}
  }
`;

const MySimulationPage = (props) => {
  const [showOverview, setShowOverview] = useState(true);
  const [showSim, setShowSim] = useState(false);
  const { simulationID, sharetoken } = useParams();
  const { pathname } = useLocation();

  const query = useMemo(() => {
    const search = new URLSearchParams({
      simID: simulationID,
      content: true,
    });

    return search.toString();
  }, [simulationID]);

  useDeepLink(`${pathname}?${query}`);

  const { loading, error, data } = useQuery(READ_STUDENT_SIMULATION, {
    fetchPolicy: 'cache-and-network',
    variables: {
      sharetokenHeader: sharetoken,
      ID: simulationID,
    },
  });

  const simulation = useMemo(() => data?.readOneSimulation, [data]);

  useCurrentOrganisation(simulation?.OrganisationID);

  const {
    loading: badgeDataLoading,
    error: badgeDataError,
    data: badgeData,
  } = useQuery(READ_BADGES, {
    fetchPolicy: 'cache-and-network',
  });

  const thumbnailUrl = useMemo(
    () =>
      simulation?.ImageMedia?.URL ||
      simulation?.FirstVideo?.nodes?.[0]?.PosterURL,
    [simulation]
  );

  const videosLength = useMemo(() => {
    if (simulation?.TotalLength > 0) {
      return Math.ceil(simulation.TotalLength / 60);
    } else {
      return '0';
    }
  }, [simulation]);

  const completedOverview = useCallback(
    () => setShowOverview(false),
    [setShowOverview]
  );

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  if (loading || badgeDataLoading) {
    return <LoadingPageIndicator />;
  }
  if (error || badgeDataError) {
    return <PageLoadError graphQLErrorObj={error ? error : badgeDataError} />;
  }

  const badgeEdges = _.get(badgeData, 'readBadges.edges');

  return (
    <>
      <HeaderArea
        showImg={!showSim}
        imageSrc={thumbnailUrl}
        title={simulation ? simulation?.Title : '(Interactive video not found)'}
        type="simulation"
      >
        <StyledHeaderContent>
          <div>
            <StyledDataHeader>Length</StyledDataHeader>
            <StyledDataItem>
              {videosLength}{' '}
              <span style={{ fontSize: '0.7rem', color: '#c3c3c3' }}>mins</span>
            </StyledDataItem>
          </div>
          <div>
            <StyledDataHeader>Views</StyledDataHeader>
            <StyledDataItem>{simulation?.TotalViews}</StyledDataItem>
          </div>
          <div>
            <StyledDataHeader>Likes</StyledDataHeader>
            <StyledDataItem>{simulation?.Likes || 0}</StyledDataItem>
          </div>
        </StyledHeaderContent>
      </HeaderArea>
      {simulation && (
        <StyledPageContentInner showOverview={showOverview ? '1' : undefined}>
          <OverviewContainer showOverview={showOverview ? '1' : undefined}>
            {showOverview ? (
              <SimulationOverview
                simulation={simulation}
                onComplete={completedOverview}
              />
            ) : (
              <WebPlayerWrapper
                simulationID={simulationID}
                onShowSim={setShowSim}
                sharetoken={sharetoken}
              />
            )}
          </OverviewContainer>
          {badgeEdges && (
            <BadgesContainer showOverview={showOverview ? '1' : undefined}>
              <CardTitle>Available badges</CardTitle>
              <BadgesCard showOverview={showOverview ? '1' : undefined}>
                {badgeEdges.map((edge) => {
                  const badgeData = simulation?.MyBadges.nodes.find(
                    (badge) => badge.Label === edge.node.Label
                  );
                  return (
                    <Badge
                      key={edge.node.Label}
                      title={edge.node.Title}
                      created={badgeData?.Created}
                      unlocked={!!badgeData}
                    />
                  );
                })}
              </BadgesCard>
            </BadgesContainer>
          )}
        </StyledPageContentInner>
      )}
    </>
  );
};

MySimulationPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
};

export default _.flowRight(
  withUser,
  withPageTemplate({ pageKey: 'my-simulation-page' })
)(MySimulationPage);
