/** @type {(score: number) => void} */
export const sendSimSCORMComplete = (score) => {
  if (window.parent === window) {
    return;
  }
  window.parent.postMessage(
    { action: 'setCompleted', score, saveAndQuit: true },
    '*'
  );
};
