import { useMemo } from 'react';

export const isIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {}
  return true;
};

export const useIsIframe = () => {
  return useMemo(isIframe, []);
};
