import styled, { css } from 'styled-components';

export const BillingHeader = styled.h1`
  ${({ theme: { font } }) => css`
    ${font.h1}
  `}
`;

export const BillingContent = styled.div`
  ${({ theme: { spacing } }) => css`
    padding: ${spacing.md};
    display: flex;
    flex-direction: column;
    gap: ${spacing.md};
  `}
`;