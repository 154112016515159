import { createGlobalStyle, css } from 'styled-components';

export const GleapGlobalStyles = createGlobalStyle`
  ${({ $hidden }) =>
    $hidden &&
    css`
      .bb-feedback-button,
      .gleap-notification-container {
        display: none !important;
      }
    `}
`;
