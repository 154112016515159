import getConfig from '@base/lib/config';

function hubspotTrackingEnabled() {
  return process.env.REACT_APP_ENV === 'production';
}

export function initHubspotTracking() {
  if (
    hubspotTrackingEnabled() &&
    !document.getElementById('hs-script-loader')
  ) {
    const hubspotTrackingScript = document.createElement('script');
    hubspotTrackingScript.src = `https://js.hs-scripts.com/${getConfig(
      'REACT_APP_HUBSPOT_ACCOUNT_ID'
    )}.js`;
    hubspotTrackingScript.async = true;
    hubspotTrackingScript.defer = true;
    document.head.appendChild(hubspotTrackingScript);
  }
}

export function hubspotTrackPageView(path) {
  if (hubspotTrackingEnabled()) {
    const initialPageView = !Array.isArray(window._hsq);
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', path]);
    // First page view will be recorded automatically when the script loads https://developers.hubspot.com/docs/api/events/tracking-code#tracking-in-single-page-applications
    if (!initialPageView) _hsq.push(['trackPageView']);
  }
}

export function hubspotIdentify(user) {
  if (hubspotTrackingEnabled()) {
    const isAdmin =
      user.UserType.includes('admin') || user.UserType.includes('creator');
    const _hsq = (window._hsq = window._hsq || []);
    if (isAdmin) {
      _hsq.push([
        'identify',
        {
          email: user.Email,
          id: user.ID,
        },
      ]);
    }
  }
}
