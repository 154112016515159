import {
  PlanFeatureContainer,
  PlanFeatureIcon,
} from '@core/components/Pricing/Pricing.styled';

export const PlanFeature = ({ children, ...props }) => {
  return (
    <PlanFeatureContainer {...props}>
      <PlanFeatureIcon icon="success" /> {children}
    </PlanFeatureContainer>
  );
};
