import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { Icon } from '@virtidev/toolbox';

export const Container = styled.div`
  ${
    /** @param {{ theme: DefaultTheme, $transitionTime?: number }} p */
    ({ theme: { color }, $transitionTime = 300 }) => css`
      --sidebar-transition-time: ${$transitionTime}ms;
      --sidebar-bottom-space: 68px;
      position: relative;
      overflow: visible;
      min-height: 100%;
      height: 100%;
      z-index: 1004;
      background: ${color.white};
      color: ${color.contrast.white};

      min-width: var(--sidebar-width-contracted);
      transition: min-width var(--sidebar-transition-time);

      @media screen and (min-width: 768px) {
        min-width: var(--sidebar-width-expanded);

        &.sidebar-contracted {
          min-width: var(--sidebar-width-contracted);
        }
      }

      --sidebar-item-pad-height: 12px;

      @media screen and (min-height: 940px) {
        --sidebar-item-pad-height: 19px;
      }
    `
  }
`;

export const itemContainerCss = css`
  ${
    /**
     * @param {{ theme: DefaultTheme, $faded?: boolean }} p
     */
    ({
      theme: {
        fontFamily,
        fontSize,
        fontWeight,
        spacing,
        borderRadius,
        sidebar,
      },
      $faded,
    }) => css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 26px;
      white-space: nowrap;
      cursor: pointer;
      margin: 0 ${spacing.p2};
      border-radius: ${borderRadius.p1};

      font-family: ${fontFamily};
      font-size: ${fontSize.md};
      border: 0;

      background: ${sidebar.base.background};
      color: ${sidebar.base.color};

      .sidebar-icon {
        color: ${sidebar.base.icon};
      }

      &:hover {
        background: ${sidebar.highlight.background};
        color: ${sidebar.highlight.color};

        .sidebar-icon {
          color: ${sidebar.highlight.icon};
        }
      }

      &:active {
        background: ${sidebar.active.background};
        color: ${sidebar.active.color};
        font-weight: ${fontWeight.bold};
      }

      &.active {
        .sidebar-icon {
          color: ${sidebar.active.icon};
        }
      }

      ${$faded
        ? null
        : css`
            &.active {
              background: ${sidebar.active.background};
              color: ${sidebar.active.color};
              font-weight: ${fontWeight.bold};
            }
          `}

      padding: var(--sidebar-item-pad-height) 17px;
      transition: padding var(--sidebar-transition-time),
        width var(--sidebar-transition-time);

      .sidebar-hover & {
        padding: var(--sidebar-item-pad-height) 7px
          var(--sidebar-item-pad-height) 27px;
      }

      @media screen and (min-width: 768px) {
        .sidebar-contracted & {
          padding: var(--sidebar-item-pad-height) 17px;
        }
        .sidebar-hover.sidebar-contracted &,
        .sidebar-expanded & {
          padding: var(--sidebar-item-pad-height) 7px
            var(--sidebar-item-pad-height) 27px;
        }
      }
    `
  }
`;

export const itemContentCss = css`
  flex: 1;
  text-align: start;
  width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BrandImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const BrandImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  transition: margin var(--sidebar-transition-time),
    height var(--sidebar-transition-time), width var(--sidebar-transition-time);
`;

export const LogoSvg = styled(SVG)`
  position: absolute;
  top: 32px;
  transition: opacity var(--sidebar-transition-time),
    left var(--sidebar-transition-time);

  &.small-logo {
    width: 20px;
    left: 27px;
    opacity: 1;
  }

  .sidebar-hover &.small-logo {
    left: 38px;
    opacity: 0;
  }

  &.big-logo {
    min-width: 130px;
    width: 130px;
    left: 27px;
    opacity: 0;
  }

  .sidebar-hover &.big-logo {
    left: 38px;
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    .sidebar-contracted &.small-logo {
      left: 27px;
      opacity: 1;
    }

    .sidebar-hover.sidebar-contracted &.small-logo,
    .sidebar-expanded &.small-logo {
      left: 38px;
      opacity: 0;
    }

    .sidebar-contracted &.big-logo {
      left: 27px;
      opacity: 0;
    }

    .sidebar-hover.sidebar-contracted &.big-logo,
    .sidebar-expanded &.big-logo {
      left: 38px;
      opacity: 1;
    }
  }
`;

export const LogoLink = styled(Link)`
  ${
    /** @param {{ $branded?: boolean }} p */
    ({ $branded }) => css`
      display: block;
      margin-bottom: 10px;
      position: relative;
      padding: 32px 7px 10px;
      min-height: ${$branded ? '150px' : '100px'};
      height: ${$branded ? '150px' : '100px'};
      transition: padding var(--sidebar-transition-time);

      .sidebar-hover & {
        padding: 32px 18px 10px;
      }

      @media screen and (min-width: 768px) {
        .sidebar-contracted & {
          padding: 32px 7px 10px;
        }

        .sidebar-hover.sidebar-contracted &,
        .sidebar-expanded & {
          padding: 32px 18px 10px;
        }
      }
    `
  }
`;

export const Separator = styled.div`
  margin: 20px;
  border-top: 1px solid #eee;
`;

const closeSidebar = keyframes`
  0% {
    width: var(--sidebar-width-expanded);
  }
  100% {
    width: var(--sidebar-width-contracted);
  }
`;

/**
 * @param {DefaultTheme["spacing"]} spacing
 * @param {DefaultTheme["lineHeight"]} lineHeight
 * @param {boolean} [$inviteShow]
 */
const calcHeight = (spacing, lineHeight, $inviteShow) => {
  const multiplier = $inviteShow ? 2 : 1;
  const button = `(var(--sidebar-item-pad-height) * 2) + (1rem * ${lineHeight})`;
  return `((${button}) * ${multiplier}) + ${spacing.p2} + var(--sidebar-bottom-space)`;
};

export const Content = styled.div`
  ${
    /**
     * @param {{ theme: DefaultTheme, $inviteShow?: boolean }} p
     */
    ({ theme: { color, spacing, lineHeight }, $inviteShow }) => css`
      position: fixed;
      top: 0;
      left: 0;
      bottom: calc(${calcHeight(spacing, lineHeight, $inviteShow)});
      height: calc(100vh - (${calcHeight(spacing, lineHeight, $inviteShow)}));
      padding-bottom: ${spacing.p2};

      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      background: ${color.white};
      color: ${color.blue30};

      z-index: 3;

      transition: width var(--sidebar-transition-time),
        border-right var(--sidebar-transition-time);

      width: var(--sidebar-width-contracted);
      scrollbar-color: transparent transparent;
      scrollbar-width: none;

      .sidebar-hover & {
        width: var(--sidebar-width-expanded);
        scrollbar-color: unset;
        scrollbar-width: unset;
        overflow-x: hidden;
      }

      .sidebar-subopened & {
        border-right: 1px solid ${color.midnight30};
      }

      .sidebar-subclosed & {
        border-right: 0 solid ${color.midnight30};
      }

      @media screen and (min-width: 768px) {
        .sidebar-contracted & {
          width: var(--sidebar-width-contracted);
          scrollbar-color: transparent transparent;
          scrollbar-width: none;
        }
        .sidebar-hover.sidebar-contracted &,
        .sidebar-expanded & {
          width: var(--sidebar-width-expanded);
          scrollbar-color: unset;
          scrollbar-width: unset;
          overflow-x: hidden;
        }
        .sidebar-force-closed & {
          animation: ${closeSidebar} var(--sidebar-transition-time) linear 1;
        }
      }
    `
  }
`;

export const HelpItemWrapper = styled.div`
  ${({ theme: { color, spacing } }) => css`
    position: fixed;
    bottom: -1px;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    transition: width var(--sidebar-transition-time),
      border-right var(--sidebar-transition-time);
    overflow: hidden;
    border-top: 1px solid ${color.midnight30};
    background: ${color.white};
    padding: ${spacing.p2} 0 var(--sidebar-bottom-space);

    width: var(--sidebar-width-contracted);

    .sidebar-hover & {
      width: var(--sidebar-width-expanded);
    }

    .sidebar-subopened & {
      border-right: 1px solid ${color.midnight30};
    }

    .sidebar-subclosed & {
      border-right: 0 solid ${color.midnight30};
    }

    @media screen and (min-width: 768px) {
      .sidebar-contracted & {
        width: var(--sidebar-width-contracted);
      }

      .sidebar-hover.sidebar-contracted &,
      .sidebar-expanded & {
        width: var(--sidebar-width-expanded);
      }
      .sidebar-force-closed & {
        animation: ${closeSidebar} var(--sidebar-transition-time) linear 1;
      }
    }
  `}
`;

export const ToggleContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: var(--sidebar-width-expanded);
    padding-left: calc(var(--sidebar-width-contracted) - ${spacing.p2} / 2);
    margin-bottom: ${spacing.p3};
  `}
`;

export const ToggleLabel = styled.div`
  max-width: 0;
  overflow: hidden;
  transition: max-width calc(var(--sidebar-transition-time) / 2);
`;

export const ToggleExpand = styled.button`
  ${({ theme: { sidebar } }) => css`
    ${itemContainerCss}
    position:relative;
    justify-content: flex-end;
    align-self: flex-end;
    right: 0;
    background: ${sidebar.base.background};
    color: ${sidebar.base.color};
    width: 100%;

    transition: opacity var(--sidebar-transition-time),
      width calc(var(--sidebar-transition-time) / 2),
      color calc(var(--sidebar-transition-time) / 2);
    opacity: 0;
    pointer-events: none;

    &&&& {
      gap: 12px;
      padding: 14px 15px 14px 5px;
    }

    :hover ${ToggleLabel} {
      max-width: var(--sidebar-width-expanded);
    }

    @media screen and (min-width: 768px) {
      opacity: 1;
      pointer-events: all;
    }
  `}
`;

export const ToggleIcon = styled(Icon)`
  ${({ theme: { sidebar } }) => css`
    border-radius: 17px;
    height: 32px;
    width: 32px;
    background: ${sidebar.active.background};
    color: ${sidebar.active.icon};
  `}
`;
