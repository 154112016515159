import { LightTheme } from '@virtidev/toolbox';
import { produce } from 'immer';
import { createGlobalStyle, css, DefaultTheme } from 'styled-components';
import { transparentize } from 'polished';

export const baseTheme = produce(
  /** @type {DefaultTheme} */ (LightTheme),
  (theme) => {
    // customisations
    theme.interactions = {
      question: {
        icon: 'help',
        color: '#356876',
      },
      infobox: {
        icon: 'information',
        color: '#46beaf',
      },
      hotspot: {
        icon: 'hotspot',
        color: '#79ddff',
      },
      bookmark: {
        icon: 'bookmark',
        color: '#fbc767',
      },
    };

    theme.sidebar = {
      base: {
        icon: theme.color.midnight10,
        color: theme.color.midnight10,
        background: theme.color.white,
      },
      highlight: {
        icon: theme.color.midnight10,
        color: theme.color.midnight10,
        background: transparentize(0.5, theme.color.blue60),
      },
      active: {
        icon: theme.color.blue70,
        color: theme.color.midnight,
        background: theme.color.blue60,
      },
    };
  }
);

export const lightTheme = baseTheme;

export const VirtiGlobalStyles = createGlobalStyle`
${
  /** @param {{ $fullHeight?: boolean }} p */
  ({ $fullHeight }) => css`
    html,
    body {
      min-height: 100%;
      height: 100%;
      ${!$fullHeight &&
      css`
        height: auto;
      `};
    }

    #root {
      height: 100%;
    }
  `
}
`;

export const LegacyCssVars = createGlobalStyle`
  ${({ theme: { color, type } }) => css`
    :root {
      /* main colors */
      --primary-color: ${color.primary};
      --primary-color-dark: #1a4683;
      --primary-color-light: #c5e6fd;
      --primary-color-lighter: #eef7ff;
      --primary-bg-color: ${type === 'light' ? color.blue50 : color.midnight};
      --secondary-color: ${color.secondary};
      --secondary-bg-color: #fff;
      --selected-color: #f8bd49;
      --warning-color: ${color.warning};
      --warning-color-2: #e69d18;

      /* --danger-color: #b32626; */
      --negative-color: ${color.error};
      --positive-color: ${color.success};
      --negative-color-weak: rgba(225, 80, 64, 0.827);
      --negative-color-weakest: rgba(225, 80, 64, 0.58);
      --neutral-color: #777;
      --neutral-color-light: #d8d8d8;
      --neutral-color-lighter: #f1f0f0;

      /* fonts */
      --header-font: 'Ubuntu', sans-serif;
      --text-font: 'Ubuntu', sans-serif;

      /* buttons */
      --primary-button-bg-color: var(--primary-color);
      --primary-button-text-color: #fff;
      --primary-button-text-color-without-bg: #777;
      --primary-button-bg-color-hover: var(--primary-color);
      --primary-button-text-color-hover: #fff;
      --primary-button-border-radius: 22px;
      --primary-button-gradient: linear-gradient(to right, #034ffc, #329fc3);
      --primary-button-gradient-hover: linear-gradient(
        to right,
        #3471ff,
        #55b4d3
      );
      --primary-button-padding: 0.8rem 1.1rem;
      --primary-button-bg-color-current: #7ddbd0;
      --primary-button-bg-color-current-hover: #7ddbd0;
      --primary-button-bg-color-active: #7bd9cedc;
      --secondary-button-bg-color: #f9f9f9;
      --secondary-button-border-radius: var(--primary-button-border-radius);
      --secondary-button-padding: var(--primary-button-padding);
      --secondary-button-text-color: #464646;
      --secondary-button-bg-color-hover: #fff;
      --secondary-button-text-color-hover: #646464;
      --secondary-button-bg-color-disabled: #c0c0c0;
      --secondary-button-text-color-disabled: #646464;
      --secondary-button-bg-color-active: #f9f9f9d5;
      --tertiary-button-bg-color: #fff;
      --tertiary-button-border-radius: var(--primary-button-border-radius);
      --tertiary-button-padding: var(--primary-button-padding);
      --tertiary-button-text-color: #464646;
      --tertiary-button-bg-color-hover: #e7e7e7;
      --tertiary-button-text-color-hover: #646464;
      --tertiary-button-bg-color-disabled: #c0c0c0;
      --tertiary-button-text-color-disabled: #646464;
      --tertiary-button-bg-color-active: #f1f1f1;
      --material-grey-btn-color: #7a8ea2;
      --material-grey-btn-color-hover: #fff;
      --material-grey-btn-bg-color: #eff4f6;
      --material-grey-btn-bg-color-active: #c7ccce;
      --button-disabled-bg-color: #b3b3b3;
      --button-disabled-text-color: #dbdbdb;
      --help-button-disabled-bg-color: #d4d4d4;
      --help-button-disabled-icon-color: #bbbbbb;
      --filter-button-bg: #e7e7e7;
      --filter-button-text-color: #585858;

      --delete-button-bg-color: #d64646;
      --delete-button-bg-color-hover: #db6b6b;
      --delete-button-text-color: #fff;
      --delete-button-bg-color-active: #eb8082;
      --cancel-button-bg-color: #afafaf;
      --cancel-button-bg-color-hover: #bbbbbb;
      --cancel-button-text-color: #fff;
      --cancel-button-bg-color-active: #ececec;

      /* pagination */
      --pagination-btn-bg-color: #fff;
      --pagination-btn-border: 1px solid #ccc;
      --pagination-btn-border-radius: 20px;
      --pagination-btn-text-color: #a4a5aa;
      --pagination-btn-text-color-active: #fff;
      --pagination-btn-text-color-hover: #fff;
      --pagination-btn-bg-color-active: var(--primary-color);
      --pagination-btn-bg-color-hover: var(--primary-color);
      --disabled-pagination-btn-text-color: #98aab8;
      --disabled-pagination-btn-bg-color: #eff4f6;

      /* text */
      --primary-font-color: ${color.base};
      --primary-font-color-light: ${color.midnight10};
      --menu-font-color: #464646;
      --secondary-font-color: #fff;
      --tertiary-font-color: #afafaf;

      /* sidebar */
      --sidebar-width-expanded: 260px;
      --sidebar-width-contracted: 75px;
      --onboarding-sidebar-width-expanded: 0rem;
      --onboarding-sidebar-width-contracted: 0rem;
      --sidebar-anim-duration: 0.3s;
      --sidebar-link-text-color: #bbc0ca;
      --sidebar-link-padding-expanded: 24px 20px 20px 38px;
      --sidebar-link-padding-contracted: 23px 23px 23px 26px;
      --sidebar-link-hover-background-color: #f3f3f3;
      --sidebar-link-active-background-color: #e7e7e7;

      /* secondary sidebar */
      --secondary-sidebar-width-expanded: 11.3rem;
      --secondar-sidebar-width-contracted: 4.7rem;

      /* savebar */
      --savebar-height: 4.5rem;
      --savebar-bg-color: #fff;
      --savebar-padding: 0.7rem var(--content-side-padding);
      --savebar-height-space-allowance: calc(var(--savebar-height) + 2rem);
      --disabled-savebar-btn-bg-color: rgb(117, 117, 117);
      --disabled-savebar-btn-font-color: rgb(143, 143, 143);
      --disabled-savebar-btn-nobg-font-color: #ccc;

      /* breadcrumbs */
      --breadcrumbs-height: 4rem;
      --breadcrumbs-vertical-padding: 0rem;
      --breadcrumbs-vertical-padding-total: calc(
        var(--breadcrumbs-vertical-padding) * 2
      );
      --breadcrumbs-padding: var(--breadcrumbs-vertical-padding)
        var(--content-side-padding);
      --breadcrumbs-calculated-height: calc(
        var(--breadcrumbs-height) + var(--breadcrumbs-vertical-padding-total)
      );
      --breadcrumbs-bg-color: var(--primary-bg-color);

      /* main content */
      --content-side-padding: 2rem;
      --content-padding: 2rem;

      /* borders */
      --primary-border-width: 1px;
      --primary-border-color: #ebebeb;
      --primary-border: var(--primary-border-width) solid
        var(--primary-border-color);
      --primary-item-border-color: #9e9e9e;
      --primary-item-border: var(--primary-border-width) solid
        var(--primary-item-border-color);
      --secondary-border-width: 0.15rem;
      --secondary-border-color: #d7d7d7;
      --secondary-border: var(--secondary-border-width) solid
        var(--secondary-border-color);
      --input-border-color: #ededed;

      /* header nav */
      --nav-bar-height: 4rem;
      --nav-logo-height: 1.5rem;
      --nav-button-hover-bg-color: #e9e9e9;
      --nav-button-active-bg-color: #e4e4e4;
      --nav-button-icon-content-active-bg-color: var(--primary-color);
      --nav-button-icon-hover-bg-color: #fff;

      /* tab nav */
      --tab-bg-color: var(--primary-bg-color);
      --tab-text-color: #737373;
      --tab-active-click-bg-color: #fbfbfb;
      --tab-active-click-text-color: var(--primary-font-color);
      --tab-active-bg-color: #fff;
      --tab-active-text-color: #0c0c0c;
      --tab-hover-bg-color: #fff;
      --tab-disabled-bg-color: var(--primary-bg-color);
      --tab-disabled-text-color: #ccc;

      /* help content */
      --help-width: 25rem;
      --help-bg-color: #fff;

      /** generic */
      --success: #41cc2f;

      /* flash messages */
      --flash-message-width: 40rem;
      --flash-message-top: calc(var(--nav-bar-height) + 2rem);
      --flash-message-top-with-onboarding: calc(var(--nav-bar-height) + 7rem);
      --flash-message-right: 2rem;
      --flash-message-color-default: var(--primary-color);
      --flash-message-color-success: #b3e7ac;
      --flash-message-color-warning: #f4e782;
      --flash-message-color-error: #e07c65;
      --flash-message-text-color: #68738b;
      --flash-message-font-size: 0.9rem;
      --flash-message-text-color-default: var(--primary-font-color);

      /* Alert messages */
      --alert-message-bg-color-warning: ${color.contrast.error};
      --alert-message-bg-color-success: var(--primary-color);
      --alert-message-bg-color-error: ${color.error};
      --alert-message-text-color-warning: ${color.error};
      --alert-message-text-color-error: ${color.contrast.error};
      --alert-message-font-size: 0.9rem;

      /* shadows */
      --material-shadow-color: #ccc;
      --material-shadow-blur: 0.75rem;
      --material-shadow-amount: 0.05rem;
      --material-shadow-bottom: 0 var(--material-shadow-amount)
        var(--material-shadow-blur) var(--material-shadow-color);
      --material-shadow-right: var(--material-shadow-amount) 0
        var(--material-shadow-blur) var(--material-shadow-color);
      --material-shadow-left: 0 var(--material-shadow-amount)
        var(--material-shadow-blur) var(--material-shadow-color);

      /* icons */
      --icon-width-page-illustration: 50rem;
      --icon-width-extra-large: 13rem;
      --icon-width-large: 10rem;
      --icon-width-medium-large: 7rem;
      --icon-width-medium: 5rem;
      --icon-width-medium-small: 3rem;
      --icon-width-small: 1.6rem;
      --icon-width-tiny: 1rem;
      --icon-width-navbar: 1.2rem;
      --icon-circular-bg-color: #fff;

      /* illustrations */
      --illustration-width: 20rem;

      /* content dimensions */
      --content-height: calc(100vh - var(--nav-bar-height));

      /* simulation list items */
      --high-avg-score-color: var(--primary-color);
      --mid-avg-score-color: var(--warning-color);
      --low-avg-score-color: #f17d82;

      /* cards */
      --card-border-radius: 24px;
      --card-bg-color: #fff;
      --card-box-shadow-color: #eaeaea;
      --card-box-shadow: 0 3px 6px 1px var(--card-box-shadow-color);

      /* modal */
      --modal-bg-color: #ebebeb;

      /* links */
      --link-text-color: ${color.turquoise};
      --link-text-color-hover: ${color.turquoise10};
      --link-text-color-active: ${color.blue20};
      --link-text-color-visited: ${color.turquoise};
      --link-text-color-light: #b2b2b2;
      --link-text-color-light-hover: #bbbbbb;
      --link-text-color-light-active: #c5c5c5;
      --link-text-color-light-visited: var(--link-text-color-light);
      --caps-link-text-color: #d0d0d0;
      --caps-link-text-color-hover: var(--primary-color);
      --caps-link-text-color-active: var(--primary-color);
      --caps-link-text-color-visited: #d0d0d0;
      --image-link-opacity-hover: 0.8;
      --image-link-opacity-active: 0.7;

      /* forms */
      --validation-error-text-color: #cf3535;
      --validation-error-input-border-color: #cf3535;
      --text-input-bg-color: #f7f9fb;
      --text-input-text-color: var(--primary-font-color);
      --text-input-placeholder-text-color: #ccc;
      --input-alt-bg-color: #fff;
      --input-alt-border: 1px solid #e3e6eb;
      --input-alt-placeholder-text-color: #ccc;
      --input-alt-border-radius: 8px;
      --white-input-placeholder-color: #ccc;

      --checkbox-bg-color: #efefef;
      --checkbox-bg-color-grey-form: #fff;
      --checkbox-bg-color-hover: var(--primary-color-light);
      --checked-checkbox-bg-color: var(--primary-color);
      --checkbox-tick-color: #fff;
      --form-section-bg-color: #ebebeb;
      --input-focus-color: var(--primary-button-bg-color-hover);
      --disabled-checkbox-color: #ccc;
      --disabled-checkbox-text-color: #969696;
      --disabled-form-input-bg-color: #d2d2d2;
      --form-max-width: 50rem;
      --form-colored-btn-hover-bg-color: #ccc;
      --input-height: 3.1rem;
      --input-border: 1px solid #e9edf1;
      --input-bg-color: #f7f9fb;
      --input-bg-color-hover: #fff;
      --alt-form-bg-color: #f7f9fb;
      --input-border-color: #e9edf1;
      --input-border-radius: 22px;
      --char-counter-width: 2rem;
      --char-counter-input-margin-right: calc(
        var(--char-counter-width) + 0.3rem
      );
      --range-input-text-width: 3rem;
      --range-input-margin-right: calc(var(--range-input-text-width) + 0.3rem);

      --char-counter-okay-text-color: green;
      --char-counter-caution-text-color: orange;
      --char-counter-max-text-color: red;

      /* login page */
      --login-page-bg-color: var(--primary-bg-color);
      --login-form-bg-color: var(--form-section-bg-color);

      /* interactions */
      --move-playhead-bg-color: #98d4cd;
      --move-playhead-text-color: #fff;
      --interaction-selected-bg-color: #ccc;
      --interaction-selected-bg-color-hover: var(--primary-color-light);
      /* --interaction-question-color: #F8BD49;
      --interaction-question-color-light: #ffcc67; */
      --interaction-question-color: #356876;
      --interaction-question-color-light: #356876;
      --interaction-question-color-dark: #356876;
      /* --interaction-infobox-color:#339ADC;
      --interaction-infobox-color-light: #90c5e6; */
      --interaction-infobox-color: #46beaf;
      --interaction-infobox-color-light: #46beaf;
      --interaction-infobox-color-dark: #46beaf;
      /* --interaction-hotspot-color: #B06DCC;
      --interaction-hotspot-color-light: #dba8f1; */
      --interaction-hotspot-color: #79ddff;
      --interaction-hotspot-color-light: #79ddff;
      --interaction-hotspot-color-dark: #79ddff;
      --interaction-bookmark-color: #fbc767;
      --interaction-bookmark-color-light: #fbc767;
      --interaction-bookmark-color-dark: #fbc767;
      --interaction-button-font-color: #fff;
      --interaction-count-text-color: #fff;
      --interaction-count-bg-color: #cecece;
      --seekbar-interaction-active-bg-color: var(--primary-color);
      --interaction-list-add-btn-height: 3.5rem;

      /* seekbar */
      --seekbar-cursor-color: #999;
      --seekbar-bg-color: #dcdcdc;
      --seekbar-label-section-height: 1.6rem;
      --seekbar-height-min: 7rem;
      --seekbar-height-extra-per-layer: 1rem;
      --seekbar-offset-height: var(--seekbar-height);

      /* custom scrollbars */
      --scrollbar-thumb-color: #b4b4b4;
      --scrollbar-bg-color: #ddd;

      /** stop-gap for redesigns */
      --material-border-radius: 8px;
      --material-bg-color: #fff;
      --material-box-shadow-color: #eaeaea;
      --material-box-shadow: 0 3px 7px 1px var(--card-box-shadow-color);
      --material-button-text-color: var(--primary-color);

      /** notifications */
      --notification-bg-color: #fff;
      --notification-bg-color-unread: #f7f9fb;
      --notification-bg-color-hover: #f1f7fc;
      --notification-bg-color-active: #eef6fd;

      /** unity */
      --unity-loading-bg-color: #0e1b32;
      --unity-loader-path-color: ${color.primary};
      --unity-loader-text-color: ${color.white};
      --unity-loader-trail-color: #d6d6d6;

      /** pd web app */
      --chat-item-user-bg-color: #111b32;
      --chat-item-user-text-color: #fff;
      --chat-item-bot-bg-color: #f8f8fa;
      --chat-item-bot-text-color: var(--primary-font-color);
      --pd-primary-color: ${color.secondary};
      --pd-primary-color-light: #6dcdbe;
      --pd-primary-color-lighter: #eef9f9;
      --pd-red: #ef4331;
      --pd-red-light: #fff6f6;
      --pd-yellow: #edbe62;
      --pd-yellow-light: #fff9ed;
      --pdwebgl-bg-color: #f7f8f9;
      --pd-player-padding: 2rem;
      --pd-secondary-button-bg-color: #d0d0d0;
      --pd-border: 1px solid #e0e0e0;
      --pd-border-focused: 1px solid #111b32;
      --pd-secondary-color: #b06dcb;
      --pd-secondary-color-light: #ece3f2;
      --pd-light-text-color: #a0a0a0;
      --pd-dark-text-color: #111b32;
      --pd-disabled-text-color: #cecece;
      --pd-border-color: #c5cfd7;
      --pd-grey-bg-color-dark: #a0a0a0;
      --pd-grey-bg-color: #fafbfb;
      --pd-modal-border-radius: 32px;
      --pd-modal-bg-color: #fff;
      --pd-box-shadow: 0 4px 20px -2px #d4d4d4;
      --pd-positive-color: var(--pd-primary-color);
      --pd-positive-color-lighter: var(--pd-primary-color-lighter);
      --pd-negative-color: var(--pd-red);
      --pd-negative-color-lighter: var(--pd-red-light);
      --pd-neutral-color: var(--pd-yellow);
      --pd-neutral-color-lighter: var(--pd-yellow-light);
      /* --pd-disabled-icon-color: #B1B1B1; */
      --pd-disabled-button-bg-color: #bdbdbd;
      --pd-builder-height-subtraction: 12.5rem;
    }
  `}
`;
