import zod from 'zod';

export const editOrganisationSchema = zod.object({
  Name: zod.string().min(1, { message: 'Organisation name is required.' }),
  Description: zod.string().nullable(),
  LogoMediaID: zod.string().optional(),
  LogoMedia: zod.any().optional(),
  BrandImageMediaID: zod.string().optional(),
  BrandImageMedia: zod.any().optional(),
});
