import styled, { css } from 'styled-components';

export const OrganisationForm = styled.form`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: row;
    gap: ${spacing.md};
  `}
`;

export const OrganisationImagesBlock = styled.div`
  ${({ theme: { spacing } }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${spacing.lg};
    align-items: center;
    justify-content: flex-start;
  `}
`;

export const OrganisationFieldsBlock = styled.div`
  ${({ theme: { spacing } }) => css`
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: ${spacing.md};
    align-items: stretch;
    justify-content: flex-start;
  `}
`;

export const OrganisationUploadContainer = styled.div`
  ${
    /** @param {{ $round?: boolean }} p */
    ({ $round }) => css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      ${$round
        ? css`
            width: 200px;
            height: 200px;
          `
        : css`
            width: 312px;
            height: 176px;
          `}
    `
  }
`;

export const OrganisationUploadLabel = styled.label`
  ${({ theme: { color, font, spacing, borderRadius } }) => css`
    ${font.smallnote}
    position: absolute;
    top: 0;
    left: ${spacing.sm};
    transform: translate(0, -50%);
    background: ${color.contrast.base};
    padding: 0 ${spacing.p1};
    border-radius: ${borderRadius.p1};
    color: ${color.midnight5};
    z-index: 2;
  `}
`;
