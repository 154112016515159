import { useQuery } from '@apollo/client';
import { READ_LICENSE_COUNTS } from './useGetOrganisationLicenseCounts.query';

export const useGetOrganisationLicenseCounts = (OrganisationID) => {
  const { data, loading, error } = useQuery(READ_LICENSE_COUNTS, {
    variables: {
      OrganisationID,
    },
  });
  const vhCount =
    data?.readOneOrganisation?.VirtualHumans?.pageInfo?.totalCount ?? 0;
  const vhMax = data?.readOneOrganisation?.PlatformLicense?.VirtualHumans ?? 0;
  const ivCount =
    data?.readOneOrganisation?.Simulations?.pageInfo?.totalCount ?? 0;
  const ivMax =
    data?.readOneOrganisation?.PlatformLicense?.InteractiveVideos ?? 0;
  const editorCount = data?.readOneOrganisation?.TotalAdmins ?? 0;
  const editorMax =
    data?.readOneOrganisation?.PlatformLicense?.PaidEditorSeats ?? 0;
  const learnerCount = data?.readOneOrganisation?.TotalUsers ?? 0;
  const learnerMax = data?.readOneOrganisation?.PlatformLicense?.Learners ?? 0;

  const isSaasOrg =
    data?.readOneOrganisation?.PlatformLicense?.Type &&
    ['STARTER', 'PRO', 'FREE_TRIAL'].includes(
      data?.readOneOrganisation?.PlatformLicense?.Type
    );
  const vhCapHit = isSaasOrg && vhCount >= vhMax;
  const ivCapHit = isSaasOrg && ivCount >= ivMax;
  const editorCapHit = isSaasOrg && editorCount >= editorMax;
  const learnerCapHit = isSaasOrg && learnerCount >= learnerMax;

  return {
    editorCount,
    editorMax,
    learnerCount,
    learnerMax,
    vhCount,
    vhMax,
    ivCount,
    ivMax,
    editorCapHit,
    learnerCapHit,
    ivCapHit,
    vhCapHit,
    loading,
    error,
    isSaasOrg,
  };
};
