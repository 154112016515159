import MediaUploader from '@core/components/Media/components/MediaUploaders/MediaUploader';
import {
  OrganisationUploadContainer,
  OrganisationUploadLabel,
} from '@core/components/Organisation/EditOrganisationForm/EditOrganisationForm.styled';
import { getResizeImage } from '@core/helpers/imageResizing/getResizeImage';
import { FC, ReactNode, useCallback, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';

/**
 * @type {FC<{
 *    name: string;
 *    label?: ReactNode;
 *    round?: boolean;
 * }>}
 */
export const OrganisationImageUpload = ({ name, label, round }) => {
  const {
    field: { value, onChange },
  } = useController({ name: `${name}ID` });
  const TusID = useWatch({ name: `${name}.TusID` });
  const imageUrl = useWatch({ name: `${name}.URL` });

  const [remount, setRemount] = useState(2);
  const { setValue } = useFormContext();

  const handleSuccess = useCallback(
    ({ ID, URL, TusID }) => {
      const resizedURL = getResizeImage(TusID, {
        resize: {
          width: 200,
          height: 200,
        },
      });

      onChange(ID);
      setValue(`${name}.TusID`, TusID);
      setValue(`${name}.URL`, resizedURL);

      setRemount((prev) => prev + 1);
    },
    [onChange, setValue, name]
  );

  const handleRemove = useCallback(() => {
    onChange('0');
    setValue(`${name}.TusID`, null);
    setValue(`${name}.URL`, null);

    setRemount((prev) => prev + 1);
  }, [onChange, setValue, name]);

  return (
    <OrganisationUploadContainer $round={round}>
      {label && <OrganisationUploadLabel>{label}</OrganisationUploadLabel>}
      <MediaUploader
        key={`${value}-${remount}`}
        mediaType="image"
        mediaTusID={TusID}
        src={imageUrl}
        onSuccess={handleSuccess}
        onRemove={handleRemove}
        round={round}
        recommendationsText={
          round
            ? 'Images should be no larger than 300px x 300px. Aspect ratio should be 1:1.'
            : 'Images should be no larger than 534px x 300px. Aspect ratio should be 16:9.'
        }
      />
    </OrganisationUploadContainer>
  );
};
