import { useMemo, FC } from 'react';
import DataListCard from '../../../DataListCard/DataListCard';
import ScorePercentage from '../../../../../../../styled-components/StyledScorePercentage';
import { useMutation } from '@apollo/client';
import { DELETE_SIMULATIONS } from '../../../DataListCard/components/CardDeleteButton/CardDeleteButton.query';
import PercentTag from '../PercentTag';
import useUser from '../../../../helpers/useUser';
import { COPY_SIMULATION } from '../../../../../../../components/buttons/CopySimulationButton/CopySimulation.query';
import useFlashMessage from '../../../FlashMessage';
import { FALLBACK } from '@virtidev/toolbox';
import { Simulation } from '../../../../models/simulation.types';

/**
 * @type {FC<{
 *    simulation: Simulation
 *    editTags?: boolean
 * }>}
 */
export const SimulationDataCard = ({ simulation, editTags, ...props }) => {
  const {
    ID,
    Title,
    OrganisationGroups,
    TotalUsers,
    AverageScore,
    TotalCompletions,
    TotalViews,
    FirstVideo,
    ImageMedia,
    Quiz,
    LastSaved,
  } = simulation;

  const { addFlashMessage } = useFlashMessage();
  const { OrganisationID } = useUser();
  const dataPoints = useMemo(() => {
    const percent =
      TotalViews > 0 ? Math.round((TotalCompletions / TotalViews) * 100) : 0;

    return [
      { title: 'Users', data: TotalUsers },
      {
        title: 'Attempts',
        data: TotalViews,
      },
      {
        title: 'Completed',
        data: (
          <>
            {TotalCompletions}{' '}
            {!!percent && <PercentTag percent={percent}>{percent}%</PercentTag>}
          </>
        ),
      },
      Quiz
        ? {
            title: 'Avg. Score',
            data: (
              <ScorePercentage
                align="center"
                fallback="-"
                score={AverageScore}
              />
            ),
          }
        : null,
    ];
  }, [TotalUsers, AverageScore, TotalCompletions, TotalViews, Quiz]);

  const [deleteSimulation] = useMutation(DELETE_SIMULATIONS, {
    variables: {
      IDs: [ID],
    },
    onCompleted: () => {
      addFlashMessage(
        `Interactive video '${Title || '(Untitled)'}' successfully deleted!`
      );
    },
    update: (cache) => {
      // evict instead of refetchQueries since we're redirecting away from page
      cache.evict({ fieldName: 'readVideoMedias' });
      cache.evict({ fieldName: 'readSimulations' });
    },
    refetchQueries: ['readSimulations'],
    onError: () => {
      addFlashMessage(
        'Error: The interactive video could not be deleted.',
        'error'
      );
    },
  });

  const [copySimulation] = useMutation(COPY_SIMULATION, {
    variables: {
      simulationID: ID,
      organisationID: OrganisationID,
    },
    onCompleted: () => {
      addFlashMessage(
        `Interactive video '${Title || '(Untitled)'}' successfully duplicated!`
      );
    },
    update: (cache) => {
      // evict instead of refetchQueries since we're redirecting away from page
      cache.evict({ fieldName: 'readVideoMedias' });
      cache.evict({ fieldName: 'readSimulations' });
    },
    refetchQueries: ['readSimulations'],
    onError: () => {
      addFlashMessage(
        'Error: The interactive video could not be duplicated.',
        'error'
      );
    },
  });

  // this was just for courses because they are added via modal right?
  const isNew = !ID || ID.includes('new');

  const thumbnailUrl = useMemo(
    () => ImageMedia?.URL || FirstVideo?.nodes?.[0]?.PosterURL,
    [ImageMedia, FirstVideo]
  );

  return (
    <DataListCard
      {...props}
      {...simulation}
      LastEdited={LastSaved}
      onDelete={deleteSimulation}
      onCopy={copySimulation}
      editLink={!isNew ? `/simulations/${ID}` : ''}
      analyticsLink={!isNew ? `/analytics-data/simulations/${ID}` : ''}
      itemLink={!isNew ? `/simulations/${ID}` : ''}
      visibleTo={OrganisationGroups.nodes}
      ThumbnailURL={thumbnailUrl}
      dataPoints={dataPoints}
      cardType="simulation"
      published={simulation.Status === 'Published'}
      editTags={editTags}
    />
  );
};

export default SimulationDataCard;
