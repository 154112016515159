import React from 'react';
import { StyledBannerCTA } from './BannerCTA.styled';

/** @type {React.FC<{content: React.ReactNode, buttonContent: React.ReactNode}>} */
const BannerCTA = ({ content, buttonContent }) => {
  return (
    <StyledBannerCTA>
      <div>{content}</div>
      <div>{buttonContent}</div>
    </StyledBannerCTA>
  );
};

export default BannerCTA;
