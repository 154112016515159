import { useQuery } from '@apollo/client';
import { withPageTemplate } from '@core/templates';
import { flowRight } from 'lodash';
import { READ_LICENSE_DETAILS, SUBSCRIPTION_STATUS } from './BillingPage.query';
import React, { useEffect, useMemo } from 'react';
import { Button, Loading } from '@virtidev/toolbox';
import * as Styled from './BillingPage.styled';
import BannerCTA from '@core/components/Banners/BannerCTA/BannerCTA';
import BillingOrganisationOverview from '@core/pages/admin/billing/components/BillingOrganisationOverview/BillingOrganisationOverview';
import useUser from '@core/helpers/useUser';
import useFlashMessage from '@core/components/FlashMessage';
import { READ_MEMBER_BASIC } from '@base/queries/UserQueries';
import { StoreLogin } from '@base/utility/LoginUtility';
import { withAnalyticsAccess } from '@core/helpers/permissions';
import { useLocation } from 'react-router-dom';

const BillingPage = () => {
  const { ID, Organisation } = useUser();
  const { search } = useLocation();
  const { addFlashMessage } = useFlashMessage();
  const { data, loading, error } = useQuery(READ_LICENSE_DETAILS, {
    variables: {
      OrganisationID: Organisation?.ID,
    },
    skip: !Organisation?.ID,
  });

  const check = useMemo(() => {
    const params = new URLSearchParams(search);

    if (params.has('session_id')) {
      return 'active';
    }

    if (params.has('subscription-cancel')) {
      return 'canceled';
    }

    return null;
  }, [search]);

  const {
    data: subData,
    loading: subLoading,
    error: subError,
    refetch,
  } = useQuery(SUBSCRIPTION_STATUS, {
    variables: {
      OrganisationID: Organisation?.ID,
    },
    notifyOnNetworkStatusChange: true,
    skip: !check || !Organisation?.ID,
  });
  //pending, active, canceled

  useEffect(() => {
    if (subLoading || !check) {
      return;
    }
    switch (subData?.getSubscriptionStatus?.Status) {
      case 'pending':
        setTimeout(() => refetch(), 2000);
        return;
      case 'active':
        if (check === 'active') {
          addFlashMessage('Subscription activated successfully', 'success');
        }
        return;
      case 'canceled':
        if (check === 'canceled') {
          addFlashMessage('Subscription has been cancelled', 'error');
        }
        return;
      default:
      // nothing
    }
  }, [
    addFlashMessage,
    refetch,
    subData?.getSubscriptionStatus?.Status,
    subLoading,
    check,
  ]);

  const { data: userData } = useQuery(READ_MEMBER_BASIC, {
    variables: {
      ID,
    },
    skip: !ID || !Organisation?.ID,
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!userData || !Organisation) {
      return;
    }
    const org = userData.readOneMember.Organisations.nodes.find(
      (item) => item.ID === Organisation.ID
    );
    if (
      org &&
      (org.TrialExpiryDate !== Organisation.TrialExpiryDate ||
        org.PlatformLicense?.Type !== Organisation.PlatformLicense?.Type ||
        org.CustomerStage !== Organisation.CustomerStage)
    ) {
      StoreLogin(null, null, userData.readOneMember, null, org.ID);
    }
  }, [data, Organisation, userData]);

  if (loading) {
    return <Loading size="large" />;
  }
  const platformLicense = data?.readOneOrganisation?.PlatformLicense;

  if (!platformLicense) {
    return <div>No license data found</div>;
  }

  if (error || subError) {
    return <div>No license data found: {error || subError}</div>;
  }

  return (
    <div>
      <Styled.BillingHeader>Usage & Billing</Styled.BillingHeader>
      <Styled.BillingContent>
        <BannerCTA
          content="Upgrade to unlock more sessions and create even more content"
          buttonContent={
            <Button color="turquoise" as="a" type="link" href="/pricing-plans">
              Upgrade
            </Button>
          }
        />
        <BillingOrganisationOverview
          checkingStatus={check && subLoading}
          organisationData={data.readOneOrganisation}
          platformLicense={platformLicense}
          usedVHCount={
            data.readOneOrganisation.VirtualHumans.pageInfo.totalCount ?? 0
          }
          usedSimCount={
            data.readOneOrganisation.Simulations.pageInfo.totalCount ?? 0
          }
          lastCardNumbers={subData?.getSubscriptionStatus?.PaymentCardLast4}
          billingPortalURL={data?.getBillingPortalURL?.URL ?? ''}
        />
      </Styled.BillingContent>
    </div>
  );
};

export default flowRight(
  withAnalyticsAccess,
  withPageTemplate({ pageKey: 'billing' })
)(BillingPage);
