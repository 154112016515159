import { Button, Icon, Modal } from '@virtidev/toolbox';
import React, { useCallback, useMemo, useState } from 'react';
import FeedbackFormEmbed from '@core/components/FeedbackForms/FeedbackFormEmbed'; 
import { SectionWrapper } from './CourseFeedbackModal.styled';

export default function CourseFeedbackModal({
  formData,
  refetch,
  formComplete,
  disabled,
  trigger,
  sourceID,
}) {
  const [show, setShow] = useState(false);

  const handleClick = useCallback(() => {
    setShow(true);
  }, []);

  const handleComplete = useCallback(() => {
    setShow(false);
    refetch();
  }, [refetch]);

  const isDisabled = useMemo(() => {
    if (disabled) {
      return true;
    }
    if (formData.AllowMultipleSubmissions) {
      return false;
    }
    if (formComplete) {
      return true;
    }
  }, [disabled, formData.AllowMultipleSubmissions, formComplete]);
  return (
    <SectionWrapper>
      <Button
        style={{ borderRadius: '22px' }}
        onClick={handleClick}
        disabled={isDisabled}
      >
        {formComplete
          ? formData.AllowMultipleSubmissions
            ? 'Open Form'
            : 'Form complete!'
          : 'Open Form'}
      </Button>{' '}
      {formComplete && (
        <Icon icon="success" size="30px" color="var(--secondary-color)" />
      )}
      <Modal
        show={show}
        render={() => (
          <FeedbackFormEmbed
            hash={formData.Hash}
            formID={formData.ID}
            trigger={trigger}
            sourceID={sourceID}
            onComplete={handleComplete}
          />
        )}
        onHide={() => {
          setShow(false);
        }}
      />
    </SectionWrapper>
  );
}
