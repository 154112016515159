import { BrowserRouter, Route, Switch, useLocation, matchPath } from "react-router-dom";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ComponentType, lazy, Suspense, useEffect, useLayoutEffect, useMemo } from 'react';

import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ForgotCodePage from './pages/ForgotCodePage';
import LogoutPage from './pages/LogoutPage';
import SetOrganisationPage from './pages/SetOrganisationPage';
import OrganisationPage from './pages/OrganisationPage';
import BillingPage from './apps/core/src/pages/admin/billing/BillingPage';
import UserProfilePage from './pages/UserProfilePage';
import AnalyticsService from './apps/core/src/components/AnalyticsService/AnalyticsService';
import AccountActivationPage from './pages/AccountActivationPage';
import MySimulationsPage from './pages/MySimulationsPage';
import MyCoursesPage from './pages/MyCoursesPage';
import MyCoursePage from './pages/MyCoursePage';
import MyCourseUnitSimulationPage from './pages/MyCourseUnitSimulationPage';
import MyHomePage from './pages/MyHomePage';
import MySimulationPage from './pages/MySimulationPage';
import MyVirtualHumansPage from './pages/MyVirtualHumansPage';
import PDWebPlayerPage from './pages/PDWebPlayerPage';
import NotFoundPage from './pages/NotFoundPage';
import OnboardingDashboardPage from './pages/OnboardingDashboardPage';
import { UserProvider } from './apps/core/src/helpers/useUser';
import { flowRight} from 'lodash';
import NewMemberSignupPage from './apps/core/src/pages/signup/NewMemberSignUpPage/NewMemberSignupPage';
import VirtiApolloProvider from './apps/core/src/components/VirtiApolloProvider/VirtiApolloProvider';
import MyCourseUnitVirtualHumanPage from './pages/MyCourseUnitVirtualHumanPage';
import VRLoginPage from './apps/core/src/pages/user/account/VRLoginPage';
import TotaraRegisterPage from './apps/core/src/pages/signup/totara/TotaraRegister';
import { DeepLinkProvider } from './apps/core/src/components/DeepLink';
import { LaunchDarklyProvider } from './apps/core/src/components/LaunchDarkly/LaunchDarkly';
import useSentryUserData from './apps/core/src/components/Sentry/useSentryUserData';
import useDateLocale from './apps/core/src/components/Locale/useDateLocale';
import MySimulationSessionDetailPage from './pages/MySimulationSessionDetailPage';
import MySimulationSessionsPage from './pages/MySimulationSessionsPage';
import MyVirtualHumanSessionsPage from './pages/MyVirtualHumanSessionsPage';
import MyBadgesPage from './pages/MyBadgesPage';
import withEs6Support from './apps/core/src/helpers/withEs6Support';
import { OfflineUpdate } from './apps/core/src/components/OfflineUpdate/OfflineUpdate';
import RegisterPage from './apps/core/src/pages/signup/register/RegisterPage';
import { publicRoutes } from '@core/helpers/publicRoutes';
import VirtiTheme from './apps/core/src/templates/VirtiTheme';
import withUserPilot from '@core/components/UserPilot/UserPilotProvider';
import withGleap from './apps/core/src/components/Gleap/GleapProvider';
import VHWebPlayerPagePublicRedirect from '@core/pages/admin/virtual-humans/VHWebPlayerPagePublic/VHWebPlayerPagePublicRedirect';
import PricingPage from '@core/pages/admin/pricing/PricingPage';

import './App.css';
import "@virtidev/toolbox/build/styles.css";
import TrialExpiredModal from "@core/components/Signup/TrialExpiredModal/TrialExpiredModal";

const EmbedSection = lazy(() => import( '@core/pages/admin/embed/Embed.section'));

const HelpPage = lazy(() => import( '@core/pages/admin/help/HelpPage'));

const AnalyticsDataSection = lazy(() => import('@core/pages/admin/Analytics/AnalyticsData.section'));

const FeedbackFormsSection = lazy(() => import( '@core/pages/admin/Feedback/FeedbackForms.section'));

const CxDashboardSection = lazy(() => import('@core/pages/admin/cx/CxDashboard.section'));

const VirtualCoachApp = lazy(() => import('./pages/VirtualCoachApp'));

const SimulationsSection = lazy(() => import('@core/pages/admin/simulations/Simulations.section'));

const MediaSection = lazy(() => import('@core/pages/admin/media/Media.section'));

const UsersSection = lazy(() => import( '@core/pages/admin/users/Users.section'));

const GroupsSection = lazy(() => import( "@core/pages/admin/groups/Groups.section"));

const CoursesSection = lazy(() => import( "@core/pages/admin/course/Courses.section"));

const VirtualHumansSection = lazy(() => import( "@core/pages/admin/virtual-humans/VirtualHumans.section"));

const App = () => {
  const { pathname } = useLocation();

  useSentryUserData();

  useDateLocale();

  const publicPage = useMemo(
    () => publicRoutes.find((route) => matchPath(pathname, route)),
    [pathname]
  );

  useEffect(() => {
    window.graphQLVersion = 4; // so vh client knows correct formats
  }, []);

  useLayoutEffect(() => {
    const env = process.env.REACT_APP_ENV;
    if (env !== 'production') {
      document.title = `Virti Admin - ${window.location.host}`;
    }
  }, []);

  return (
    <Switch>
      <Route exact path="/" component={DashboardPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/signup-link/:userType/:orgHash" component={NewMemberSignupPage}/>
      <Route path="/register/maudsley" component={TotaraRegisterPage} />
      <Route path="/register" component={RegisterPage} />
      <Route path="/change-password" component={ChangePasswordPage} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />
      <Route path="/forgot-code" component={ForgotCodePage} />
      <Route path="/activate-account" component={AccountActivationPage} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/set-organisation/:id" component={SetOrganisationPage} />
      <Route path="/organisation" component={OrganisationPage} />
      <Route path="/billing" component={BillingPage} />
      <Route path="/profile" component={UserProfilePage} />
      <Route path="/help" component={HelpPage} />
      <Route path="/home" component={MyHomePage} />

      <Route path="/embed" component={EmbedSection} />
      <Route path="/analytics-data" component={AnalyticsDataSection} />
      <Route path="/feedback-forms" component={FeedbackFormsSection} />
      <Route path="/simulations" component={SimulationsSection} />
      <Route path="/media" component={MediaSection} />
      <Route path="/users" component={UsersSection} />
      <Route path="/groups" component={GroupsSection} />
      <Route path="/courses" component={CoursesSection} />
      <Route path="/cx-dashboard" component={CxDashboardSection} />

      <Route path="/virtual-humans" component={VirtualHumansSection} />

      <Route path="/virtual-coaches/:ssVirtualHumanID/builder" component={VirtualCoachApp} />

      <Route path="/my-simulations/:simulationID/:sharetoken" component={MySimulationPage} />
      <Route path="/my-simulations/:simulationID" component={MySimulationPage} />
      <Route path="/my-simulations" component={MySimulationsPage} />

      <Route path="/my-courses/:courseID/simulations/:simID" component={MyCourseUnitSimulationPage} />
      <Route path="/my-courses/:courseID/virtual-humans/:virtualHumanID" component={MyCourseUnitVirtualHumanPage} />
      <Route path="/my-courses/:courseID" component={MyCoursePage} />
      <Route path="/my-courses" component={MyCoursesPage} />

      {/* remove this embed redirect later */}
      <Route path="/my-virtual-humans/embed/:ssVirtualHumanID" component={VHWebPlayerPagePublicRedirect} />
      <Route path="/my-virtual-humans/:ssVirtualHumanID/play/:sharetoken" component={PDWebPlayerPage} />
      <Route path="/my-virtual-humans/:ssVirtualHumanID/play" component={PDWebPlayerPage} />
      <Route path="/my-virtual-humans/:ssVirtualHumanID" component={NotFoundPage} />
      <Route path="/my-virtual-humans" component={MyVirtualHumansPage} />

      <Route path="/my-profile/simulations/:sessionID" component={MySimulationSessionDetailPage} />
      <Route path="/my-profile/simulations" component={MySimulationSessionsPage} />
      <Route path="/my-profile/virtual-humans" component={MyVirtualHumanSessionsPage} />
      <Route path="/my-profile/badges" component={MyBadgesPage} />

      <Route path="/onboarding-dashboard" component={OnboardingDashboardPage}/>
      <Route path="/privacy-policy" render={() => {
        window.location.href = 'https://www.virti.com/virti-privacy-policy-app-and-product-browser/';
        return null;
      }} />
      <Route path="/terms-conditions" render={() => {
        window.location.href = "https://www.virti.com/terms-conditions";
        return null;
      }} />
      <Route path="/account/vr" component={VRLoginPage} />
      <Route path="/pricing-plans" component={PricingPage} />
      <Route component={NotFoundPage}/>
    </Switch>
  );
};

/**
 *
 * @param {ComponentType} Component
 * @returns {ComponentType}
 */
const provideAppContext = (Component) => {
  const AppProviders = () => {
    return (
      <BrowserRouter>
        <UserProvider>
          <VirtiTheme>
            <VirtiApolloProvider>
              <DndProvider backend={HTML5Backend}>
                <DeepLinkProvider>
                  <LaunchDarklyProvider>
                    <Suspense fallback="">
                      <AnalyticsService />
                      <Component />
                      <OfflineUpdate />
                    </Suspense>
                    <TrialExpiredModal />
                  </LaunchDarklyProvider>
                </DeepLinkProvider>
              </DndProvider>
            </VirtiApolloProvider>
          </VirtiTheme>
        </UserProvider>
      </BrowserRouter>
    );
  };
  
  return AppProviders;
};

// export default App;
export default flowRight(
  withEs6Support,
  provideAppContext,
  withUserPilot,
  withGleap
)(App);
