import { FC, useCallback } from 'react';
import {
  secondarySidebarContentVar,
  setSecondarySidebarContentVar,
} from '@base/ApolloReactiveVars';
import {
  CanViewAnalyticsPage,
  CanViewCoursesList,
  CanViewDashboard,
  CanViewFormsList,
  CanViewGroupsList,
  CanViewMedia,
  CanViewSimulationsList,
  CanViewUsersList,
} from '@base/utility/Permissions';

import ButtonItem from '../ButtonItem/ButtonItem';
import LinkItem from '../LinkItem/LinkItem';
import useFeature from '@core/components/LaunchDarkly';

/**
 * @type {FC<{}>}
 */
export const AdminSidebar = () => {
  const openSubContent = useCallback((contentName) => {
    if (secondarySidebarContentVar() === contentName) {
      setSecondarySidebarContentVar(null);
      return;
    }
    setSecondarySidebarContentVar(contentName);
  }, []);

  const openAnalytics = useCallback(() => {
    openSubContent('analytics');
  }, [openSubContent]);

  const openUsers = useCallback(() => {
    openSubContent('users');
  }, [openSubContent]);

  const virtualCoachFeature = useFeature('virtual-coach');

  return (
    <>
      {CanViewDashboard() && (
        <LinkItem icon="dashboard" to="/" exact>
          Dashboard
        </LinkItem>
      )}

      {CanViewAnalyticsPage() && (
        <ButtonItem
          icon="analytics"
          contentName="analytics"
          to="/analytics-data/"
          onClick={openAnalytics}
          sublinks
        >
          Analytics
        </ButtonItem>
      )}

      {CanViewMedia() && (
        <LinkItem icon="media" to="/media">
          Media
        </LinkItem>
      )}
      {CanViewCoursesList() && (
        <LinkItem icon="course" to="/courses">
          Courses
        </LinkItem>
      )}
      {CanViewSimulationsList() && (
        <LinkItem icon="simulation" iconType="outline" to="/simulations">
          Interactive Videos
        </LinkItem>
      )}

      <LinkItem icon="virtual-human" to="/virtual-humans">
        Virtual Humans
      </LinkItem>
      {virtualCoachFeature && (
        <LinkItem icon="support" to="/virtual-coaches/8166/builder/coach/7376">
          Virtual Coaches
        </LinkItem>
      )}
      {(CanViewUsersList() || CanViewGroupsList()) && (
        <ButtonItem
          icon="group"
          contentName="users"
          to={['/users', '/groups']}
          onClick={openUsers}
          sublinks
        >
          Users
        </ButtonItem>
      )}
      {CanViewFormsList() && (
        <LinkItem icon="feedback" to="/feedback-forms">
          Feedback
        </LinkItem>
      )}
    </>
  );
};

export default AdminSidebar;
