import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ScoreOverview from '@core/components/Analytics/VirtualHuman/ScoreOverview/ScoreOverview';
import { Button, Loading, Modal } from '@virtidev/toolbox';
import useGetVHData from '@base/utility/VHHooks/useGetVHData';
import { useQuery } from '@apollo/client';
import { READ_VIRTUAL_HUMAN_FOR_SCORE_OVERVIEW } from '@core/components/Analytics/VirtualHuman/ScoreOverviewAnalyticsAreaModal/ScoreOverviewAnalyticsAreaModal.query';
import { ScoreOverviewModal } from '@core/components/Analytics/VirtualHuman/ScoreOverviewModal/ScoreOverviewModal.styled';
import * as Styled from './ScoreOverviewAnalyticsAreaModal.styled';
import useUser from '@core/helpers/useUser';
import { useGetSessionReportUntilDone } from '@core/components/Analytics/VirtualHuman/ScoreOverview/useGetSessionReportUntilDone';
import { useFakeProgressValue } from '@core/components/Loaders/ProgressLoader/useFakeProgressValue';
import { useGenerateSessionReport } from '@core/components/Analytics/VirtualHuman/ScoreOverview/useGenerateSessionReport';
import { usePrevious } from '@base/utility/CustomHooks';

const ScoreOverviewAnalyticsAreaModal = ({
  analyticsSubmission,
  SSID,
  vhId,
  onHideModal,
  vhHideScoresFromUser,
  showRegenerateButton,
}) => {
  const { data: assessment, loading: loadingAssessment } = useGetVHData(
    `assessments/${analyticsSubmission.PDAssessmentID}`
  );

  const vhDBVH = useMemo(() => {
    return assessment?.data?.assessment?.patient ?? null;
  }, [assessment]);
  const { data: localeData } = useGetVHData(`languages`);
  const { data: SSVH } = useQuery(READ_VIRTUAL_HUMAN_FOR_SCORE_OVERVIEW, {
    variables: {
      ID: SSID,
    },
  });
  const locale = useMemo(() => {
    return localeData?.data?.find((lang) => lang.id === vhDBVH?.languageId);
  }, [localeData?.data, vhDBVH?.languageId]);

  const {
    data: objectiveGroupsData,
    loading: objectiveGroupsLoading,
    error: objectiveGroupsError,
  } = useGetVHData(`objective-groups?vhId=${vhId}`);

  const [currentlyGeneratingReport, setCurrentlyGeneratingReport] =
    useState(false);

  const [sessionReport, setSessionReport] = useState(null);

  const shownHintObjectiveIds = useMemo(() => {
    return (
      assessment?.assessmentLogs?.reduce((carry, log) => {
        if (log.type === 'hint' && log.objectiveId) {
          carry.push(log.objectiveId);
        }
        return carry;
      }, []) ?? []
    );
  }, [assessment]);

  const {
    data: objectivesData,
    loading: objectivesLoading,
    error: objectivesError,
  } = useGetVHData(`objectives?vhId=${vhId}`);

  const { UserType } = useUser();
  const hideScoresFromUser = UserType === 'user' && vhHideScoresFromUser;

  const { data: sessionReportData, resetData } = useGetSessionReportUntilDone(
    true,
    analyticsSubmission.PDAssessmentID,
    (completedReport) => {
      if (completedReport) {
        stopProgress();
        setCurrentlyGeneratingReport(false);
        setSessionReport(completedReport);
      }
    }
  );

  const { data } = useGetVHData(
    `virtual-humans/${vhId}/coaching-session-report-generation-duration`
  );

  const estimatedGenerationDuration = data?.data ?? 40000;

  const { fakeProgress, startProgressIncrementing, stopProgress } =
    useFakeProgressValue();

  const memoizedArgs = useMemo(() => {
    return {
      assessmentId: analyticsSubmission.PDAssessmentID,
      onStarted: () => {
        console.log('generation started');
        resetData();
        setSessionReport(null);
        setCurrentlyGeneratingReport(true);
        startProgressIncrementing(estimatedGenerationDuration, true);
        console.log('START INCREMENTING');
      },
      onComplete: (newReport) => {
        setSessionReport(newReport);
        console.log('COMPLETE');
        setCurrentlyGeneratingReport(false);
        stopProgress();
      },
      onError: (e) => {
        console.error('Error generating report:', e);
        setCurrentlyGeneratingReport(false);
        stopProgress();
      },
    };
  }, [
    analyticsSubmission.PDAssessmentID,
    estimatedGenerationDuration,
    resetData,
    startProgressIncrementing,
    stopProgress,
  ]);

  const { generateReport, generatingReport } =
    useGenerateSessionReport(memoizedArgs);

  const handleRegenerate = useCallback(() => {
    generateReport(analyticsSubmission.PDAssessmentID, true);
  }, [analyticsSubmission.PDAssessmentID, generateReport]);

  // start the fake progress again if relaunching when pending
  // (the UX here is kinda broken but no easy way to deal with this)
  const prevSessionReport = usePrevious(sessionReportData);
  useEffect(() => {
    if (
      prevSessionReport?.data?.data?.generatingStatus !== 'pending' &&
      sessionReportData?.data?.data?.generatingStatus === 'pending'
    ) {
      startProgressIncrementing(estimatedGenerationDuration, false);
    }
  }, [
    prevSessionReport,
    sessionReport,
    sessionReportData?.data?.data?.generatingStatus,
    startProgressIncrementing,
    estimatedGenerationDuration,
  ]);

  return (
    <ScoreOverviewModal
      show={true}
      onHide={onHideModal}
      title="Coaching report"
    >
      {(loadingAssessment || objectiveGroupsLoading || objectivesLoading) && (
        // ||        coachingSessionReportLoading
        <Styled.LoadingWrapper>
          <Loading size="large" />
        </Styled.LoadingWrapper>
      )}
      {objectiveGroupsData?.data?.data?.objectiveGroups &&
      objectivesData?.data?.data?.objectives &&
      assessment?.data?.assessment &&
      vhDBVH &&
      SSVH?.readOneVirtualHuman ? (
        <ScoreOverview
          currentlyGeneratingReport={currentlyGeneratingReport}
          onRegenerate={handleRegenerate}
          generationProgress={fakeProgress}
          objectiveGroups={objectiveGroupsData?.data?.data?.objectiveGroups}
          allObjectives={objectivesData?.data?.data?.objectives}
          timeSpent={
            analyticsSubmission?.AnalyticsEvents?.edges?.[0]?.node?.TimeSpent
          }
          diagnosisSuccess={analyticsSubmission.PDDiagnosisScore === 100}
          vhDBVH={vhDBVH}
          ssVH={SSVH?.readOneVirtualHuman}
          hitObjectivesData={assessment?.data.assessment?.hitObjectivesData}
          pointsScored={assessment?.data.assessment?.objectivePointsAchieved}
          pointsPossible={assessment?.data.assessment?.objectivePointsPossible}
          assessmentId={analyticsSubmission.PDAssessmentID}
          hideScores={hideScoresFromUser}
          shownHintObjectiveIds={shownHintObjectiveIds}
          sessionReport={sessionReport ?? sessionReportData?.data?.data}
          easyMode={assessment?.data.assessment?.easyMode}
          BCP47Code={locale?.BCP47Code}
          showRegenerateButton={showRegenerateButton}
          footer={
            <Styled.CloseButtonWrapper>
              <Button color="turquoise" onClick={onHideModal}>
                Close
              </Button>
            </Styled.CloseButtonWrapper>
          }
        />
      ) : (
        <>
          {!loadingAssessment && !assessment?.data?.assessment && (
            <div>Couldn't retrieve required data</div>
          )}
        </>
      )}
    </ScoreOverviewModal>
  );
};

export default ScoreOverviewAnalyticsAreaModal;
