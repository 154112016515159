import { TableV2 } from '@virtidev/toolbox';
import styled, { css, DefaultTheme } from 'styled-components';

export const BillingOrganisationContent = styled.div`
  ${({ theme: { spacing } }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${spacing.xl};
    padding: ${spacing.md};
  `}
`;
export const SessionUsedCountWrapper = styled.div`
  ${({ theme: { font, spacing, color } }) => css`
    ${font.body}
    margin-bottom: -${spacing.xs};
    color: ${color.midnight10};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const PlanInfoValue = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${spacing.sm};
  `}
`;

export const PlanInfoRows = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing.md};
  `}
`;

export const Splitter = styled.hr`
  ${({ theme: { spacing, color } }) => css`
    height: 1px;
    background-color: ${color.blue40};
    font-size: 0;
    margin: ${spacing.lg};
    border: 0;
  `}
`;

export const PlanInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CurrentPlan = styled.div`
  ${({ theme: { font, borderRadius, spacing, color } }) => css`
    background-color: ${color.blue30};
    color: ${color.midnight};
    ${font.label}
    padding: ${spacing.md};
    border-radius: ${borderRadius.sm};
  `}
`;
export const LicenseDetail = styled.div``;

export const SessionContent = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { spacing, color, borderRadius } }) => css`
      padding: ${spacing.lg};
      background-color: ${color.blue50};
      margin-bottom: ${spacing.xl};
      border-radius: ${borderRadius.sm};
      border: 1px solid ${color.blue30};
    `
  }
`;

export const PlanTitle = styled.h2`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { font } }) => css`
      ${font.h2}
    `
  }
`;

export const ExpiryDate = styled.span`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { font, color } }) => css`
      ${font.body}
      color: ${color.midnight5};
    `
  }
`;

export const ProgressBarContainer = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { spacing } }) => css`
      margin: ${spacing.sm} 0;
    `
  }
`;
export const SessionUsedCount = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { font, color } }) => css`
      ${font.h1}
      color: ${color.midnight5};
    `
  }
`;

export const SessionUsedText = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }} p */
    ({ theme: { font, color } }) => css`
      ${font.body}
      color: ${color.midnight};
    `
  }
`;

export const SessionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ResetInfo = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }}  */
    ({ theme: { font, color } }) => css`
      ${font.body}
      color: ${color.midnight10};
    `
  }
`;

export const LicenseCountsPanel = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }}  */
    ({ theme: { spacing, color, borderRadius } }) => css`
      border: 1px solid ${color.blue30};
      border-radius: ${borderRadius.sm};
    `
  }
`;
export const LicenseCountsHeader = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }}  */
    ({ theme: { font, spacing, color, borderRadius } }) => css`
      border-radius: ${borderRadius.sm} ${borderRadius.sm} 0 0;
      ${font.label}
      padding: ${spacing.sm} ${spacing.xl};
      background-color: ${color.blue50};
      color: ${color.midnight};
      display: flex;
      justify-content: space-between;
    `
  }
`;
export const LicenseCountsRow = styled.div`
  ${
    /** @param {{ theme: DefaultTheme }}  */
    ({ theme: { spacing, color, font } }) => css`
      padding: ${spacing.sm} ${spacing.xl};
      color: ${color.midnight10};
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid ${color.blue30};
      > div:last-child {
        ${font.label}
      }
    `
  }
`;

export const LicenseCount = styled.div`
  ${({ $hitsCap, theme: { spacing, color, font } }) => css`
    ${font.label}
    color: ${$hitsCap ? color.red : color.midnight};
    display: grid;
    grid-template-columns: 1fr 5rem;
    align-items: center;
    justify-content: space-between;
    gap: ${spacing.sm};
    > div:last-child {
      margin-left: auto;
    }
  `}
`;

export const Billing = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing.lg};
  `}
`;
export const BillingInformationTitle = styled.div`
  ${({ theme: { font } }) => css`
    ${font.label}
  `}
`;
export const BillingInformation = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;
