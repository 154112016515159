import { Modal } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const StyledError = styled.div`
  color: var(--negative-color);
  /* margin: 20px 0px; */
`;

export const LoadingWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const InviteCodeSection = styled.div`
  flex: 1;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  place-items: end;
`;

export const WelcomeEmailSection = styled.div`
  display: flex;
  gap: 16px;
  font-weight: 600;
`;

export const BulkModal = styled(Modal)`
  .modal-content {
    min-height: min(80vh, 300px);
  }
`;

export const SaasLearnerNotice = styled.div`
  ${({ theme: { spacing, borderRadius, color } }) => css`
    padding: ${spacing.md} ${spacing.lg};
    border-radius: ${borderRadius.md};
    color: ${color.midnight10};
    background-color: ${color.blue50};
  `}
`;