
export const GetErrMessage = function (err) {
  if (err.graphQLErrors && err.graphQLErrors.length > 0 && err.graphQLErrors[0].hasOwnProperty('message')) {
    return err.graphQLErrors[0].message;
  }
  if (err.networkError && err.networkError.hasOwnProperty('message')) {
    return err.networkError.message;
  }
  return err.message || '';
}

// get our err status code from message string e.g. `(404) This query couldn't find anything` would get `404`
export const GetStatusCode = function (responseMsg) {
  let errCode = 200;
  if (responseMsg.includes('(') && responseMsg.includes(')')) {
    errCode = parseInt(responseMsg.split('(')[1].split(')')[0]);
  }
  if (
    // chrome
    responseMsg.includes('Failed to fetch') ||
    // firefox
    responseMsg.includes('NetworkError') ||
    // safari
    responseMsg.includes('Load failed')
  ) {
    errCode = 404;
  }
  return errCode;
}
