import { gql } from '@apollo/client';

export const GENERATE_CHECKOUT = gql`
  query generateCheckoutSession($orgId: ID!, $priceId: String!) {
    generateCheckoutSession(OrganisationID: $orgId, PriceID: $priceId) {
      URL
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  query upgradeSubscription($orgId: ID!, $priceId: String!) {
    upgradeSubscription(OrganisationID: $orgId, PriceID: $priceId) {
      Status
      Message
      NewPlan
    }
  }
`;

export const UPGRADE_PREVIEW = gql`
  query getUpgradePreview($orgId: ID!, $priceId: String!) {
    getUpgradePreview(OrganisationID: $orgId, PriceID: $priceId) {
      ProrationAmount
      Currency
      Message
    }
  }
`;
