import { gql } from "@apollo/client";

export const READ_LICENSE_DETAILS = gql`
  query licenseDetails($OrganisationID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $OrganisationID } }) {
      ID
      Name
      VirtualHumanFreeformLimit
      Licenses
      UnusedFreeformLicenses
      MicrophoneBehavior
      TotalAdmins
      TotalUsers
      VirtualHumans {
        pageInfo {
          totalCount
        }
      }
      Simulations {
        pageInfo {
          totalCount
        }
      }
      PlatformLicense {
        ID
        Type
        Title
        StartDate
        ExpiryDate
        SessionsPerMonth
        VirtualHumans
        InteractiveVideos
        Editors
        Learners
        Notes
      }
    }
    getBillingPortalURL(OrganisationID: $OrganisationID) {
      URL
    }
  }
`;

export const SUBSCRIPTION_STATUS = gql`
  query licenseDetails($OrganisationID: ID!) {
    getSubscriptionStatus(OrganisationID: $OrganisationID) {
      Status
      PaymentCardLast4
    }
  }
`;
