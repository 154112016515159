import { Card, Icon } from '@virtidev/toolbox';
import styled, { css, DefaultTheme } from 'styled-components';

export const PricingPageContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${spacing.p2};
  `}
`;

export const PricingHeader = styled.div`
  ${({ theme: { font } }) => css`
    ${font.title}
    width: 100%;
    max-width: 1240px;
  `}
`;

export const PricingBilling = styled.div`
  ${({ theme: { spacing } }) => css`
    padding: ${spacing.lg} ${spacing.md};
  `}
`;

export const PricingCards = styled.div`
  ${({ theme: { spacing } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    align-items: stretch;
    justify-items: center;
    width: 100%;
    gap: ${spacing.md};

    @media screen and (min-width: 981px) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      max-width: 1240px;
    }
  `}
`;

export const PlanContainer = styled(Card)`
  ${({ theme: { color, spacing, font } }) => css`
    flex: 1;
    min-width: 300px;
    width: 100%;
    max-width: 400px;

    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .body {
      padding-bottom: ${spacing.xl};
    }

    .footer {
      ${font.note}

      color: ${color.midnight10};
      text-align: center;
    }
  `}
`;

export const PlanNote = styled.div`
  ${
    /** @param {{ theme: DefaultTheme, $light?: boolean, $small?: boolean }} p */
    ({ theme: { font, color }, $light, $small }) => css`
      ${$small ? font.note : font.body}

      color: ${$light ? color.midnight10 : color.midnight};
      width: 100%;
      max-width: 1240px;
    `
  }
`;

export const PlanPriceWrapper = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

export const PlanBigPrice = styled.span`
  ${({ theme: { font } }) => css`
    ${font.callout}
  `}
`;

export const PlanFeatures = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing.p2};
    padding: 0 ${spacing.p2};
  `}
`;

export const PlanFeatureContainer = styled.div`
  ${({ theme: { spacing, color } }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${spacing.p2};
    color: ${color.midnight10};
  `}
`;

export const PlanFeatureIcon = styled(Icon).attrs({ icon: 'success' })`
  ${({ theme: { color } }) => css`
    color: ${color.turquoise};
  `}
`;

export const FootNotes = styled.div`
  ${({ theme: { spacing } }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
    justify-items: stretch;
    gap: ${spacing.p1};
    width: 100%;
    max-width: 1240px;
    margin-top: ${spacing.p3};
  `}
`;

export const PinkFootNote = styled.div`
  ${({ theme: { color, spacing } }) => css`
    background: ${color.pink30};
    color: ${color.contrast.pink30};
    padding: ${spacing.p5} ${spacing.p6};
  `}
`;

export const TurquoiseFootNote = styled.div`
  ${({ theme: { color, spacing } }) => css`
    background: ${color.turquoise20};
    color: ${color.contrast.turquoise20};
    padding: ${spacing.p5} ${spacing.p6};
  `}
`;

export const FootNoteHeader = styled.header`
  ${({ theme: { font } }) => css`
    ${font.h2}
  `}
`;
