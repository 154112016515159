import React, { FC } from 'react';
import * as Styled from './CoachingSessionReport.styled';
import { Button, ProgressBar } from '@virtidev/toolbox';
import { useTranslatedReportHeaders } from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/CoachingSessionReport/useTranslatedReportHeaders';

/**
 * @typedef {import('../../../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReport} CoachingSessionReport
 * @typedef {import('../../../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReportObjective} CoachingSessionReportObjective
 * @typedef {import('../../../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionEvaluationReport} CoachingSessionEvaluationReport
 */

/**
 * @type {FC<{
 *   BCP47Code: string;
 *   report: CoachingSessionReport & {objectiveReports: CoachingSessionReportObjective[]} & {evaluationReports: CoachingSessionEvaluationReport[]};
 *   onRegenerateClick?: () => void;
 *   showRegenerateButton?: boolean;
 * }>}
 * */
const CoachingSessionReport = ({
  BCP47Code,
  showRegenerateButton,
  report,
  onRegenerateClick,
}) => {
  const {
    AreasOfImprovement,
    OverallAnalysis,
    CustomAssessment,
    GeneratedWithAI,
    PerformanceOverview,
  } = useTranslatedReportHeaders(BCP47Code);
  return (
    <Styled.ReportWrapper>
      <Styled.ReportAIPill>{GeneratedWithAI}</Styled.ReportAIPill>
      {report.customAssessment && (
        <Styled.ReportSection>
          <Styled.ReportHeader>{CustomAssessment}</Styled.ReportHeader>
          <Styled.ReportBody>{report.customAssessment}</Styled.ReportBody>
        </Styled.ReportSection>
      )}
      {report.overallAnalysis && (
        <Styled.ReportSection>
          <Styled.ReportHeader>{OverallAnalysis}</Styled.ReportHeader>
          <Styled.ReportBody>{report.overallAnalysis}</Styled.ReportBody>
        </Styled.ReportSection>
      )}
      {report.areasOfImprovement && (
        <Styled.ReportSection>
          <Styled.ReportHeader>{AreasOfImprovement}</Styled.ReportHeader>
          <Styled.ReportBody>{report.areasOfImprovement}</Styled.ReportBody>
        </Styled.ReportSection>
      )}
      {report.evaluationReports.map((evaluation) => (
        <Styled.ReportSection key={evaluation.id}>
          <Styled.ProgressBarWrapper>
            <ProgressBar
              color="turquoise"
              progress={evaluation.score}
              max={100}
              height={4}
              labelText={`${evaluation.score}%`}
            />
          </Styled.ProgressBarWrapper>
          <Styled.ReportHeader>{evaluation.name}</Styled.ReportHeader>
          <Styled.ReportBody>{evaluation.reportText}</Styled.ReportBody>
        </Styled.ReportSection>
      ))}
      {report.objectiveReports.map((obj) => (
        <Styled.ReportSection key={obj.id}>
          <Styled.ProgressBarWrapper>
            <ProgressBar
              color="turquoise"
              progress={obj.rating}
              max={100}
              height={4}
              labelText={`${obj.rating}%`}
            />
          </Styled.ProgressBarWrapper>
          <Styled.ReportHeader>{obj.name}</Styled.ReportHeader>
          <Styled.ReportBody>{obj.reason}</Styled.ReportBody>
        </Styled.ReportSection>
      ))}
      {showRegenerateButton && onRegenerateClick && (
        <div>
          <Button
            icon="loop"
            onClick={onRegenerateClick}
            color="turquoise"
            size="small"
            design="outline"
          >
            Regenerate
          </Button>
        </div>
      )}
    </Styled.ReportWrapper>
  );
};

export default CoachingSessionReport;
