import getConfig from '@base/lib/config';
import { publicRoutes } from '@core/helpers/publicRoutes';
import useUser from '@core/helpers/useUser';
import { parseDatetime } from '@virtidev/toolbox';
import { useEffect, useMemo, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import Gleap from 'gleap';
import { isIframe } from '@core/helpers/useIsIframe';
import { GleapGlobalStyles } from '@core/components/Gleap/Gleap.styled';

if (process.env.REACT_APP_ENV === 'production' && !isIframe()) {
  Gleap.initialize(getConfig('REACT_APP_GLEAP_SDK_KEY'));
}
export const withGleap = (Component) => {
  const GleapProvider = ({ ...props }) => {
    const user = useUser();
    const { pathname } = useLocation();

    const publicPage = useMemo(
      () => !!publicRoutes.find((route) => matchPath(pathname, route)),
      [pathname]
    );

    useEffect(() => {
      if (publicPage) {
        Gleap.setDisableInAppNotifications(true);
        return;
      }
      Gleap.setDisableInAppNotifications(false);

      if (user.ID && user.ID !== '0') {
        const prefix =
          getConfig('REACT_APP_ENV') === 'production'
            ? 'prod'
            : getConfig('REACT_APP_ENV');

        const { Organisation } = user;
        Gleap.identify(`${prefix}-${user.ID}`, {
          name: user.Name,
          email: user.Email,
          plan: Organisation?.PlatformLicense?.Type,
          companyId: Organisation?.AccountID,
          companyName: Organisation?.Name,
          createdAt: new Date(),
          customData: {
            userType: user.UserType,
            orgTrial: Organisation?.Trial,
            orgExpiry: Organisation?.Trial
              ? parseDatetime(Organisation?.TrialExpiryDate)?.toISOString?.()
              : null,
            customerStage: Organisation?.CustomerStage,
          },
        });
        return;
      }
      Gleap.clearIdentity();
    }, [user, publicPage]);

    // probably unnecessary but here to uncomment in case it is necessary
    // useEffect(() => {
    //   Gleap.getInstance().softReInitialize();
    // }, [location]);

    return (
      <>
        <GleapGlobalStyles $hidden={publicPage} />
        <Component {...props} />
      </>
    );
  };

  return GleapProvider;
};

export default withGleap;
