import styled, { css } from 'styled-components';
import LinkWithPrevState from '../components/LinkWithPrevState';
import Breakpoints from '../themes/Breakpoints';
import { Card } from '@virtidev/toolbox';

const StyledDataBlocks = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.gridCols || '1fr 1fr'};
  ${(props) =>
    props.gridColsMed &&
    css`
      @media (max-width: ${Breakpoints.large}) {
        grid-template-columns: ${props.gridColsMed};
      }
    `}
  ${(props) =>
    props.gridColsSmall &&
    css`
      @media (max-width: ${Breakpoints.med}) {
        grid-template-columns: ${props.gridColsSmall};
      }
    `}
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
`;
export const BlockWrapper = styled.div`
  margin-top: ${(props) => (props.first ? '0' : '1rem')};
  margin-bottom: 1rem;
`;
const StyledDataBlock = styled(Card)`
  ${({ theme: { spacing }, fullWidth }) => css`
    width: ${fullWidth ? '100%' : 'initial'};

    .body {
      padding: 0 ${spacing.sm};
      gap: 0;
    }
  `}
`;
const StyledDataBlockHeader = styled.div`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledDataBlockHeaderText = styled.h2`
  ${({ theme: { color, fontSize, fontWeight } }) => css`
    display: flex;
    justify-content: space-between;
    color: ${color.midnight};
    font-size: ${fontSize.xl};
    font-weight: ${fontWeight.partBold};
  `}
`;
const StyledDataBlockLink = styled(LinkWithPrevState)`
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    margin-right: 0.3rem;
  }
`;
const StyledDataBlockBody = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 1rem 0;
  gap: 2rem;
`;
export const StyledSubDataBlockBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1rem 1fr;
  justify-content: center;
  align-items: center;
`;
const StyledDataItem = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 200;
  padding: 0.5rem;
`;
const StyledDataLabel = styled.div`
  color: #ccc;
`;

export {
  StyledDataBlock,
  StyledDataBlockBody,
  StyledDataBlockHeader,
  StyledDataBlockHeaderText,
  StyledDataBlockLink,
  StyledDataBlocks,
  StyledDataItem,
  StyledDataLabel,
};
