import { useQuery } from '@apollo/client';
import { READ_MEMBER_BASIC } from '@base/queries/UserQueries';
import { StoreLogin } from '@base/utility/LoginUtility';
import EnterprisePlanCard from '@core/components/Pricing/components/EnterprisePlanCard';
import PlanCard from '@core/components/Pricing/components/PlanCard';
import {
  FootNoteHeader,
  FootNotes,
  PinkFootNote,
  PlanNote,
  PricingBilling,
  PricingCards,
  PricingHeader,
  PricingPageContainer,
  TurquoiseFootNote,
} from '@core/components/Pricing/Pricing.styled';
import useUser from '@core/helpers/useUser';
import { withContentTemplate } from '@core/templates';
import { ButtonGroupV2 as ButtonGroup, Loading } from '@virtidev/toolbox';
import { useEffect, useMemo, useState } from 'react';

const priceOptions = [
  { name: 'annually', title: 'Billed annually' },
  { name: 'monthly', title: 'Billed monthly' },
];

const prices = [
  {
    Type: 'STARTER',
    Title: 'Starter',
    Editors: 3,
    Learners: 25,
    InteractiveVideos: 3,
    VirtualHumans: 3,
    SessionsPerMonth: 250,
  },
  {
    Type: 'PRO',
    Title: 'Pro',
    Editors: 5,
    Learners: 100,
    InteractiveVideos: 10,
    VirtualHumans: 10,
    SessionsPerMonth: 500,
  },
];

export const PricingPage = () => {
  const { ID, Organisation } = useUser();
  const [bill, setBill] = useState('monthly');

  const { data, loading } = useQuery(READ_MEMBER_BASIC, {
    variables: {
      ID,
    },
    skip: !ID || !Organisation?.ID,
  });

  const org = useMemo(() => {
    if (!data || !ID || !Organisation?.ID) {
      return null;
    }

    const org = data.readOneMember.Organisations.nodes.find(
      (item) => item.ID === Organisation.ID
    );
    return org;
  }, [ID, Organisation?.ID, data]);

  const orgType = useMemo(() => {
    if (!org) {
      return;
    }
    const platform = org?.PlatformLicense?.Type;
    if (platform) {
      return platform;
    }
    return org?.CustomerStage;
  }, [org]);

  useEffect(() => {
    const title = 'Subscription plans available at Virti';

    const env = process.env.REACT_APP_ENV;
    if (env !== 'production') {
      document.title = `${title} - ${window.location.host}`;
      return;
    }

    document.title = title;
  }, []);

  useEffect(() => {
    if (!data || !org || !Organisation) {
      return;
    }
    if (
      org.TrialExpiryDate !== Organisation.TrialExpiryDate ||
      org.PlatformLicense?.Type !== Organisation.PlatformLicense?.Type ||
      org.CustomerStage !== Organisation.CustomerStage
    ) {
      StoreLogin(null, null, data.readOneMember, null, org.ID);
    }
  }, [data, org, Organisation]);

  return (
    <PricingPageContainer>
      <PricingHeader>Choose the right plan for your team</PricingHeader>
      {loading && <Loading />}
      {(!ID || orgType === 'FREE_TRIAL') && (
        <PlanNote $light>
          After your 14-day trial period, you will need to upgrade to a paid
          plan to continue using the platform.
        </PlanNote>
      )}
      <PricingBilling>
        <ButtonGroup items={priceOptions} selected={bill} onChange={setBill} />
      </PricingBilling>
      <PricingCards>
        {prices.map((price) => (
          <PlanCard
            key={`${bill}-${price.Type}`}
            plan={price}
            bill={bill}
            org={org}
          />
        ))}
        <EnterprisePlanCard org={org} />
      </PricingCards>
      <FootNotes>
        <PinkFootNote>
          <FootNoteHeader>Coming soon</FootNoteHeader>
          <ul>
            <li>Translate content in 30 languages</li>
            <li>Export Virtual Human as chat bot</li>
          </ul>
        </PinkFootNote>
        <TurquoiseFootNote>
          <FootNoteHeader>Services</FootNoteHeader>
          <p>$999 per custom Virtual Human created by our team</p>
        </TurquoiseFootNote>
      </FootNotes>
    </PricingPageContainer>
  );
};

export default withContentTemplate({ pageKey: 'pricing' })(PricingPage);
