import { useQuery } from '@apollo/client';
import { hasExpiredTrial } from '@base/utility/LoginUtility';
import { getTrialRemainder } from '@core/components/Signup/legacy/helpers/getTrialRemainder';
import { READ_TRIAL_EXPIRY } from '@core/components/Signup/legacy/TrialBanner/TrialBanner.query';
import {
  TrialButtons,
  TrialModal,
} from '@core/components/Signup/TrialExpiredModal/TrialExpiredModal.styled';
import { publicRoutes } from '@core/helpers/publicRoutes';
import useUser from '@core/helpers/useUser';
import { Button, ConfirmationModal } from '@virtidev/toolbox';
import { useCallback, useMemo } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

export const TrialExpiredModal = () => {
  const { UserType, Organisation, Organisations } = useUser();
  const history = useHistory();
  const { pathname } = useLocation();

  const publicPage = useMemo(
    () => publicRoutes.find((route) => matchPath(pathname, route)),
    [pathname]
  );

  const { data: orgData } = useQuery(READ_TRIAL_EXPIRY, {
    variables: {
      ID: Organisation?.ID,
    },
    skip: !Organisation?.ID,
  });

  const trialRemainder = useMemo(() => {
    if (!orgData?.readOneOrganisation?.TrialExpiryDate) {
      return 1;
    }
    return getTrialRemainder(orgData.readOneOrganisation.TrialExpiryDate);
  }, [orgData]);

  const nextActiveOrg = useMemo(() => {
    return Organisations?.find((org) => !hasExpiredTrial(org));
  }, [Organisations]);

  const switchToActive = useCallback(() => {
    if (!nextActiveOrg?.ID) {
      return;
    }
    history.push(`/set-organisation/${nextActiveOrg.ID}`);
  }, [history, nextActiveOrg?.ID]);

  const trialExpired = useMemo(() => {
    if (trialRemainder == null) {
      return false;
    }
    return trialRemainder <= 0;
  }, [trialRemainder]);

  const show = useMemo(() => {
    return !publicPage && trialExpired && orgData?.readOneOrganisation?.Trial;
  }, [publicPage, trialExpired, orgData?.readOneOrganisation?.Trial]);

  const goPurchase = useCallback(() => {
    if (UserType !== 'admin') {
      return;
    }
    history.push('/pricing-plans');
  }, [UserType, history]);

  return (
    <TrialModal
      title="Your 14-day trial has ended"
      show={show}
      onConfirm={goPurchase}
      onHide={() => null}
      confirmDisabled={UserType !== 'admin'}
      confirmLabel="Purchase"
      cancelLabel={false}
      footer={
        <TrialButtons>
          <Button color="turquoise" design="outline" type="link" to="/logout">
            Log out
          </Button>
          {!!nextActiveOrg?.ID && (
            <Button
              color="turquoise"
              design="transparent"
              onClick={switchToActive}
            >
              Switch organisation
            </Button>
          )}
        </TrialButtons>
      }
    >
      You have reached the end of your trial, purchase a subscription to
      continue.
    </TrialModal>
  );
};

export default TrialExpiredModal;
