import { ConfirmationModal } from '@virtidev/toolbox';
import styled from 'styled-components';

export const TrialButtons = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export const TrialModal = styled(ConfirmationModal)`
  .modal-box {
    width: max-content;
  }

  .modal-close {
    display: none;
  }
`;
