import React, { useMemo } from 'react';
import * as Styled from './BillingOrganisationOverview.styled';
import {
  Button,
  Card,
  Chip,
  Datetime,
  formatDateLong,
  Link,
  Loading,
  ProgressBar,
} from '@virtidev/toolbox';
import {
  addMonths,
  differenceInDays,
  startOfDay,
  startOfMonth,
} from 'date-fns';

const BillingOrganisationOverview = ({
  checkingStatus,
  organisationData,
  platformLicense,
  usedVHCount,
  usedSimCount,
  lastCardNumbers,
  billingPortalURL,
}) => {
  const sessionsUsed = organisationData?.MonthlySessions || 0;
  const editorCount = organisationData?.TotalAdmins || 0;
  const learnerCount = organisationData?.TotalUsers || 0;
  const totalSessionsLimit = platformLicense?.SessionsPerMonth || 1500;
  const progressPercentage =
    totalSessionsLimit > 0 ? (sessionsUsed / totalSessionsLimit) * 100 : 100;

  const currentDate = new Date();
  const firstDayNextMonth = startOfMonth(addMonths(currentDate, 1));
  const resetInDays = differenceInDays(
    startOfDay(firstDayNextMonth),
    startOfDay(currentDate)
  );

  const tableData = useMemo(() => {
    return [
      {
        title: 'Editors',
        max: platformLicense.Editors || 0,
        used: editorCount,
        hitsCap: editorCount >= (platformLicense.Editors || 0),
      },
      {
        title: 'Learners',
        max: platformLicense.Learners || 0,
        used: learnerCount,
        hitsCap: learnerCount >= (platformLicense.Learners || 0),
      },
      {
        title: 'Virtual Humans',
        max: platformLicense.VirtualHumans || 0,
        used: usedVHCount,
        hitsCap: usedVHCount >= (platformLicense.VirtualHumans || 0),
      },
      {
        title: 'Interactive Videos',
        max: platformLicense.InteractiveVideos || 0,
        used: usedSimCount,
        hitsCap: usedSimCount >= (platformLicense.InteractiveVideos || 0),
      },
    ];
  }, [
    editorCount,
    learnerCount,
    platformLicense.Editors,
    platformLicense.InteractiveVideos,
    platformLicense.Learners,
    platformLicense.VirtualHumans,
    usedSimCount,
    usedVHCount,
  ]);

  const currentPlan = useMemo(() => {
    switch (platformLicense.Type) {
      case 'FREE_TRIAL':
        return 'Free trial';
      case 'PRO':
        return 'Pro';
      case 'STARTER':
        return 'Starter';
      default:
        return 'Enterprise';
    }
  }, [platformLicense.Type]);

  return (
    <Card title="Plan details">
      <Styled.BillingOrganisationContent>
        <div>
          <Styled.PlanInfoRows>
            <Styled.PlanInfoRow>
              <div>Your current plan</div>
              <Styled.PlanInfoValue>
                <Link to="/pricing-plans">Change</Link>
                <Chip color="pink">
                  {checkingStatus ? <Loading /> : currentPlan}
                </Chip>
              </Styled.PlanInfoValue>
            </Styled.PlanInfoRow>
            {/* <Styled.PlanInfoRow>
              <div>Monthly cost</div>
              <Styled.PlanInfoValue>$79 per admin/month</Styled.PlanInfoValue>
            </Styled.PlanInfoRow> */}
            {/* <Styled.PlanInfoRow>
              <div>
                Your next invoice is due on{' '}
                <Datetime datetime={platformLicense.ExpiryDate} long noTime />
              </div>
              <Styled.PlanInfoValue>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={billingPortalURL}
                >
                  View portal
                </Link>
              </Styled.PlanInfoValue>
            </Styled.PlanInfoRow> */}
          </Styled.PlanInfoRows>
          <Styled.Splitter />
          <Styled.Billing>
            {platformLicense.Type !== 'FREE_TRIAL' && (
              <>
                <Styled.BillingInformationTitle>
                  Billing information
                </Styled.BillingInformationTitle>
                <Styled.BillingInformation>
                  <div>**** **** **** {lastCardNumbers || '****'}</div>
                  <Button
                    design="outline"
                    color="turquoise"
                    type="link"
                    as="a"
                    href={billingPortalURL}
                  >
                    Update payment method
                  </Button>
                </Styled.BillingInformation>
              </>
            )}
          </Styled.Billing>
        </div>
        <div>
          <Styled.SessionContent>
            <Styled.SessionUsedCountWrapper>
              <Styled.SessionUsedCount>
                {sessionsUsed}/{totalSessionsLimit}
              </Styled.SessionUsedCount>
              <div>
                Expires on{' '}
                <Datetime
                  long={true}
                  datetime={platformLicense.ExpiryDate}
                  noTimeDisplay
                  emptyDisplay="-"
                />
              </div>
            </Styled.SessionUsedCountWrapper>
            <Styled.ProgressBarContainer>
              <ProgressBar progress={progressPercentage} max={100} />
            </Styled.ProgressBarContainer>
            <Styled.SessionInfo>
              <Styled.SessionUsedText>
                Sessions used this month
              </Styled.SessionUsedText>
              <Styled.ResetInfo>Resets in {resetInDays} days</Styled.ResetInfo>
            </Styled.SessionInfo>
          </Styled.SessionContent>
          <Styled.LicenseCountsPanel>
            <Styled.LicenseCountsHeader>
              <div>Included</div>
              <div>Used</div>
            </Styled.LicenseCountsHeader>
            {tableData.map((row) => (
              <Styled.LicenseCountsRow key={row.title}>
                <div>{row.title}</div>
                <Styled.LicenseCount $hitsCap={row.hitsCap}>
                  <div>
                    {row.hitsCap && <Chip color="turquoise">Upgrade</Chip>}
                  </div>
                  <div>
                    {row.used}/{row.max}
                  </div>
                </Styled.LicenseCount>
              </Styled.LicenseCountsRow>
            ))}
          </Styled.LicenseCountsPanel>
        </div>
      </Styled.BillingOrganisationContent>
    </Card>
  );
};

export default BillingOrganisationOverview;
