import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Loading } from '@virtidev/toolbox';
import { NavMenuLink } from './components/NavMenuLink';
import {
  NavMenuButton,
  NavMenuContainer,
  NavMenuContent,
} from './NavMenu.styled';
import {
  CanEditOrganisation,
  CanSeeCXDashboard,
} from '../../../../../utility/Permissions';
import {
  StoreLogin,
  useLoginAsUser,
} from '../../../../../utility/LoginUtility';
import { useMutation } from '@apollo/client';
import { REFRESH_TOKEN } from '@core/components/NavMenu/NavMenu.query';
import useUser from '@core/helpers/useUser';

export const NavMenu = ({ className, avatar, userName }) => {
  const { Organisation } = useUser();
  const [open, setOpen] = useState(false);
  const menuRef = useRef();

  const [refreshToken, { loading }] = useMutation(REFRESH_TOKEN);

  const orgType = useMemo(() => {
    const platform = Organisation?.PlatformLicense?.Type;
    if (platform) {
      return platform;
    }
    return Organisation?.CustomerStage;
  }, [Organisation]);

  const reloadOrgs = useCallback(
    async (e) => {
      if (e) {
        e.preventDefault();
      }
      const { data } = await refreshToken();

      if (!data) {
        return;
      }

      StoreLogin(
        data.refreshVirtiToken.Token,
        data.refreshVirtiToken.PDToken,
        data.refreshVirtiToken.Member
      );
    },
    [refreshToken]
  );

  useEffect(() => {
    const clickOutside = (event) => {
      if (!menuRef.current) {
        return;
      }
      if (menuRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };
    document.addEventListener('click', clickOutside, { passive: true });

    return () => {
      document.removeEventListener('click', clickOutside, { passive: true });
    };
  }, []);

  const toggleMenu = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const editOrg = CanEditOrganisation();
  const cxDashboard = CanSeeCXDashboard();

  const { previousToken, goBack } = useLoginAsUser();

  return (
    <NavMenuContainer className={className}>
      <NavMenuButton
        ref={menuRef}
        type="button"
        open={open ? '1' : undefined}
        onClick={toggleMenu}
        aria-label="Organisation Options"
      >
        {userName}
        {avatar}
      </NavMenuButton>
      <NavMenuContent open={open}>
        {previousToken && (
          <NavMenuLink icon="reset-position" to="" onClick={() => goBack()}>
            Back to Product Dashboard
          </NavMenuLink>
        )}
        {cxDashboard && (
          <NavMenuLink icon="barchart" to="/cx-dashboard/users">
            CX Dashboard
          </NavMenuLink>
        )}
        {cxDashboard && (
          <NavMenuLink icon="group" to="#" onClick={reloadOrgs}>
            {loading ? <Loading /> : 'Reload organisations'}
          </NavMenuLink>
        )}
        {
          <NavMenuLink icon="cog" to="/profile">
            Account Settings
          </NavMenuLink>
        }
        {editOrg && (
          <>
            <NavMenuLink icon="pen" to="/organisation">
              Edit Organisation
            </NavMenuLink>
            {(orgType === 'FREE_TRIAL' ||
              orgType === 'STARTER' ||
              orgType === 'PRO') && (
              <NavMenuLink icon="dollar" to="/billing">
                Usage & Billing
              </NavMenuLink>
            )}
          </>
        )}
        <NavMenuLink
          to="/privacy-policy"
          icon="padlock"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </NavMenuLink>
        <NavMenuLink icon="logout" to="/logout">
          Log Out
        </NavMenuLink>
      </NavMenuContent>
    </NavMenuContainer>
  );
};

export default NavMenu;
