import { NavLink } from 'react-router-dom';
import styled, { css, DefaultTheme, keyframes } from 'styled-components';
import { itemContainerCss } from '../../Sidebar.styled';

export const SubSidebarLink = styled(NavLink)`
  ${itemContainerCss}
  padding: 12px 27px;

  .sidebar-hover & {
    padding: 12px 27px;
  }

  @media screen and (min-width: 768px) {
    .sidebar-contracted & {
      padding: 12px 27px;
    }

    .sidebar-hover.sidebar-contracted &,
    .sidebar-expanded & {
      padding: 12px 27px;
    }
  }
`;

export const SubContent = styled.div`
  ${
    /** @param {{ $show?: boolean, $contracted?: boolean, theme: DefaultTheme }} p */
    ({ theme: { color }, $show }) => css`
      background: ${color.white};
      position: fixed;
      top: 0;
      height: 100vh;
      min-height: 100%;
      z-index: 2;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      width: 0;
      transition: width var(--sidebar-transition-time),
        border-left var(--sidebar-transition-time),
        left var(--sidebar-transition-time);
      border-right: 1px solid ${color.midnight30};

      scrollbar-color: transparent transparent;
      scrollbar-width: none;

      .sidebar-hover &,
      &:hover {
        scrollbar-color: unset;
        scrollbar-width: unset;
        overflow-x: hidden;
      }

      left: var(--sidebar-width-contracted);

      .sidebar-hover & {
        left: var(--sidebar-width-expanded);
      }

      @media screen and (min-width: 768px) {
        .sidebar-contracted & {
          left: var(--sidebar-width-contracted);
        }
        .sidebar-hover.sidebar-contracted &,
        .sidebar-expanded & {
          left: var(--sidebar-width-expanded);
        }
      }

      ${$show &&
      css`
        width: calc(var(--sidebar-width-expanded) - 70px);
      `}
    `
  }
`;

export const Spacer = styled.div`
  ${
    /** @param {{ $branded?: boolean }} p */
    ({ $branded }) => css`
      flex: 1;
      max-height: ${$branded ? '232px' : '182px'};
    `
  }
`;
