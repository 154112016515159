import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useInitialisePlayer } from '../helpers/useInitialisePlayer';
import { UnityIframe } from './UnityIframe';
import { SimulationPlayerManager } from '../Unity.types';

export const SimulationPlayer = ({
  show,
  onEnd,
  onComplete,
  onExitSimData,
  onSubmissionID, // not used
  onPlayerLoaded,
  onScoreAvailable,
  simulationData,
  setManager,
  unitID,
  sharetoken,
  publicPlayer,
  onPlayerVisible,
  ...props
}) => {
  const [embedRef, setEmbedRef] = useState(
    /** @type {HTMLIFrameElement | null} */ (null)
  );
  const { Hash } = useInitialisePlayer(embedRef, simulationData);
  const { search } = useLocation();

  const embedUrl = useMemo(() => {
    // copy params in original url to iframe
    const params = new URLSearchParams(search);

    params.set('Hash', Hash);
    params.set('simID', simulationData?.ID);
    if (sharetoken) {
      params.set('sharetoken', sharetoken);
    }

    if (publicPlayer) {
      params.set('publicPlayer', publicPlayer ? 'true' : 'false');
    }

    if (unitID) {
      params.set('unitID', unitID);
    }

    return `${
      window.location.origin
    }/embed/simulation-player?${params.toString()}`;
  }, [search, Hash, simulationData?.ID, sharetoken, publicPlayer, unitID]);

  /**
   * the manager object for sending data to unity from outside this component
   * @type {SimulationPlayerManager}
   */
  const manager = useMemo(() => {
    /** @param {string} event */
    const postEvent =
      (event) =>
      /** @param {any} [data] */
      (data) => {
        if (!embedRef?.contentWindow?.postMessage) {
          return;
        }

        embedRef.contentWindow.postMessage({
          event,
          Hash,
          data,
        });
      };

    const instance = {
      setMixpanelId: postEvent('setMixpanelId'),
    };
    return instance;
  }, [embedRef, Hash]);

  // send the new manager instance whenever it changes
  useEffect(() => {
    setManager(manager);
  }, [setManager, manager]);

  // for edge cases when we have an external content link
  useEffect(() => {
    if (!simulationData?.ContentEndpoint) {
      return;
    }
    const simWindow = window.open(
      simulationData.ContentEndpoint,
      'simulation-view',
      'noreferrer,noopener'
    );
    simWindow?.focus();
    onEnd();
  }, [simulationData, onEnd]);

  useEffect(() => {
    const handleEvents = (event) => {
      const { Hash: EventHash, event: eventName, data } = event.data;
      if (EventHash !== Hash) {
        return;
      }
      switch (eventName) {
        case 'onComplete':
          return onComplete();

        case 'onEnd':
          return onEnd();

        case 'onSubmissionID':
          return onSubmissionID(data);

        case 'onExitSimData':
          return onExitSimData(data);

        case 'onPlayerLoaded':
          return onPlayerLoaded();

        case 'onScoreAvailable':
          return onScoreAvailable(data);

        case 'onPlayerVisible':
          return onPlayerVisible(data);

        default:
        // nothing
      }
    };
    window.addEventListener('message', handleEvents);

    return () => {
      window.removeEventListener('message', handleEvents);
    };
  }, [
    onEnd,
    onComplete,
    onExitSimData,
    onSubmissionID,
    onPlayerLoaded,
    onScoreAvailable,
    onPlayerVisible,
    Hash,
  ]);

  useEffect(() => {
    if (show && embedRef) {
      setTimeout(() => {
        embedRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }, 300);
    }
  }, [show, embedRef]);

  return (
    <UnityIframe ref={setEmbedRef} {...props} show={show} src={embedUrl} />
  );
};

SimulationPlayer.defaultProps = {
  onEnd: () => null,
  onComplete: () => null,
  onExitSimData: () => null,
  onSubmissionID: () => null,
  setManager: () => null,
  onPlayerLoaded: () => null,
  onScoreAvailable: () => null,
  onPlayerVisible: () => null,
};

export default SimulationPlayer;
