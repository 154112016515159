import { Icon } from '@virtidev/toolbox';
import styled, { css, DefaultTheme } from 'styled-components';
import { itemContentCss, itemContainerCss } from '../../Sidebar.styled';

export const ButtonWrapper = styled.button`
  ${
    /**
     * @param {{ theme: DefaultTheme, $open?: boolean }} p
     */
    ({ theme: { sidebar, fontWeight }, $open }) => css`
      ${itemContainerCss}

      position: relative;

      .sidebar-contracted &&.more,
      .sidebar-expanded &&.more {
        padding-right: 7px;
      }

      ${$open &&
      css`
        background: ${sidebar.active.background};
        color: ${sidebar.active.color};
        font-weight: ${fontWeight.bold};

        .sidebar-contracted &&.more,
        .sidebar-expanded &&.more {
          padding-right: 2px;
        }
      `}
    `
  }
`;

export const Content = styled.div`
  ${itemContentCss}
`;

export const IconsWrapper = styled.div`
  position: relative;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LockIcon = styled(Icon)`
  ${({ theme: { color } }) => css`
    color: ${color.midnight10};
    position: absolute;
    top: 10px;
    left: 15px;
  `}
`;

export const MoreIcon = styled(Icon).attrs({
  size: '18px',
})``;

export const IconHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  pointer-events: none;
`;
