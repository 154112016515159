import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import withFlashMessaging from '../../HOCs/WithFlashMessaging';
import VirtiCircularProgressbar from '../CircularProgressbar';
import Icon from '../icons/Icon';
import {
  Icon as ToolboxIcon,
  Button as VirtiButton,
  Card,
} from '@virtidev/toolbox';
import Button from '../buttons/Button';
import { IsAcceptedFile, GetAcceptedFiles } from '../../utility/FileUtility';
import WithConfirmationBox from '../../HOCs/WithConfirmationBox';

import Placeholders from '../../utility/Placeholders';
import TranscodingStatus from '../TranscodingStatus';
import VideoSelectModal from '../../apps/core/src/components/AssetSelectModal/VideoSelectModal';
import UploadDragBoxProgress from './UploadDragBoxProgress';

const getMediaLabel = (type) => {
  switch (type) {
    case 'video':
    case 'video2D':
    case 'video3D':
      return 'video';
    case 'image':
      return 'image';
    case 'audio':
      return 'audio';
    default:
      return 'file';
  }
};

export const StyledAddPrevAssetDiv = styled.div`
  position: relative;
  padding: 10px 0;
  z-index: 1;
  width: ${(props) => props.width || '100%'};
  background: #fff;
  font-family: var(--text-font);
  font-size: 1rem;
  font-weight: 500;
  border: 2px dashed #e1e4ea;
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  cursor: pointer;
  color: #697184;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.assetBtnAbsolute &&
    css`
      position: absolute;
      top: calc(100% + 0.5rem);
    `}
  &:hover {
    background-color: #f9f9f9;
  }
`;

const StyledUploader = styled(Card).attrs({ noPadding: true })`
  cursor: ${(props) => (props.canOpen ? 'pointer' : 'default')};
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  ${(props) =>
    props.height &&
    css`
      height: ${(props) =>
        typeof props.height === 'number' ? props.height + 'px' : props.height};
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${(props) =>
        typeof props.width === 'number' ? props.width + 'px' : props.width};
    `}
    /* if aspect ratio is set it should stretch the whole width and height and might have to override any numeric widths/heights */
  ${(props) =>
    props.aspectRatio &&
    css`
      width: 100%;
      height: 100%;
    `}

  background-color: #FAFBFD;

  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  flex: 1;
  position: relative;
  ${(props) =>
    !props.visible &&
    css`
      display: none !important;
    `}
  border: 2px dashed #e1e4ea;
  ${(props) =>
    props.card &&
    css`
      background-color: var(--card-bg-color);
      box-shadow: var(--card-box-shadow);
    `}
  ${(props) =>
    props.useAssetSelector &&
    css`
      &:hover {
        background-color: #f9f9f9;
      }
    `}
`;

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const IconWithClickPassThroughs = styled.div`
  height: 0px;
  overflow: visible;
  pointer-events: none;
  background: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 500;
  color: #697184;
  ${({ $isAudio }) =>
    $isAudio &&
    css`
      padding-bottom: 30px;
    `}
`;
const StyledAddFromMediaIcon = styled(Icon)`
  margin-bottom: 1rem;
  path[fill='#7a8ea2'] {
    fill: #cdd1d8;
  }
`;
const StyledUseMediaText = styled.div`
  text-align: center;
  line-height: 1.5rem;
`;

const dropMargin = '.25rem';
const StyledDropArea = styled.div`
  width: calc(100% - (${dropMargin} * 2));
  height: calc(100% - (${dropMargin} * 2));
  margin: ${dropMargin};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px dashed #ccc;
  border-radius: calc(var(--card-border-radius) - 3px);
`;

const StyledProgressWrapper = styled.div`
  padding: 0 1rem;
`;

const StyledProgressbarWrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: 0 auto 0.5em auto;
`;

const StyledUploadDragBoxProgress = styled(UploadDragBoxProgress)`
  font-size: 0.8rem;
  color: var(--primary-font-color-light);
  margin: 0.5em 0;

  p + p {
    margin-top: 0.5em;
  }
`;

const StyledActions = styled.div`
  font-size: 0.8rem;

  button {
    color: var(--link-text-color);
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: var(--link-text-color-hover);
    }
  }
`;

const StyledCancel = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  text-decoration: underline;
  /* color: var(delete-button-bg-color);
  &:hover {
    color: var(delete-button-bg-color-hover);
  }
  &:active {
    color: var(delete-button-bg-color-active);
  } */
`;

const StyledPause = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  text-decoration: underline;
`;
const StyledClearButton = styled(VirtiButton).attrs({
  color: 'error',
  icon: 'bin',
})`
  cursor: pointer !important;
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  z-index: 2;
  padding: 0.7rem;
`;

const StyledText = styled.div`
  margin-top: 0.5rem;
`;
const StyledFileCountProgressText = styled.div`
  margin-bottom: 1rem;
`;

const StyledImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 6px;
  overflow: hidden;
  background: none;
  border-radius: var(--card-border-radius);
`;
const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
  position: relative;
  cursor: pointer !important;
  ${(props) =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
    `}
  ${(props) =>
    props.round &&
    css`
      border-radius: 20rem;
    `}
    /* if aspect ratio is set it should stretch the whole width and height */
  ${(props) =>
    props.aspectRatio &&
    css`
      width: 100%;
      height: 100%;
    `}
`;
const StyledVideoWrapper = styled.div`
  width: 100%;
  z-index: 1;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  background: #000;
  cursor: pointer !important;
  border-radius: var(--card-border-radius);
`;

const StyledTranscodingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  max-height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;

  > * {
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.8);
    border-radius: var(--card-border-radius);
  }
`;
const StyledVideo = styled.video`
  max-width: 100%;
  max-height: 100%;
  cursor: pointer !important;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
`;
const StyledAudio = styled.audio`
  position: absolute;
  bottom: 0;
`;

const StyledUploaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  gap: 16px;
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-items: stretch;
  ${(props) =>
    props.aspectRatio &&
    css`
      aspect-ratio: ${props.aspectRatio};
    `}
`;

const StyledRecommendationsText = styled.div`
  font-size: 0.7rem;
  line-height: 1rem;
  font-weight: normal;
  max-width: 80%;
  margin-top: 10px;
`;

const StyledUploadText = styled.div`
  margin-top: 1rem;
`;

class UploadDragBox extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      dragging: false,
      assetSelectorModalVisible: false,
    };
  }

  componentDidMount() {
    this.dragCounter = 0;
  }

  _canCurrentlyOpenPicker = () => {
    return true;
    // return !this.props.uploading && (!this.props.completed || this.props.allowUploadWhenCompleted);
  };

  _openFilePicker = () => {
    if (!this._canCurrentlyOpenPicker()) return;
    this.fileInput.current.click();
  };

  _handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  _handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // if (this.props.uploading) return;

    this.dragCounter++; // keep track of nested drag level
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };
  _handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!this.state.dragging) return;

    this.dragCounter--; // keep track of nested drag level
    if (this.dragCounter > 0) return;
    this.setState({ dragging: false });
  };
  // _isAccepted(fileType) {
  //   if (!this.props.mediaType) return true;
  //   const accepted = GetAcceptedFiles(this.props.mediaType);
  //   if (accepted.includes(fileType)) return true;
  //   if (accepted.replace('image/', '').replace('video/', '').replace('audio/').includes(fileType)) return true;
  //   if (fileType.includes('*'))
  // }
  /**
   *
   * @param {DragEvent} e
   * @returns
   */
  _handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log('handle drop');
    if (!this.state.dragging || !e.dataTransfer) return;
    // console.log('post drop');

    let validFiles = true;
    // can't use a one-line .every because FileList not array :(
    for (var i = 0; i < e.dataTransfer.files.length; i++) {
      if (
        !IsAcceptedFile(
          e.dataTransfer.files[i].type,
          GetAcceptedFiles(this.props.mediaType)
        )
      ) {
        validFiles = false;
        break;
      }
    }
    if (
      validFiles &&
      typeof e.dataTransfer !== 'undefined' &&
      typeof e.dataTransfer.files !== 'undefined'
    ) {
      // console.log('drop should go ahead');
      this.fileInput.current.files = e.dataTransfer.files;
      if (this.props.uploading) {
        this.props.addToQueue(e.dataTransfer.files);
      } else {
        this.props.startUpload(e.dataTransfer.files);
      }
    }
    this.setState({
      dragging: false,
    });
    this.dragCounter = 0;
    if (!validFiles) {
      let message = this.props.multiple
        ? 'One or more of your files are not valid.'
        : 'Please upload a valid file.';
      if (this.props.mediaType) {
        message = this.props.multiple
          ? 'One ore more of your files are not a valid ' +
            this.props.mediaType +
            ' file'
          : 'Please upload a valid ' + this.props.mediaType + ' file.';
      }
      this.fileInput.current.value = '';
      this.props.addFlashMessage(message, 'warning');
    }
  };

  _handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  _getProgressWidth = () => {
    if (this.props.loaderSize) {
      return this._getLoaderSizeVal(this.props.loaderSize);
    } else if (this.props.progressWidth) {
      return this.props.progressWidth;
    }
    return '100%';
  };
  _getProgressHeight = () => {
    if (this.props.loaderSize) {
      return this._getLoaderSizeVal(this.props.loaderSize);
    } else if (this.props.progressHeight) {
      return this.props.progressHeight;
    }
    return '100%';
  };

  _getLoaderSizeVal(size) {
    switch (size) {
      case 'large':
        return '200px';
      case 'small':
        return this.props.multiple ? '60px' : '80px';
      default:
        return '60px';
    }
  }

  _hideModal = () => {
    if (!this.props.loading) {
      this.setState({ assetSelectorModalVisible: false });
    }
  };

  render() {
    return (
      <StyledUploaderContainer
        flexDirection={this.props.flexDirection}
        style={{ height: '100%' }}
        aspectRatio={this.props.aspectRatio}
      >
        <StyledUploader
          className={this.props.className}
          onDrag={this._handleDrag}
          onDragOver={this._handleDragOver}
          onDragEnter={this._handleDragEnter}
          onDragLeave={this._handleDragLeave}
          onDrop={this._handleDrop}
          onClick={this._openFilePicker}
          visible={this.props.visible}
          height={this.props.height}
          width={this.props.width}
          round={this.props.round}
          data-testid="uploader"
          canOpen={this._canCurrentlyOpenPicker()}
          card={this.props.card}
          useAssetSelector={this.props.useAssetSelector}
          aspectRatio={this.props.aspectRatio}
        >
          <StyledContentWrapper>
            {!this.props.uploading &&
              !this.props.completed &&
              !this.state.dragging &&
              !this.props.imageSrc && (
                <IconWithClickPassThroughs
                  style={this.props.iconStyles}
                  data-testid="uploader-cloud-icon-wrapper"
                  $isAudio={
                    this.props.audioSrc &&
                    !this.props.uploading &&
                    !this.props.completed
                  }
                >
                  <div>
                    <ToolboxIcon
                      icon="upload"
                      size={this.props.uploadIconWidth}
                    />
                  </div>
                  <StyledUploadText>{this.props.dragHereText}</StyledUploadText>
                  {this.props.mediaType === 'image' && !this.props.imageSrc && (
                    <StyledRecommendationsText>
                      {this.props.recommendationsText}
                    </StyledRecommendationsText>
                  )}
                </IconWithClickPassThroughs>
              )}
            {this.state.dragging && (
              <StyledDropArea data-testid="uploader-drop-area">
                Drop here
              </StyledDropArea>
            )}
            <input
              multiple={this.props.multiple}
              ref={this.fileInput}
              onChange={(e) => {
                if (e.target.files) {
                  if (this.props.uploading) {
                    this.props.addToQueue(e.target.files);
                  } else {
                    this.props.startUpload(e.target.files);
                  }
                }
              }}
              type="file"
              name={this.props.name}
              style={{ display: 'none' }}
              accept={GetAcceptedFiles(this.props.mediaType)}
            />
            {!this.state.dragging && (
              <StyledProgressWrapper>
                {this.props.multiple && this.props.uploading && (
                  <StyledFileCountProgressText data-testid="uploader-file-count-progress">
                    Uploading file {this.props.filesUploadedProgress + 1} of{' '}
                    {this.props.files.length}
                  </StyledFileCountProgressText>
                )}
                {this.props.multiple && this.props.completed && (
                  <StyledFileCountProgressText data-testid="uploader-file-count-complete">
                    {this.props.files.length} file
                    {this.props.files.length > 1 ? 's' : ''} uploaded – click or
                    drag to upload more.
                  </StyledFileCountProgressText>
                )}
                {(this.props.uploading || this.props.completed) && (
                  <>
                    <StyledProgressbarWrapper
                      width={this._getProgressWidth()}
                      height={this._getProgressHeight()}
                    >
                      <VirtiCircularProgressbar
                        percentage={Math.round(this.props.progress)}
                        uploadBar={true}
                      />
                    </StyledProgressbarWrapper>
                    {this.props.uploading && (
                      <>
                        <StyledUploadDragBoxProgress
                          progress={parseFloat(this.props.progress) / 100}
                          paused={this.props.paused}
                          totalBytes={this.props.totalBytes}
                        />
                        <StyledActions>
                          <StyledPause
                            data-testid="uploader-pause-button"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.pause();
                            }}
                          >
                            {this.props.paused ? 'Resume' : 'Pause'}
                          </StyledPause>
                          <StyledCancel
                            data-testid="uploader-cancel-button"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (this.fileInput && this.fileInput.current) {
                                this.fileInput.current.value = '';
                              }
                              this.props.cancelUpload();
                            }}
                          >
                            Cancel
                          </StyledCancel>
                        </StyledActions>
                      </>
                    )}
                  </>
                )}
              </StyledProgressWrapper>
            )}

            {!this.props.multiple && this.props.completed && (
              <StyledText data-testid="uploader-finished">Finished!</StyledText>
            )}
            {this.props.onRemoveAsset &&
              !this.props.uploading &&
              (this.props.imageSrc ||
                this.props.audioSrc ||
                this.props.videoSrc) && (
                <StyledClearButton
                  data-testid="uploader-clear-image"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.props.confirm(
                      () => {
                        this.props.onRemoveAsset();
                      },
                      '',
                      `Remove${
                        this.props.mediaType
                          ? ' ' + getMediaLabel(this.props.mediaType)
                          : ''
                      }?`
                    );
                  }}
                />
              )}
            {this.props.imageSrc &&
              !this.props.uploading &&
              !this.props.completed &&
              !this.state.dragging && (
                <>
                  {this.props.onRemoveImg &&
                    !!this.props.imageSrc &&
                    this.props.imageSrc !== Placeholders.OrganisationLogo && (
                      <StyledClearButton
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.props.confirm(
                            () => {
                              this.props.onRemoveImg();
                            },
                            '',
                            'Remove image?'
                          );
                        }}
                      />
                    )}
                  <StyledImageWrapper>
                    <StyledImage
                      alt="dropzone upload image preview"
                      round={this.props.round}
                      bgColor={this.props.imageBG}
                      height={this.props.imgHeight || this.props.height}
                      src={this.props.imageSrc}
                      aspectRatio={this.props.aspectRatio}
                    />
                  </StyledImageWrapper>
                </>
              )}
            {this.props.videoSrc &&
              !this.state.dragging &&
              !this.props.uploading &&
              !this.props.completed && (
                <StyledVideoWrapper>
                  <StyledVideo controls data-testid="uploader-video-preview">
                    <source src={this.props.videoSrc} type="video/mp4" />
                  </StyledVideo>
                </StyledVideoWrapper>
              )}
            {!this.props.uploading &&
              this.props.transcodingStatus &&
              this.props.transcodingStatus !== 'Success' && (
                <StyledTranscodingWrapper>
                  <TranscodingStatus
                    transcodingStatus={this.props.transcodingStatus}
                  />
                </StyledTranscodingWrapper>
              )}
            {this.props.audioSrc &&
              !this.props.uploading &&
              !this.props.completed && (
                <StyledAudio controls src={this.props.audioSrc}>
                  Your browser does not support the
                  <code>audio</code> element.
                </StyledAudio>
              )}
            {this.props.children}
          </StyledContentWrapper>
        </StyledUploader>
        {this.props.useAssetSelector && (
          <StyledAddPrevAssetDiv
            width={this.props.assetSelectorBtnWidth}
            assetBtnAbsolute={this.props.assetBtnAbsolute}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ assetSelectorModalVisible: true });
            }}
          >
            <StyledUseMediaText>{this.props.useMediaText}</StyledUseMediaText>
          </StyledAddPrevAssetDiv>
        )}
        {this.props.useAssetSelector && (
          <VideoSelectModal
            visible={this.state.assetSelectorModalVisible}
            organisationID={this.props.organisationID}
            hideModal={this._hideModal}
            multiSelect={this.props.multiSelect}
            adding={this.props.adding}
            onConfirm={this.props.onConfirmPrevAsset}
            disabledVideoIDs={this.props.disabledVideoIDs}
            loading={this.props.mutatingWithPrevAsset}
            closeWhenMutationComplete={
              this.props.closeAssetSelectorWhenMutationComplete
            }
          />
        )}
      </StyledUploaderContainer>
    );
  }
}

UploadDragBox.defaultProps = {
  loaderSize: 'large',
  multiple: false,
  visible: true,
  round: false,
  adding: false,
  chunkSize: 1024 * 1024 * 50,
  dragHereText: 'Drag & Drop Here to Upload',
  recommendationsText:
    'Images should be no larger than 1280px x 720px. Aspect ratio should be 16:9.',
  allowUploadWhenCompleted: true,
  card: false,
  uploadIconWidth: '40px',
  useAssetSelector: false,
  closeAssetSelectorWhenMutationComplete: false,
  assetSelectorBtnWidth: '200px',
  flexDirection: 'row',
  useMediaText: 'Select from Library',
};

UploadDragBox.propTypes = {
  loaderSize: PropTypes.string,
  dragHereText: PropTypes.string,
  recommendationsText: PropTypes.string,
  name: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mediaType: PropTypes.string,
  iconStyles: PropTypes.object,
  className: PropTypes.string,
  visible: PropTypes.bool,
  round: PropTypes.bool,
  adding: PropTypes.bool,
  onRemoveAsset: PropTypes.func,
  confirm: PropTypes.func,
  addFlashMessage: PropTypes.func.isRequired,
  audioSrc: PropTypes.string,
  videoSrc: PropTypes.string,
  imageSrc: PropTypes.string,
  progressWidth: PropTypes.string,
  progressHeight: PropTypes.string,
  maxFileSize: PropTypes.number,
  startUpload: PropTypes.func.isRequired,
  cancelUpload: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  totalBytes: PropTypes.number.isRequired,
  files: PropTypes.array,
  allowUploadWhenCompleted: PropTypes.bool,
  card: PropTypes.bool,
  uploadIconWidth: PropTypes.string,
  useAssetSelector: PropTypes.bool,
  onConfirmPrevAsset: PropTypes.func,
  mutatingWithPrevAsset: PropTypes.bool,
  disabledVideoIDs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  disabledImageIDs: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  organisationID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closeAssetSelectorWhenMutationComplete: PropTypes.bool,
  addToQueue: PropTypes.func,
  transcodingStatus: PropTypes.string,
  assetSelectorBtnWidth: PropTypes.string,
  flexDirection: PropTypes.string,
  useMediaText: PropTypes.string,
  assetBtnAbsolute: PropTypes.bool,
  aspectRatio: PropTypes.string,
};

export default WithConfirmationBox(withFlashMessaging(UploadDragBox));
