import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import useUser from '@core/helpers/useUser';
import { getTrialRemainder } from '@core/components/Signup/legacy/helpers/getTrialRemainder';
import { READ_TRIAL_EXPIRY } from './TrialBanner.query';
import { StyledBannerCTA } from '@core/components/Banners/BannerCTA/BannerCTA.styled';
import { Button, Loading } from '@virtidev/toolbox';

export default function TrialBanner() {
  const { ID, UserType, Organisation } = useUser();

  const { data: orgData } = useQuery(READ_TRIAL_EXPIRY, {
    variables: {
      ID: Organisation?.ID,
    },
    skip: !Organisation?.ID,
  });

  const remainder = useMemo(() => {
    if (orgData) {
      return getTrialRemainder(orgData.readOneOrganisation.TrialExpiryDate);
    }
    return 0;
  }, [orgData]);

  return (
    <StyledBannerCTA>
      {orgData ? (
        remainder > 0 ? (
          <div>
            <strong>{remainder} days</strong> remaining on your free trial!
          </div>
        ) : (
          <div>
            Your free trial has expired, please consider purchasing a plan.
          </div>
        )
      ) : (
        <Loading contrast />
      )}
      {UserType === 'admin' && (
        <Button type="link" color="turquoise" to="/pricing-plans">
          Purchase
        </Button>
      )}
    </StyledBannerCTA>
  );
}
