import { FC, ComponentType, useCallback } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { Icon } from '@virtidev/toolbox';
import { Content, LinkWrapper } from './LinkItem.styled';
import {
  IconsWrapper,
  LockIcon,
} from '@core/components/Sidebar/components/ButtonItem/ButtonItem.styled';
import {
  secondarySidebarContentVar,
  setSecondarySidebarContentVar,
} from '@base/ApolloReactiveVars';
import { useReactiveVar } from '@apollo/client';
import classNames from 'classnames';

/**
 * @type {FC<{
 *    icon: string,
 *    iconType?: "solid" | "outline",
 *    as?: string | ComponentType<any> | undefined,
 *    locked?: boolean,
 * } & Partial<NavLinkProps>>}
 */
export const LinkItem = ({
  icon,
  iconType,
  children,
  locked,
  className,
  ...props
}) => {
  const sidebarContent = useReactiveVar(secondarySidebarContentVar);
  const closeSubSidebar = useCallback((e) => {
    if (e.target.classList.contains('active')) {
      return;
    }
    setTimeout(() => setSecondarySidebarContentVar(null), 100);
  }, []);

  return (
    <LinkWrapper
      {...props}
      onClick={closeSubSidebar}
      $faded={!!sidebarContent}
      className={classNames(className, 'sidebar-item')}
    >
      <IconsWrapper>
        {locked && ( // having the condition on only LockIcon caused styling issues for the menu chevron button
          <LockIcon icon={'padlock'} color="var(--primary-color)" size="18px" />
        )}
        <Icon
          icon={icon}
          type={iconType}
          className="sidebar-icon"
          size="22px"
        />
      </IconsWrapper>
      <Content>{children}</Content>
    </LinkWrapper>
  );
};

export default LinkItem;
