import { useMutation, useQuery } from '@apollo/client';
import { Button, LoadingPlaceholder, Modal, Table } from '@virtidev/toolbox';
import React, { useCallback, useMemo, useState } from 'react';
import {
  S_td,
  S_th,
  S_thLeft,
  S_tr,
} from '../../../../../../styled-components/StyledTable';
import useUser from '../../../helpers/useUser';
import useFlashMessage from '../../FlashMessage';
import PaginationControl from '../../PaginationControl';
import { usePaginationUrl } from '../../PaginationControl/helpers/usePaginationUrl';
import {
  READ_API_NOTIFICATIONS,
  UPDATE_API_NOTIFICATION,
} from './APILogs.query';
import {
  Entry,
  EntryCode,
  EntryItem,
  EntryTitle,
  TableRow,
} from './APILogs.styled';

export default function APILogs() {
  const { Organisation } = useUser();
  const { page, pageSize, controlProps } = usePaginationUrl({
    pageSize: 20,
  });

  const { addFlashMessage } = useFlashMessage();

  const [activeRow, setActiveRow] = useState();
  const [show, setShow] = useState(false);

  const variables = useMemo(() => {
    return {
      OrganisationID: Organisation.ID,
      limit: pageSize,
      offset: (page - 1) * pageSize,
    };
  }, [Organisation.ID, page, pageSize]);

  const { data, loading } = useQuery(READ_API_NOTIFICATIONS, {
    variables,
  });

  const [updateAPINotification, { loading: loadingUpdate }] = useMutation(
    UPDATE_API_NOTIFICATION,
    {
      onCompleted: () => addFlashMessage('Resent!'),
      onError: (e) => {
        console.error(e);
        addFlashMessage(
          'Something went wrong, check console for details.',
          'error'
        );
      },
    }
  );

  const handleRowClick = useCallback((node) => {
    setShow(true);
    setActiveRow(node);
  }, []);

  const handleCloseModal = useCallback(() => {
    setShow(false);
    setActiveRow(null);
  }, []);

  const handleResend = useCallback(
    (ID) => {
      const refetchQueries = [
        {
          query: READ_API_NOTIFICATIONS,
          variables,
        },
      ];
      updateAPINotification({
        variables: {
          Input: {
            ID: ID,
            SendNotification: true,
          },
        },
        refetchQueries,
      });
    },
    [updateAPINotification, variables]
  );

  const entry = () => {
    if (activeRow) {
      return (
        <Entry>
          {Object.keys(activeRow)
            .filter((key) => {
              return key !== '__typename' && key !== 'Status';
            })
            .map((key) => {
              const parsedKeyTitle =
                key === 'ResponseStatusCode' ? 'Status Code' : key;
              return (
                <EntryItem>
                  <EntryTitle>{parsedKeyTitle}</EntryTitle>
                  <EntryCode>{activeRow[key]}</EntryCode>
                </EntryItem>
              );
            })}

          <Button
            style={{
              borderRadius: '22px',
              float: 'right',
            }}
            disabled={loadingUpdate}
            onClick={(e) => {
              handleResend(activeRow.ID);
            }}
          >
            Resend
          </Button>
        </Entry>
      );
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseModal}
        title="Log Entry"
        render={entry}
      />

      <Table>
        <thead>
          <tr>
            <S_th>ID</S_th>
            <S_th>Created</S_th>
            <S_th>LastSent</S_th>
            <S_th>Event</S_th>
            <S_th>Response Code</S_th>
            <S_th>URL</S_th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.readAPINotifications.edges.map(({ node }) => {
              const { ID, Event, Created, LastSent, ResponseStatusCode, URL } =
                node;
              return (
                <TableRow key={ID} onClick={() => handleRowClick(node)}>
                  <S_td>{ID}</S_td>
                  <S_td>{Created}</S_td>
                  <S_td>{LastSent}</S_td>
                  <S_td>{Event}</S_td>
                  <S_td>{ResponseStatusCode}</S_td>
                  <S_td>{URL}</S_td>
                </TableRow>
              );
            })}
          {loading &&
            [...Array(10)].map((item, index) => (
              <S_tr key={index + 'row'}>
                {[...Array(6)].map((item, index) => {
                  return (
                    <S_thLeft key={index + 'dataitem'}>
                      <LoadingPlaceholder />
                    </S_thLeft>
                  );
                })}
              </S_tr>
            ))}
        </tbody>
      </Table>
      <PaginationControl
        {...controlProps}
        total={data?.readAPINotifications?.pageInfo?.totalCount || 0}
      />
    </div>
  );
}
