import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { READ_ORGANISATION } from '../queries/OrganisationQueries';
import withUser from '../HOCs/WithUser';
import { PageContentCard } from '../components/StyledPage';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import PageLoadError from '../components/PageLoadError';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import { CanEditOrganisation } from '../utility/Permissions';
import OrganisationAPI from '../apps/core/src/components/Organisation/OrganisationAPI/OrganisationAPI';
import APILogs from '../apps/core/src/components/Organisation/APILogs/APILogs';
import SessionDashboard from '../apps/core/src/components/Organisation/SessionDashboard/SessionDashboard';
import { withPageTemplate } from '@core/templates';
import { flowRight } from 'lodash';
import { Titlebar } from '@core/components/Page';
import { EditOrganisationForm } from '@core/components/Organisation/EditOrganisationForm/EditOrganisationForm';
import { Card } from '@virtidev/toolbox';

const OrganisationPage = (props) => {
  const { data, loading, error } = useQuery(READ_ORGANISATION, {
    variables: { ID: props.userOrganisationID },
  });
  const [hasSetExtraData, setHasSetExtraData] = useState(false);
  useEffect(() => {
    // set organisation data as extra data for onboarding so we can check if certain onboarding objectives
    // have previously been completed or not (e.g. description is not empty)
    if (
      !hasSetExtraData &&
      data &&
      typeof data.readOneOrganisation !== 'undefined'
    ) {
      setHasSetExtraData(true);
    }
  }, [hasSetExtraData, data]);

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  if (!CanEditOrganisation()) {
    return <RedirectWithPrevState to="/" />;
  }
  if (!loading && !error && !data?.readOneOrganisation) {
    return <RedirectWithPrevState to="/" />;
  }

  return (
    <>
      <Titlebar>Edit organisation</Titlebar>
      {loading && <LoadingPageIndicator />}
      {error && <PageLoadError graphQLErrorObj={error} />}
      {!loading && !error && data && (
        <>
          <Card>
            <EditOrganisationForm organisation={data.readOneOrganisation} />
          </Card>
          <br />
          <SessionDashboard />
          <br />
          <PageContentCard>
            <OrganisationAPI />
            <APILogs />
          </PageContentCard>
          <br />
          <br />
          <br />
          <br />
        </>
      )}
    </>
  );
};

OrganisationPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default flowRight(
  withPageTemplate({ pageKey: 'organisation ' }),
  withUser
)(OrganisationPage);
