import { gql } from '@apollo/client';

export const READ_VIRTUAL_HUMAN = gql`
  query readOneVirtualHuman($ID: ID!) {
    readOneVirtualHuman(filter: { ID: { eq: $ID } }) {
      ID
      OrganisationID
      FeedbackForms {
        edges {
          node {
            ID
            Title
            Hash
            Trigger
          }
        }
      }
      FeedbackFormBeforeCompleted
      FeedbackFormBefore {
        ID
        Title
        Hash
        AllowMultipleSubmissions
      }
      FeedbackFormAfterCompleted
      FeedbackFormAfter {
        ID
        Title
        Hash
        AllowMultipleSubmissions
      }
      PlayerType
    }
  }
`;
