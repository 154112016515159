import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import _ from 'lodash';

import LoginForm from '../forms/LoginForm';
import {
  StoreLogin,
  UpdateToken,
  UpdateAIToken,
  UnsetLocalStorageUserData,
} from '../utility/LoginUtility';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import { LOGIN } from '../queries/UserQueries';
import FlashMessageHolder from '../components/FlashMessageHolder';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import { readOneMemberBasic } from '../query-functions/MemberQueryFunctions';
import { FindGetParameter } from '../utility/URLHelpers';
import {
  setSecondarySidebarContentVar,
  setSuccessfulUserSignup,
} from '../ApolloReactiveVars';
import { withUser } from '../apps/core/src/helpers/useUser';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failedLogin: false,
      invalidUserType: false,
      loggingIn: false,
      failedConnection: false,
      newlyLoggedInUserID: null,
      newlyLoggedInUser: null,
    };
  }

  componentDidMount() {
    if (
      (typeof this.props.userID === 'number' && this.props.userID > 0) ||
      this.state.newlyLoggedInUserID
    ) {
      return;
    }
  }

  _login = async (data) => {
    setSuccessfulUserSignup(false);
    if (data?.createVirtiToken?.Token) {
      UpdateToken(data.createVirtiToken.Token);

      if (
        data.createVirtiToken.PDToken &&
        data.createVirtiToken.PDToken?.length > 3
      ) {
        UpdateAIToken(data.createVirtiToken.PDToken);
      }

      const userData = await readOneMemberBasic(
        this.props.client,
        data.createVirtiToken.Member.ID
      );

      if (!userData?.data?.readOneMember) {
        this.setState({
          failedLogin: true,
          invalidUserType: false,
          loggingIn: false,
          errMsg: "Invalid login or doesn't exist.",
        });
        return;
      }
      StoreLogin(
        data.createVirtiToken.Token,
        data.createVirtiToken.PDToken,
        userData.data.readOneMember,
        this.props.client
      );

      this.setState({
        newlyLoggedInUserID: userData.data.readOneMember.ID,
        newlyLoggedInUser: userData.data.readOneMember,
      });

      setSecondarySidebarContentVar(null);
    } else {
      const errMsg = _.get(data, 'createVirtiToken.Message', null);

      this.setState({
        failedLogin: true,
        invalidUserType: false,
        loggingIn: false,
        errMsg,
      });
    }
  };

  render() {
    if (
      (typeof this.props.userID === 'number' && this.props.userID > 0) ||
      this.state.newlyLoggedInUserID
    ) {
      if (
        this.state.newlyLoggedInUser &&
        this.state.newlyLoggedInUser.UserType === 'user'
      ) {
        return <RedirectWithPrevState to="/home" />;
      }
      return (
        <RedirectWithPrevState
          to={this.props.location?.state?.prevPath || '/'}
        />
      );
    }
    const errorMessage =
      this.props.location?.state?.errorMessage ||
      FindGetParameter('errorMessage');

    return (
      <div>
        <FlashMessageHolder />
        <Mutation
          mutation={LOGIN}
          onCompleted={this._login}
          onError={() => {
            this.setState({
              loggingIn: false,
              failedLogin: false,
              failedConnection: true,
            });
          }}
        >
          {(mutateLogin) => (
            <div>
              <LoginForm
                loggingIn={this.state.loggingIn}
                failedLogin={this.state.failedLogin}
                errMsg={this.state.errMsg}
                failedConnection={this.state.failedConnection}
                invalidUserType={this.state.invalidUserType}
                resetFailedLogin={() =>
                  this.setState({ failedLogin: false, invalidUserType: false })
                }
                redirectedError={errorMessage}
                submitCallback={(email, password) => {
                  this.setState({
                    loggingIn: true,
                  });
                  // remove any existing logins, just in case
                  UnsetLocalStorageUserData();
                  mutateLogin({
                    variables: { Email: email, Password: password },
                  });
                }}
              />
            </div>
          )}
        </Mutation>
      </div>
    );
  }
}

LoginPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default _.flowRight(withFlashMessaging, withApollo, withUser)(LoginPage);
