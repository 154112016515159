import styled from 'styled-components';

export const EggContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 0;
  transition: left var(--sidebar-anim-duration);
  pointer-events: none;
  z-index: 99999;
  width: 75px;

  @media screen and (min-width: 768px) {
    .sidebar-contracted & {
      left: 0;
    }

    .sidebar-hover.sidebar-contracted &,
    .sidebar-expanded & {
      left: 10px;
    }
  }
`;
